import React from 'react';
import UserItem from './UserItem.js';

export default class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null
        }
    }

    render() {
        // Render active users
        var activeUsersArray = this.props.users.active;
        if(!Array.isArray(this.props.users.active)) {
            activeUsersArray = [this.props.users.active];
        }

        var activeUsers = activeUsersArray.map((user) => (
            <UserItem
                item={user}
                onUpdate={this.props.onUpdate}
                loading={this.props.loading.includes(user.id)}
            />
        ));

        // Render inactive users
        var inactiveUsersArray = this.props.users.inactive;
        if(!Array.isArray(this.props.users.inactive)) {
            inactiveUsersArray = [this.props.users.inactive];
        }

        var inactiveUsers = inactiveUsersArray.map((user) => (
            <UserItem
                item={user}
                onUpdate={this.props.onUpdate}
                loading={this.props.loading.includes(user.id)}
            />
        ));

        return (
            <div>
                <div className="list-container">
                    <ul className="list list--users">
                        {activeUsers}
                        {inactiveUsers}
                    </ul>
                </div>
            </div>
        )
    }
}