import React, { useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import Button from "./Button";

import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import { useSelector } from "react-redux";

import closeIcon from '#assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

export const PopUpSelectSupplier = ({ visible, title, button, loading, hideHIL, hideFavourited, onlyFavourites, onSave, onDismiss, exclude }) => {
    const [supplier, setSupplier] = useState();
    const [supplierOptions, setSupplierOptions] = useState([]);

    const suppliers = useSelector(supplierSelectors.get);
    const favouriteSuppliers = useSelector(pubSelectors.suppliers);

    const navigate = useNavigate();

    useEffect(() => {
        let filtered = suppliers;
        if (hideHIL) {
            filtered = filtered.filter(supplier => !supplier.is_heineken);
        }

        if (exclude) {
            console.log("Exclude", exclude);
            filtered = filtered.filter(supplier => supplier.supplier_id !== exclude);
        }

        // remove inactive suppliers
        filtered = _.filter(filtered, ['active', 1]);

        if (hideFavourited) {
            filtered = _.differenceBy(filtered, favouriteSuppliers, 'supplier_id');
        } else if (onlyFavourites) {
            filtered = _.intersectionBy(filtered, favouriteSuppliers, 'supplier_id');
        }

        setSupplierOptions(
            filtered.map((item) => {
                // Format suppliers to work with picker
                return {
                    label: item.name,
                    value: item.supplier_id
                }
            })
        );
    }, [suppliers, favouriteSuppliers, hideHIL, exclude, hideFavourited, onlyFavourites]);
    
    if (!visible) {
        return;
    }

    const renderContent = () => {
        if (!supplierOptions.length) {
            return (
                <div style={{fontSize: 16, color: "#ffffff", textAlign: "center", cursor: "pointer"}} onClick={() => {
                    onDismiss();
                    navigate("/settings/favourites");
                }}>
                    <p>No preferred suppliers set.</p>
                    <p>Press here to set your preferred suppliers.</p>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="label">Supplier</div>
                <select value={supplier} onChange={({ target }) => { setSupplier(target.value) }}>
                    <option value="0">Select a supplier...</option>
                    {
                        supplierOptions.map(({ label, value }) => <option value={value}>{label}</option>)
                    }
                </select>

                <Button
                    loading={loading}
                    id="date-search-btn"
                    theme="primary"
                    disabled={!supplier}
                    onClick={() => {
                        onSave(supplier);
                    }}
                    title={button}
                />
            </React.Fragment>
        )
    }

    if (!visible) return;

    return createPortal(
        <div className="popup popup--select-supplier">
            <div id="popup-filter" onClick={onDismiss}>
            </div>

            <div id="popup-box">
                <h5 className="text-center">
                    { title }
                    <div id="popup-close" onClick={onDismiss}>
                        <img src={closeIcon} alt="close" />
                    </div>
                </h5>

                { renderContent() }
            </div>
        </div>
    , document.getElementById('root'));
}