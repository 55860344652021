import React from 'react';
import envWhiteIcon from '../../assets/icons/environment-white.png';
import foodSaftyIcon from '../../assets/icons/food-safety-white.png';
import qualityIcon from '../../assets/icons/quality-white.png';
import drinkAwareIcon from '../../assets/icons/drink-aware@2x.png';
import enjoyIcon from '../../assets/icons/enjoy-resp@2x.png';
import termsPdf from '../../assets/Smartpub Terms of Service.pdf';

export default class LoginLayout extends React.Component {
    render(){
        return (
            <div className="contents" id="login">
                <div className="login-container">
                    { this.props.content }
                    <div className="logos">
                        <div className="welcome-footer">
                            <div className="nsai">
                                <img alt="" src={envWhiteIcon}/>
                                <img alt="" src={foodSaftyIcon}/>
                                <img alt="" src={qualityIcon}/>
                            </div>
                            <div className="drink-aware">
                                <img alt="" src={drinkAwareIcon}/>
                                <img alt="" src={enjoyIcon}/>
                            </div>
                            <div className="links">
                                <a href={termsPdf} target="_blank">Legal</a>
                                <a href='https://www.heinekenireland.ie/Privacy-Policy/' target="_blank">Privacy</a>
                                <a href='https://www.heinekenireland.ie/Cookie-Policy/Cookie-Policy.html' target="_blank">Cookies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}