import React from 'react';

import { AppService as App } from 'smartpub-common/services/app.js';
import rightArrowIcon from '../../assets/icons/next-back-icon-green.svg';
import downArrowIcon from '../../assets/icons/next-back-icon-green.svg';
import InfoIcon from '../../assets/icons/info-icon.svg';

function ButtonIcon(props){
    const hasIcon = ((props.iconType && props.iconName) ? true : false);
    const hasPath = (props.file ? true : false);
    const iconSize = "iconPlaceHolder" + props.size[0].toUpperCase() + props.size.substring(1, props.size.length);
    var file = typeof props.fileLocal !== "undefined" ? props.fileLocal : props.file;
    
    const size = {
        "big": 60,
        "mediumBig": 56,
        "medium": 80,
        "checkout": 40,
        "small": 30,
        "smaller": 25,
        "tiny": 20
    }
    
    if(!hasIcon){
        if(!hasPath){
            return <div className={"icon placeholder "+ props.size}></div>
        } else {
            return <img src={file} alt="" className={"icon "+ props.size} />
        }
    } else {
        console.error("I don't know what is supposed to happen her");
    }
}

export class IconButton extends React.Component {
    constructor(props){
        super(props)
        
        let allowed = false;
        if (this.props.permission) {
            let permissions = this.props.permission.split(",");
            
            for (let i = 0; i < permissions.length; i++) {
                if (App.user && App.user.hasPermission(permissions[i].trim())) {
                    allowed = true;
                }
            }
        } else {
            allowed = true;
        }
        
        this.state = {
            hasIcon: true,
            hasPermission: allowed,
            file: props.file ? props.file.toString() : null
        }
        
        var classes = ["btn btn-primary has-icon"];
        
        if (props.arrow) {
            classes.push("blank");
        } else {
            classes.push(props.theme ? props.theme : "green");
        }
        
        if (props.align) {
            classes.push(props.align);
        }
        if (props.size) {
            classes.push(props.size);
        } else {
            classes.push("medium");
        }
        
        if (props.className) {
            classes.push(props.className);
        }
        
        if(this.props.hasOwnProperty('longDesc') && this.props.longDesc) classes.push('hasLongDesc');
        
        if (!this.state.hasPermission) {
            classes.push("btn-disabled");
        }
        
        this.className = classes.join(' ');
    }
    
    render() {
        if(!this.state.file || this.state.file.includes('admin/null')) this.state.file = null;
        
        return (
            <div className="icon-button-container">
                <a href={this.state.hasPermission ? (this.props.href || null) : null} target={this.props.target || null} className={this.className} onClick={this.state.hasPermission ? this.props.onClick : null}>
                    <div className="row">
                        <div className={"icon-wrapper " + (this.props.arrow ? (this.props.fileLocal || this.props.file ? "highlighted" : "blank") : null)}>
                            {
                                this.props.fileLocal || this.props.file ? 
                                <ButtonIcon hasPermission={this.state.hasPermission} fileLocal={this.props.fileLocal} img={this.props.img} file={this.props.file} alt="" size={this.props.arrow ? "medium" : this.props.size} />
                                :
                                null
                            }
                        </div>
                        
                        <div className={"icon-text " + (!this.props.file && !this.props.fileLocal ? "blank " : "") + (this.props.arrow ? "col-md-9 " : "") + (this.props.longDesc ? "long-text " : "") + (this.state.file ? "has-icon " : "")}>
                            <p>{ this.props.title || this.props.text }</p>
                                {
                                    this.props.subTitle || this.props.shortDesc ? (
                                        <p className={"icon-subtext"}>{ this.props.subTitle || this.props.shortDesc }</p>
                                    ) : null
                                }
                            </div>
                            
                            {
                                this.props.arrow ?
                                    <div className={"icon-arrow col-md-2" + (!this.props.file && !this.props.fileLocal ? " blank" : "") + (this.props.open ? " open" : "")}>
                                        {
                                            !this.props.open ?
                                            <img src={rightArrowIcon} alt="Right arrow" />
                                            :
                                            <img src={downArrowIcon} alt="Down arrow" />
                                        }
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </a>
                    {
                        this.props.hasOwnProperty('longDesc') && this.props.longDesc ?
                            <a className="icon-button-longdesc-trigger" onClick={() => this.props.onInfoClick({title: this.props.title, long_desc: this.props.longDesc, icon: this.props.file, link: this.props.link})}>
                                <img src={InfoIcon} style={{height: 30, width: 30}} />
                            </a>
                        :
                            null
                    }
                </div>
                );
            }
        }