import { AppService as App } from './app.js';

import firebase from 'firebase/compat/app';
import "firebase/compat/analytics";

export class AnalyticsService {
	static getInstance() {
		if (AnalyticsService.analytics) return AnalyticsService.analytics;

		firebase.initializeApp(App.firebaseConfig);
		AnalyticsService.analytics = firebase.app().analytics();

		return AnalyticsService.analytics;
	}

	static setScreen(name) {
		AnalyticsService.getInstance().setCurrentScreen(name);
		AnalyticsService.getInstance().logEvent('screen_view', { screen_name : name }); // Send event
	}

	static logEvent(name, params) {
		AnalyticsService.getInstance().logEvent(name, params); // Send event
	}

	static async setUser(userID, hil) {
		if (userID && hil) {
			await AnalyticsService.getInstance().setUserId(hil + '-' + userID);
			
			await AnalyticsService.getInstance().setUserProperties({'id_custom_dimension': hil + '-' + userID});
		}
	}

	static loggedIn() {
		AnalyticsService.getInstance().logEvent('login', {
			method: 'login'
		});
	}

	static setupGTM(tag) {
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',tag)
	}

}