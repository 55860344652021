import React from 'react';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { useSelector } from 'react-redux';
import { AppService as App } from 'smartpub-common/services/app.js';

export default function SectionHeader({ section, setShowChangeSupplierToggle, showChangeSupplierToggle }) {
    const supplier_id = section.id;
    const supplier = useSelector(state => supplierSelectors.getById(state, supplier_id));

    return (
        <>
            <div key={'section' + section.title} className={"supplier-header"}>
                <div className='container'>
                    <div className='supplier-name'>
                        {supplier && supplier_id !== 4 ? supplier.name : "Choose new supplier"}
                    </div>
                
                    { supplier_id !== 1 && supplier_id !== 3 ?
                        <button className='btn btn-small primary' onClick={() => {
                                setShowChangeSupplierToggle(supplier_id == 4 ? 'undefined' : supplier_id)
                                var change = supplier_id != 4 && supplier_id != null ? "tap_change_supplier" : "set_supplier";
                                App.analytics.logEvent(change);
                            }}
                        >{supplier_id != 4 && supplier_id != null ? "Change supplier" : "Set supplier"}</button>
                    : null }
                </div>
            </div>
        </>
    )
}