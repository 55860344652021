import {AppService as App} from "./app";
import {Linking} from "react-native";
import {Utils} from "./utils";

import { store } from '../redux/default';
import { selectors as supplierSelectors } from '../redux/slices/suppliers';
import { selectors as productSelectors } from '../redux/slices/products';
import { selectors as pubSelectors } from '../redux/slices/pub';


export class HistoryReportingService {

    constructor() {
        this.store = [];
    }

    load(page = 1) {
        return App.api.request("order/history/raw", {page: page}).then((response) => {
            this.store = this.store.concat(response.lines);

            return response.more;
        }).then((more) => {
            if (more)
                return this.load(page+1);
        });
    }

    getFilters() {
        // Get list of unique suppliers and products
        let suppliers = [],
            products = [];

        this.store.forEach(function(item) {
            const supplierObj = supplierSelectors.getById(store.getState(), item.supplier_id);
            if (!suppliers.includes(item.supplier_id) && supplierObj) {
                suppliers.push(item.supplier_id);
            }

            const productObj = productSelectors.getById(store.getState(), item.product_id);
            if (!products.includes(item.product_id) && productObj) {
                products.push(item.product_id);
            }
        });

        return {
            suppliers: suppliers,
            products: products
        }
    }

    getReport(params) {
        let suppliers = [],
            products = [],
            orders = {},
            lines = this.store.filter(item => {
                let allowed = true;

                // Remove items that don't match all the filter options
                for (let param in params) {
                    if (param === "from") {
                        if (new Date(item['created_at']) < params[param]) {
                            allowed = false;
                            break;
                        }
                    } else if (param === "to") {
                        if (new Date(item['created_at']) > params[param]) {
                            allowed = false;
                            break;
                        }
                    } else if (params[param] && item[param] !== params[param]) {
                        allowed = false;
                        break;
                    }
                }

                if (!allowed) return false;

                // Add this lines supplier to list of suppliers
                const supplierObj = supplierSelectors.getById(store.getState(), item.supplier_id);
                if (!suppliers.includes(item.supplier_id) && supplierObj) {
                    suppliers.push(item.supplier_id);
                }

                // Add this lines product to list of products
                const productObj = productSelectors.getById(store.getState(), item.product_id);
                if (!products.includes(item.product_id) && productObj) {
                    products.push(item.product_id);
                }

                // Add this line to the grouped orders list
                if (!orders[item.order_id]) {
                    orders[item.order_id] = {
                        date: item.created_at,
                        lines: []
                    }
                }
                // Get products par
                const favourite = pubSelectors.favouriteById(store.getState(), item.product_id);
                item.par = favourite ? favourite.par : 0;

                orders[item.order_id].lines.push(item);

                return true;
        });

        for (const order in orders) {
            orders[order].qty = orders[order].lines.reduce((a, b) => a + (b.qty || 0), 0);
            orders[order].stock = orders[order].lines.reduce((a, b) => a + (b.stock || 0), 0);
            orders[order].par = orders[order].lines.reduce((a, b) => a + (b.par || 0), 0);
        };

        // Convert orders object to array
        let ordersArray = [];
        for (const [key, value] of Object.entries(orders)) {
            ordersArray.push(value);
        }

        return {
            lines: lines,
            orders: ordersArray,
            suppliers: suppliers,
            products: products,
        }
    }

    download(params) {
        let query = Object.keys(params)
            .map(key => {
                if (key === "from" || key === "to") {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(Utils.getDateString(params[key]))}`
                } else {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
                }
            })
            .join('&');

        var url = App.baseURI + '/api/history/report?' + query + '&pub_id=' + App.api.pub + '&token=' + App.user.details.token;

        Linking.openURL(url);
        App.analytics.logEvent('history_report_download');
    }

}