import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { ApiService as Api } from '../../services/api';
import { Utils } from '../../services/utils';

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    loading: 'idle',
    items: []
}

export const fetch = createAsyncThunk('suppliers/fetch', async () => await Api.request('suppliers'));

const slice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
                App.initUpdate('Suppliers', 0);
            }
        });

        builder.addCase(fetch.fulfilled, (state, { meta, payload }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.items = payload.map((item) => {
                item.name = item.supplier;
                item.supplier_id = item.id;
                return item;
            });

            state.loading = 'idle'
            state.currentRequestId = undefined

            App.initUpdate('Suppliers', 1);
            App.cache.set("suppliers", state.items);
        });

        builder.addCase(fetch.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.loading = 'idle'
            state.currentRequestId = undefined

            console.error("Unable to load suppliers");
            App.initUpdate('Suppliers', 2);
            
            // Load from cache as offline
            App.cache.get("suppliers").then((items) => {
                state.items = items;
            });
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;

const getById = createMapSelector([
    state => state.suppliers.items,
    (state, id) => parseInt(id),
    (state, id, showInactive) => showInactive,
], (suppliers, id, showInactive) => {
    return suppliers.find(supplier => supplier.id === id && (supplier.active === true || !showInactive));
});

const getByName = createMapSelector([
    state => state.suppliers.items,
    (state, name) => name
], (suppliers, name) => {
    return suppliers.find(supplier => supplier.name === name);
});

export const selectors = {
    get: createMapSelector([
        state => state.suppliers.items
    ], (suppliers) => suppliers.filter(item=> item.id !== 4)),
    getById,
    getByName
};
