import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom';
import Button from "./Button";

import { store } from 'smartpub-common/redux/default';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as pubSelectors, updateSupplier } from 'smartpub-common/redux/slices/pub';
import { useSelector, useDispatch } from "react-redux";
import { showMessage } from "../facades/showMessage";

import closeIcon from '#assets/icons/close.svg';

import _ from "lodash";

export const PopUpEditSupplier = ({ visible, supplier, type = "create", onDismiss }) => {
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierId, setSupplierId] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const title = type == "create" ? 'Add supplier' : 'Edit supplier';
    const buttonLabel = type == "create" ? 'Add' : 'Save';

    const suppliers = useSelector(supplierSelectors.get);
    const favouriteSuppliers = useSelector(pubSelectors.suppliers);
    const favouriteSupplier = useSelector(state => pubSelectors.supplierById(state, supplier?.id));

    useEffect(() => {
        setSupplierId(favouriteSupplier ? favouriteSupplier.supplier_id : null);
        setAccountNumber(favouriteSupplier ? favouriteSupplier.account_number : null);
    }, [visible, favouriteSupplier]);

    useEffect(() => {
        // No point getting suppliers if no dropdown visible
        if (type === "edit") {
            setSupplierOptions([]);
        }

        // Filter out Heineken suppliers and favourited ones
        let filtered = suppliers.filter(supplier => !supplier.is_heineken);
        filtered = _.differenceBy(filtered, favouriteSuppliers, 'supplier_id');

        // remove inactive suppliers
        filtered = _.filter(filtered, ['active', 1]);

        setSupplierOptions(
            filtered.map(supplier => ({
                label: supplier.name,
                value: supplier.id
            }))
        );
    }, [suppliers, favouriteSuppliers, type]);

    const addSupplier = (supplier_id, account_number, isNew) => {
        setLoading(true);
        setError(null);

        dispatch(updateSupplier({ supplier_id, account_number })).unwrap().then(() => {
            const supplierObj = supplierSelectors.getById(store.getState(), supplier_id);
            console.log(supplier_id, supplierObj);
            showMessage({
                message: "Supplier " + (isNew ? "added" : "updated"),
                description: supplierObj.name + " " + (isNew ? "added" : "updated"),
                backgroundColor: "#1b9d54"
            });

            setLoading(false);
            onDismiss();
        }, () => {
            showMessage({
                message: "Error adding supplier",
                type: "danger"
            });
            setLoading(false);
        });
    }

    const renderContent = () => {
        var buttonDisabled = (!supplierId && !supplier) || !accountNumber;

        if (type !== "create" && !supplier) return;

        return (
            <React.Fragment>
                <div className="label">Supplier</div>
                {
                    type == "edit" ?
                        <small>{ supplier.name }</small>
                    :
                        <select onChange={({ target }) => {
                            setSupplierId(target.value);
                        }} value={supplier?.supplier_id}>
                            <option value="0">Select a supplier...</option>
                            {
                                supplierOptions.map(({ label, value }) =>
                                    <option value={ value }>{ label }</option>
                                )
                            }
                        </select>
                }

                <div className="label">Account number</div>
                <input
                    className="popup-account-number"
                    placeholder="12345..."
                    defaultValue={accountNumber}
                    onChange={({ target }) => { setAccountNumber(target.value) }}
                />

                {
                    error ?
                        <small className="error">{props.error.message}</small>
                    :
                        null
                }

                <Button
                    loading={loading}
                    id="date-search-btn"
                    theme="primary"
                    onClick={() => {
                        addSupplier(supplier ? supplier.supplier_id : supplierId, accountNumber);
                    }}
                    disabled={buttonDisabled}
                    title={buttonLabel}
                />
            </React.Fragment>
        )
    }

    if (!visible) return;

    return createPortal(
        <div className={"popup popup--" + type}>
            <div id="popup-filter" onClick={onDismiss}>
            </div>

            <div id="popup-box">
                <h5 className="text-center">
                    { title }
                    <div id="popup-close" onClick={onDismiss}>
                        <img src={closeIcon} alt="close" />
                    </div>
                </h5>

                { renderContent() }
            </div>
        </div>
    , document.getElementById('root'));
}