import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    sort: "stock",
    filter_supplier: null,
    filter_category: null
}

const preferenceSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        setSort: (state, action) => state.sort === action.payload ? state : { ...state, sort: action.payload },
        setFilterSupplier: (state, action) => state.filter_supplier === action.payload ? state : { ...state, filter_supplier: parseInt(action.payload) },
        setFilterCategory: (state, action) => state.filter_category === action.payload ? state : { ...state, filter_category: parseInt(action.payload) },
        clearFilters: (state) => ({ ...state, ...initialState })
    }
});
export const actions = preferenceSlice.actions;
export default preferenceSlice.reducer;


export const selectors = {
    getSort: state => state.preferences.sort,
    getFilterSupplier: state => state.preferences.filter_supplier,
    getFilterCategory: state => state.preferences.filter_category,
}