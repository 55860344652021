import React, { useState } from 'react';

import ProductName from '../product/Name';

import { showMessage } from "../../facades/showMessage";

import Value from '../product/Value';
import { useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { actions as orderActions } from 'smartpub-common/redux/slices/order';
import { pinProduct as pinProductThunk, selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';

import pinIconGreen from '#assets/icons/pin-icon-green.svg';
import pinIcon from '#assets/icons/pin-icon.svg';
import plusIcon from '#assets/icons/plus-icon-teal.svg';
import { ActivityIndicator } from 'react-native';

export const OrderProductItem = ({ item, setPopupQuantityConfig }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const [savingPin, setSavingPin] = useState(false);
    const isPinned = useSelector(state => pubSelectors.isPinned(state, item.id));

    const addItem = (quantity) => {
        return new Promise((resolve, reject) => {
            dispatch(orderActions.addOrUpdate({
                product_id: item.id,
                quantity,
                supplier_id: item.supplier === 4 ? null : item.supplier
            }));
            showMessage({
                message: item.name + " added to order",
                backgroundColor: "#1b9d54"
            });

            resolve(true);
        });
    }

    const popupConfig = {
        product: item.id,
        quantity: 0,
        onSave: addItem
    }

    const pinProduct = () => {
        if (savingPin) return;

        setSavingPin(true);

        dispatch(pinProductThunk(item.id))
            .unwrap()
            .finally(() => setSavingPin(false));
    }

    return (
        <div className="product-list-item" key={"product" + item.product_id}>
            <div className="row sort">    
                <div className="col-md-12">
                    <div className="row">
                        <div className="product-list-item-left flex-fill">
                            <div className={"product-list-item-name-container sortable"}>
                                <ProductName item={{ product_id: item.id }} showPrice={false} onPress={()=> {
                                    setPopupQuantityConfig(popupConfig);
                                }} />
                            </div>
                        </div>
                        <div className="product-list-item-right col-md-3">
                            <div className="editable-content">
                                {
                                    item.status === 1 ?
                                        <button className="pin-container pinned" onClick={() => setPopupQuantityConfig(popupConfig)}>
                                            <img src={plusIcon} />
                                        </button>
                                    : null
                                }

                                <button className={ "pin-container " + (isPinned ? "pinned" : "") } onClick={pinProduct}>
                                    {
                                        savingPin ?
                                            <ActivityIndicator size="small" color={isPinned ? '#026d7c' : '#CEFAB7'} />
                                        :
                                            <img src={isPinned ? pinIcon : pinIconGreen} />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}