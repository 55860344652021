import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    confirmation: null
}

let confirmationTimeout;

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showConfirmation: (state, action) => {
            state.confirmation = action.payload;
        },
        hideConfirmation: (state, action) => {
            state.confirmation = null;
        }
    }
});
export const actions = appSlice.actions;
export default appSlice.reducer;


export const selectors = {
    getConfirmation: state => state.app.confirmation
}