import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions, selectors as orderSelectors } from 'smartpub-common/redux/slices/order';
import { useNavigate } from 'react-router-dom';

import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import Button from '../../Button.js';
import MobileInput from '../MobileInput';

export default function Footer({ onSubmit, submitting }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isCounter = useSelector(orderSelectors.isCounter);

    const deliveryDateOptions =  useSelector(pubSelectors.deliveryDates);
    const order = useSelector(state => state.order);

    const [deliveryDates, setDeliveryDates] = useState([]);
    const [deliveryDateIndex, setDeliveryDateIndex] = useState(0);

    const payableItems = useSelector(orderSelectors.getPayable);
    const pubIsReca = useSelector(state => pubSelectors.details(state, "reca"));
    const isPayable = pubIsReca && payableItems.length;

    const total =  useSelector(orderSelectors.getTotal);
    const vat =  useSelector(state => orderSelectors.getTotal(state, true, true));
    const payable =  useSelector(state => orderSelectors.getTotal(state, true, false));
    const orderableItems = useSelector(state => orderSelectors.getItems(state, true));
    // Does order have heineken products
    const hasHILproducts = _.some(orderableItems, item => item.supplier_id === 1 || item.supplier_id === 3);

    // Init
    useEffect(() => {
        if (!isCounter && hasHILproducts) {
            const tempDeliveryDates = deliveryDateOptions;

            setDeliveryDates(tempDeliveryDates);
            if (tempDeliveryDates.length && !order.delivery_date) {
                dispatch(orderActions.setDeliveryDate(tempDeliveryDates[0]))
            }
        }
    }, []);

    useEffect(() => {
        if (isCounter || !deliveryDates.length) {
            return;
        }

        dispatch(orderActions.setDeliveryDate(deliveryDates[deliveryDateIndex]))
    }, [deliveryDateIndex, deliveryDates]);

    return (
        <div className="col-md-12" id="product-list-buttons">
            <div className="row">
                <div className="col-md-12 margin-bottom">
                    <Button
                        theme="secondary"
                        title="Go back and add more items"
                        onClick={() => navigate("/order/new")}
                    />
                </div>

                { isCounter ?
                    <MobileInput />
                : 
                    <>
                        {
                            deliveryDateOptions.length && hasHILproducts ?
                                <div className="col-md-12">
                                    <label>Scheduled HEINEKEN delivery date</label>
                                    <select onChange={({ target }) => { setDeliveryDateIndex(target.value) }}>
                                        <option value="-1" disabled>Select delivery date...</option>
                                        {deliveryDates.map((date, index) => <option value={index}>{date.delivery}</option>)}
                                    </select>
                                </div>
                            : null
                        }
                        <div className="col-md-12">
                            <label>Additional Delivery Instructions for Truck Driver</label>
                            <textarea onChange={({ target }) => dispatch(orderActions.setDeliveryNote(target.value))} defaultValue={order.delivery_note}></textarea>
                        </div>

                        {
                            isPayable ?
                                <div style={{margin: 15}}>
                                    <p style={{marginBottom: 7, fontSize: 16, color: "#ffffff"}}>Total: { total }</p>
                                    <p style={{marginBottom: 7, fontSize: 16, color: "#ffffff"}}>VAT: { vat }</p>
                                    <p style={{marginBottom: 7, fontSize: 16, color: "#ffffff"}}>Total payable: { payable }</p>
                                </div>
                            : null
                        }
                    </>

                }

                <div className="col-md-12">
                    <Button
                        theme="primary"
                        title={isPayable ? "Purchase now" : "Continue"}
                        onClick={onSubmit}
                        loading={submitting}
                    />
                </div>
            </div>
        </div>
    )
}