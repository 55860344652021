import { AppService as App } from 'smartpub-common/services/app.js';
import React, { useState } from 'react';
import Button from '../includes/Button';
import { Utils } from 'smartpub-common/services/utils.js';
import { showMessage } from "../facades/showMessage";
import { PopUpReminder } from "../includes/PopUpReminder";
import {
    useNavigate,
    useParams
} from "react-router-dom";

import { selectors as reminderSelectors } from 'smartpub-common/redux/slices/reminders';
import { update as updateReminderThunk, remove as removeReminderThunk } from 'smartpub-common/redux/slices/reminders';
import { useSelector, useDispatch } from 'react-redux';

export default function ReminderItem() {
    const [popupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    let reminder = useSelector(state => reminderSelectors.getById(state, params.rid));
    if (!reminder) return null;

    const dateStr = Utils.formatDateRange(reminder.start_date, reminder.end_date);

    const updateReminder = (newReminder) => {
        if (loading) return false;
        setLoading(true);

        return dispatch(updateReminderThunk(newReminder)).unwrap().then(() => {
            showMessage({
                title: "Reminder updated",
                message: newReminder.title + " updated",
            });

            setPopupVisible(false);
        }, (e) => {
            console.error(e);
            alert("Reminder could not be updated", e.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const removeReminder = () => {
        if(loading) return false;
        setLoading(true);

        return dispatch(removeReminderThunk(reminder.id)).unwrap().then(() => {
            showMessage({
                title: "Reminder removed",
                message: reminder.title + " removed"
            });

            navigate('/reminders', { replace: true });
        }, (e) => {
            console.error(e);
            alert("Reminder could not be removed", e.message);
        }).finally(() => {
            setLoading(false);
        });
    }
    
    const renderDescription = () => {
        if (!reminder.description) return;

        // Don't render personal reminders as HTML
        if (reminder.personal) {
            return <span id="reminder-notes">{ reminder.description }</span>;
        }

        return <div id="reminder-notes" dangerouslySetInnerHTML={{__html: reminder.description}} />
    }

    return (
        <div className="container">
            <div className="row">

                <div className="block block--reminder">
                    <div className="header header-big">
                        <div className="title">
                            <h4>{reminder.title}</h4>
                        </div>
                    </div>
            
                    <div className="content">
                        <p id="reminder-until">{dateStr}</p>
                        { renderDescription() }
                        {
                            reminder.personal && App.user.hasPermission("reminder") ?
                                <div className="mt-4">
                                    <Button
                                        title="Edit reminder"
                                        theme='primary'
                                        onClick={() => setPopupVisible(true)}
                                    />
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>

            {
                <PopUpReminder
                    modalBtn="Create"
                    modalTitle="New MyPub reminder"
                    onSubmit={updateReminder}
                    onDismiss={() => setPopupVisible(false)}
                    isVisible={popupVisible}
                    loading={loading}
                    reminder={{...reminder}}
                    onRemove={removeReminder}
                    clearFilter={() => {}}
                    filter={() => {}}
                />
            }
        </div>
    )
}