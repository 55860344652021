import React, { useState } from 'react';
import {Utils} from 'smartpub-common/services/utils.js';

import Genie from '#assets/icons/Genie-big.svg';
import notificationsIcon from '#assets/icons/notifications-icon-teal.svg';
import nextIcon from '#assets/icons/next-back-icon-green.svg';
import nextIconTeal from '#assets/icons/next-back-icon-teal.svg';
import { useNavigate } from 'react-router-dom';


export const ReminderItem = ({ reminder, summary }) => {
    const navigate = useNavigate();

    var dateStr = Utils.formatDateRange(reminder.start_date, reminder.end_date);

    return (
        <a href="#" className="reminder"
            onClick={(e) => {
                e.preventDefault()
                navigate("/reminder/" + reminder.id);
            }}
        >
            <div className={reminder.personal ? 'reminder-icon' : 'reminder-icon reminder-icon-hein'}>
                { reminder.personal ? 
                    <img src={notificationsIcon} alt="Notification icon" />
                : 
                    <img src={Genie} alt="Genie icon" />
                }
            </div>
            <div className="reminder-content">
                <h4>{ reminder.title }</h4>
                <p>{ dateStr }</p>
            </div>
            <img src={summary ? nextIconTeal : nextIcon} className='arrow' alt="arrow" />
        </a>
    )
}
