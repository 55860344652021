import React, { useState, useEffect, useMemo } from 'react';
import SearchBar  from '../../includes/history/SearchBar';

import { AppService as App } from 'smartpub-common/services/app.js';
import { Utils } from 'smartpub-common/services/utils.js';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import {
    ActivityIndicator
} from 'react-native';
import nextIcon from '#assets/icons/next-back-icon-green.svg';

import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';

const Suborder = ({ item }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = location.search;
    let pathname = `/history/order/${item.id}`;

    if(searchParams != '') pathname += `?${searchParams.replace('?', '')}`;

    const supplier = useSelector(state => supplierSelectors.getById(state, item.supplier_id));

    return (
        <button
            className='history-order-item'
            onClick={() => navigate(
                pathname, {
                    state: {
                        order: item.id
                    }
                }
            )}
        >
            <div className='container'>
                <div className='history-order-item-bullet'></div>
                <div className='history-item-details'>
                    <div className='history-supplier-name'>{ supplier.name ?? null }</div>
                    <div className='history-ref'>
                        { Utils.getDateString(item.order_date, false, true) } | {
                            item.po ?
                                'PO: ' + item.po
                            :
                                "OrdRef: " + item.ref
                        }
                    </div>
                </div>
                <img src={nextIcon} className='history-item-arrow' />
            </div>
        </button>
    );
}

export default function History() {
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const loadHistory = (options = {}) => {
        setHistory([]);
        setLoading(true);
        
        return App.pub.history.getSuborders(options).then((data) => {
            setLoading(false);
            setHistory(data);
        }, (err) => {
            setLoading(false);
            setHistory(null);
        });
    }

    useEffect(() => {
        let options = {};

        if(searchParams) {
            options = {
                reference: searchParams.has('reference') ? searchParams.get('reference') : null,
                supplier_id: searchParams.has('supplier_id') ? searchParams.get('supplier_id') : null,
                start: searchParams.has('start') ? searchParams.get('start') : null,
                end: searchParams.has('end') ? searchParams.get('end') : null
            }
        }

        try {
            loadHistory(options);
        } catch(err) {
        }
    }, [searchParams]);

    return (
        <React.Fragment>        
            <div id="history">
                <div className="container row content">
                    <div className="history-search">
                        <p style={{fontSize: 14}} className='history-caption'>Please note the date shown here is the date the order was placed/created. If you wish to view order history older than 3 months, please use the Search or Report facility</p>
                        <div className='history-header'>
                            <SearchBar />
                        </div>
                    </div>
                </div>
                <div className="history-orders-container container text-center">
                    {
                        loading ? 
                            <div>
                                <ActivityIndicator size="small" color="#ffffff" />
                                <div>Loading order history</div>
                            </div>
                        : (
                            !history || !history.length ?
                                <div>No history found</div>
                            :
                                <FlatList
                                    data={loading ? [] : history}
                                    renderItem={({item}) => 
                                        <Suborder
                                            key={item.product_id}
                                            item={item}
                                        />
                                    }
                                    keyExtractor={item => item.id}
            
                                    // Performance settings
                                    removeClippedSubviews={true} // Unmount components when outside of window
                                    initialNumToRender={50} // Reduce initial render amount
                                    maxToRenderPerBatch={50} // Reduce number in each render batch
                                    windowSize={50} // Reduce the window size
                                />
                        )
                    }

                </div>
            </div>
        </React.Fragment>
    );
}