import React, { useState, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../includes/Button';
import { Utils } from 'smartpub-common/services/utils.js';
import Genie from '#assets/icons/Genie-big.svg';
import editIcon from '#assets/icons/edit-icon-green.svg';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as pubSelectors, updateDetails } from 'smartpub-common/redux/slices/pub';
import { showMessage } from '../facades/showMessage';

export default function PubInfo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [edit, setEdit] = useState({ pubInfo: false });
    const [loading, setLoading] = useState(false);
    const pubDetails = useSelector(pubSelectors.details);
    const deliveryDates = useSelector(pubSelectors.deliveryDates);
    const metaData = useSelector(pubSelectors.metaData);
    const [deliveryText, setDeliveryText] = useState('');

    useEffect(() => {
        setDeliveryText(pubDetails.delivery_notes)
    }, [pubDetails]);

    const triggerEdit = (field) => {
        edit[field] = edit[field] ? false : true;

        setEdit({ pubInfo: edit[field] });
    }

    let saveInfo = (field) => {
        if (loading) return;

        setLoading(true);

        var data = {
            'delivery_notes': deliveryText,
        }

        // Save delivery note
        dispatch(updateDetails(data)).unwrap().then(() => {
            setLoading(false);

            triggerEdit(field);

            showMessage({
                title: "Info update",
                message: "Settings have been saved successfully"
            })

            setEdit({ pubInfo: false });
        });
    }

    const renderPubInfo = () => {
        return (
            <React.Fragment>
                
                <div className="block block--pub-info">
                    <div className="header">
                        <h4>Pub information</h4>
                        <a href="#!" onClick={() => triggerEdit("pubInfo")}>
                            <img src={editIcon}  />
                        </a>
                    </div>
            
                    <div className="content">
                        <div>
                            <label>Outlet name:</label>
                            <p>{pubDetails.name}</p>
                        </div>

                        <div>
                            <label>Address:</label>
                            <p>{pubDetails.address_line_1}<br/>
                            {pubDetails.city}</p>
                        </div>

                        <div>
                            <label>Heineken Acc Supplier No:</label>
                            <p>{pubDetails.account_no}</p>
                        </div>
                        {
                            edit.pubInfo ?
                                <React.Fragment>
                                    <div>
                                        Delivery note:
                                        <br />
                                        {App.user.hasPermission('order') ?
                                            <input
                                                type="text"
                                                value={deliveryText}
                                                onChange={(e) => setDeliveryText(e.target.value)}
                                            />
                                        :
                                            pubDetails.delivery_notes
                                        }
                                    </div>

                                    <Button
                                        theme="secondary"
                                        title="Save"
                                        hasMargin={true}
                                        onClick={() => saveInfo("pubInfo")}
                                    />
                                </React.Fragment>
                            :
                                <div>
                                    <label>Delivery note:</label>
                                    <p>{pubDetails.delivery_notes}</p>
                                </div>
                        }
                    </div>

                </div>
                <Button
                    theme="primary"
                    title="Go to T&Cs"
                    onClick={() => {
                        navigate("/terms", { state: { pubinfo: true } });
                    }}
                />
            </React.Fragment>
        );
    }

    const renderRepInfo = () => {
        if (!pubDetails.rep) return;

        return (
            <div className="block block--pub-info">
                <div className="header header-big">
                    <div className="title">
                        <h4>Pub rep</h4>
                    </div>
                </div>

                <div className="content">
                    <div className="jumbotron-content">
                        {
                            pubDetails.rep.name ?
                                <div>
                                    <p>
                                        Name:<br/>
                                        {pubDetails.rep.name}
                                    </p>
                                </div>
                            : null
                        }

                        {
                            pubDetails.rep.phone ?
                                <div>
                                    <p>
                                        Phone:<br/>
                                        {pubDetails.rep.phone}
                                    </p>
                                </div>
                            : null
                        }

                        {
                            pubDetails.rep.email ?
                                <div>
                                    <p>
                                        Email:<br/>
                                        <a href={"mailto:" + pubDetails.rep.email}>{pubDetails.rep.email}</a>
                                    </p>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    const renderDeliveryDates = () => {
        if (!deliveryDates || !deliveryDates.length) return null;

        const dateMarkup = deliveryDates.map((date) => (
            <div className='block--row'>
                <div className='block--column'>{ date.delivery }</div>
                <div className='block--column'>{ date.cutoff }</div>
            </div>
        ));

        return (
            <div className="block block--next-delivery">
                <img src={Genie} className='genie' />
                <div className='block--row block--top'>
                    <div className='block--column block--header'>HEINEKEN delivery dates</div>
                    <div className='block--column block--header'>Order cut off dates</div>
                </div>
                { dateMarkup }
            </div>
        );
    }
    
    const renderCleaningInfo = () => {
        var last = metaData.last_clean_dt,
            next = metaData.next_clean_dt;

        if (!next && !last) return null;

        return (
            <div className="block block--cleaning-info">
                <div className="header header-big">
                    <div className="title">
                        <h4>Heineken line cleaning info</h4>
                    </div>
                </div>
                <div className="content">
                    <div className='block--row block--top'>
                        <div className='block--column block--header'>Last clean date:</div>
                        <div className='block--column'>{ Utils.dateUntil(last, false) }</div>
                    </div>
                    <div className='block--row'>
                        <div className='block--column block--header'>Next clean by date:</div>
                        <div className='block--column'>{ Utils.dateUntil(next, false) }</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="pub-info">
            <div className="container">
                <div className='row'>
                    <div className='col' style={{paddingLeft: 0}}>
                        {renderPubInfo()}
                    </div>
                    <div className='col' style={{paddingRight: 0}}>
                        {renderDeliveryDates()}
                        {renderCleaningInfo()}
                    </div>
                </div>
            </div>
        </div>
    );
}