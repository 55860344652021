import { store } from 'smartpub-common/redux/default.js';
import { actions as appActions } from 'smartpub-common/redux/slices/app';

let messageTimeout;

export function showMessage(data) {
    clearTimeout(messageTimeout);
    store.dispatch(appActions.showConfirmation(data));

    
    if (data.timeout === false) {
        return;
    }

    messageTimeout = setTimeout(() => {
        store.dispatch(appActions.hideConfirmation());
    }, 3000);
}