import React, { useState, useEffect, useMemo } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import DatePicker from "react-datepicker";
import Button from "../Button";
import CalendarIcon from '#assets/icons/calendar-icon-active.svg';

import { useSelector } from 'react-redux';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { useSearchParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';

import _ from 'lodash';

const DateRangeInput = ({ fromDate, setFromDate, toDate, setToDate }) => {
    let calendarIcon = CalendarIcon;
    const [historyRange, setHistoryRange] = useState('');

    useEffect(() => {
        App.pub.history.getRange().then(setHistoryRange);  
    }, []);

    return (
        <div id="calendar-range">
            <div id="calendar-from" className={"calendar-container input date--show"}>
                <label
                    id="calendar-to-label"
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { fromDate ? null : "From" }
                </label>
                <DatePicker
                    selected={fromDate}
                    onChange={setFromDate}
                    minDate={new Date(historyRange.first)}
                    maxDate={toDate || new Date(historyRange.last)}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div id="calendar-to" className={"calendar-container input date--show"}>
                <label
                    id="calendar-to-label"
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { toDate ? null : "To" }
                </label>
                <DatePicker
                    selected={toDate}
                    onChange={setToDate}
                    minDate={fromDate || new Date(historyRange.first)}
                    maxDate={new Date(historyRange.last)}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        </div>
    )
}

const SuppliersInput = ({ supplier, setSupplier }) => {
    let suppliers = useSelector(supplierSelectors.get);

    return (
        <div className='input'>
            <select name="suppliers" className="suppliers" onChange={({ target }) => setSupplier(target.value)}>
                <option value="" selected={!supplier ? 'selected' : ''}>Select supplier</option>
                {
                    suppliers.map(({ id, name }) =>
                        <option value={id} selected={(id == supplier ? 'selected' : '')}>{name}</option>
                    )
                }
            </select>
        </div>
    );
}

const ReferenceInput = ({ reference, setReference, onSubmit }) => {
    return (
        <div className='input'>
            <input 
                type="text" 
                name="ref" 
                className='ref' 
                placeholder='Search PO, reference No'
                value={reference}
                onChange={({target})=> setReference(target.value)}
                onKeyUp={event => {
                    if (event.key === 'Enter') {
                        onSubmit()
                    }
                }}
            />
        </div>
    );
}

export default function SearchBar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [supplier, setSupplier] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [reference, setReference] = useState(null);

    useEffect(() => {
        setReference(searchParams.get('reference'));
        setSupplier(searchParams.get('supplier_id'));
        setFromDate(searchParams.has('start') ? new Date(searchParams.get('start')) : null);
        setToDate(searchParams.has('end') ? new Date(searchParams.get('end')) : null);
    }, [searchParams]);

    const search = () => {
        const options = _.omitBy({
            end: toDate,
            start: fromDate,
            supplier_id: supplier,
            reference: reference
        }, _.isNil);

        App.analytics.logEvent('history_search');
        if (location.pathname === "/history/") {
            setSearchParams(options);
        } else {
            const path = "/history?" + createSearchParams(options).toString();
            navigate(path)
        }
    }

    const clear = () => {
        if (location.pathname === "/history/") {
            setSearchParams({});
        } else {
            navigate("/history");
        }
    }

    return (
        <>
            <div className='search-container'>
                <div className='search-bar'>
                    <SuppliersInput
                        supplier={supplier}
                        setSupplier={setSupplier}
                    />
                    <ReferenceInput
                        reference={reference}
                        setReference={setReference}
                        onSubmit={search}
                    />
                    <DateRangeInput
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                    />
                </div>
                <Button
                    theme="primary"
                    onClick={search}
                    title="Search"
                />
                {
                    searchParams.size ? 
                        <Button
                            theme="secondary"
                            style={{marginLeft: 10}}
                            onClick={clear}
                            title="Clear"
                        />
                    :
                        null
                }

                <Button
                    theme="secondary"
                    onClick={() => navigate("/history/report")}
                    style={{marginLeft: "1rem"}}
                    title="Report"
                />
            </div>
        </>
    );
}