import React from 'react';
import { useSelector } from 'react-redux';
import {selectors as orderSelectors } from 'smartpub-common/redux/slices/order';

export default function ColumnHeaders() {
    const isCounter = useSelector(orderSelectors.isCounter);
    return (
        <div className="table-header row order-header">
            <div className="flex-fill pl-0">
                <p className="text-left">Item</p>
            </div>
            <div className="col-md-0">
                <p className="text-center">{ isCounter ? null : 'Stock Count'}</p>
            </div>
            <div className="col-md-0">
                <p className="text-center">{ isCounter ? null : 'Par'}</p>
            </div>
            <div className="col-md-0">
                <p className="text-center">Order Quantity</p>
            </div>
            <div className="col-md-0"></div>
        </div>
    )
}