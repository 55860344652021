import React from 'react';
import { IconButton } from '../includes/IconButton';
import { AppService as App } from 'smartpub-common/services/app.js';
import { PopUpAlert } from '../includes/PopUpAlert.js';

import infoIcon from '../../assets/icons/info-icon.svg';
import moreIcon from '../../assets/icons/more-vertical-icon.svg';
import nextIcon from '../../assets/icons/next-back-icon-teal.svg';

export default class SocialMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "Smart Links",
            items: App.smartlinks.get(),
            currentTitle: "",
            currentInfo: "",
            currentIcon: "",
            currentLink: "",
            showPopup: false,
        }

        this.hidePopup = this.hidePopup.bind(this);
        this.showPopup = this.showPopup.bind(this);
    }

    showPopup(link, event) {
        event.preventDefault();
        this.setState({
            showPopup: true,
            currentTitle: link.title,
            currentInfo: link.long_desc,
            currentIcon: link.icon,
            currentLink: link.link
        })
    }

    hidePopup() {
        this.setState({
            showPopup: false,
            currentTitle: "",
            currentInfo: "",
            currentIcon: "",
            currentLink: ""
        });
    }

    render() {
        let pId = this.state.title.replace(/ /g, "-").toLowerCase();

        let links = this.state.items.map((item) => (
            <div className="smartlink-item">
                <a href={item.link} className="smartlink" target="_blank">
                    { item.icon ?
                        <div className="icon" style={{backgroundImage: "url(" + item.icon + ")"}}></div>
                        :
                        <div className="icon icon--icon" style={{backgroundImage: "url("+ infoIcon +")"}}></div>
                    }
                    <div className="content">
                        <h3>{ item.title }</h3>
                        <p>{ item.short_desc }</p>
                    </div>

                    <div className="controls">
                        { item.long_desc ?
                            <div className="smartlink-more" onClick={(event) => this.showPopup({title: item.title, long_desc: item.long_desc, link: item.link}, event)}>
                                <img src={moreIcon} style={{height: 30, width: 30}} />
                            </div>
                        :
                            null
                        }

                        <img src={nextIcon} style={{height: 30, width: 30}} />
                    </div>
                </a>
            </div>
        ));

        return (
            <div id={pId} className="row">
                <div className="container">
                    <div className="row smartlinks-container">
                        {links}
                    </div>
                </div>

                <PopUpAlert
                    modalTitle={this.state.currentTitle}
                    htmlContent={this.state.currentInfo}
                    icon={this.state.currentIcon}
                    link={this.state.currentLink}
                    onDismiss={this.hidePopup}
                    isVisible={this.state.showPopup}
                />
            </div>
        );
    }
}