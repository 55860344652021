import React from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as pubSelectors, removeFavourite, updateFavourite } from 'smartpub-common/redux/slices/pub';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import ProductName from '../product/Name';
import { showMessage } from '../../facades/showMessage.js';
import closeIcon from '#assets/icons/close.svg';
import Value from '../product/Value.js';

export const ProductItem = ({ item, visible, setConfirm, setQuantity }) => {
    const dispatch = useDispatch();

    const product = useSelector(state => productSelectors.getById(state, item.product_id));

    const favourite = useSelector(state => pubSelectors.favouriteById(state, item.product_id));
    const par = favourite && favourite.par ? favourite.par : 0;

    const updateItem = (quantity) => {
        return dispatch(updateFavourite({ product_id: item.product_id, par: quantity })).unwrap().then(data => {
            showMessage({
                title: "Set par",
                message: product.name + " updated"
            });
        });
    }

    const confirmConfig = {
        title: "Remove item",
        content: 'Remove '+ product.name + '.\n\nAre you sure you want to continue?',
        onConfirm: () => {
            return dispatch(removeFavourite(item.product_id)).unwrap().then(data => {
                showMessage({
                    message: product.name + " removed"
                });
            });
        }
    }

    const quantityConfig = {
        product: item,
        quantity: par,
        onSave: updateItem
    }

    if (!visible) return;

    return <>
        <div className="product-list-item" key={"product" + product.id}>
            <div className="row sort">    
                <div className="col-md-12">
                    <div className="row">
                        <div className="product-list-item-left flex-fill">
                            <div className={"product-list-item-name-container sortable"}>
                                <ProductName item={item} showPrice={true} onPress={()=> {
                                    setQuantity(quantityConfig);
                                    App.analytics.logEvent('tap_product_line', {'product': product.name});
                                }} />
                            </div>
                        </div>
                        <div className="product-list-item-right col-md-3">
                            <div className="editable-content">
                                <Value
                                    value={par}
                                    onClick={() => {
                                        setQuantity(quantityConfig);
                                    }}
                                />
                            </div>

                            <div className="col-md-0 remove justify-right">
                                <a onClick={() => setConfirm(confirmConfig)} className="remove-container">
                                    <img className="remove" src={closeIcon} /> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}