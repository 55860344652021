import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { ApiService as Api } from '../../services/api';
import { Utils } from '../../services/utils';
import { selectors as supplierSelectors } from './suppliers';
import { selectors as categorySelectors } from './categories';
import { selectors as pubSelectors } from './pub';

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    loading: 'idle',
    items: []
}

export const fetch = createAsyncThunk('products/fetch', async () => await Api.request('products'));

const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
                App.initUpdate('Product details', 0);
            }
        });

        builder.addCase(fetch.fulfilled, (state, { meta, payload }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            payload.sort((a, b) => (a.name > b.name) ? 1 : -1);
            state.items = payload.map((item) => {
                item.locked = false;
                if(item.supplier != null && item.supplier != 4) {
                    item.locked = true;
                }

                return item;
            });

            state.loading = 'idle';
            state.currentRequestId = undefined;

            App.initUpdate('Product details', 1);
            App.cache.set("products", state.items);
        });

        builder.addCase(fetch.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.loading = 'idle'
            state.currentRequestId = undefined

            console.error("Unable to load products");
            App.initUpdate('Product details', 2);
            
            // Load from cache as offline
            App.cache.get("products").then((products) => {
                state.items = products;
            });
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;

const getById = createMapSelector([
    state => state.products.items,
    (state, id) => parseInt(id),
], (products, id) => {
    return products.find(product => product.id === id);
});

const getAvailable = createMapSelector([
    state => state.products.items,
    state => state.pub.details,
    state => state.order.type,
    state => state.categories.items,
], (products, pubDetails, type, categories) => {
    let items = products;
    
    const pubIsReca = pubSelectors.details({ pub: { details: pubDetails } }, "reca");

    if (type === 'counter') {
        // Filter only HIL (non-blade) draught products 
        items = items.filter(item => {
            const counter = selectors.isCounter({products: {items: products}, categories: {items: categories}}, item.id);
            return counter;
        });
    }

    // Remove non RECA products
    return items.filter(product => product.status != 0 && (!pubIsReca || product.reca_visible));
});

const getBySupplier = createMapSelector([
    getAvailable,
    (state, supplierId) => supplierSelectors.getById(state, parseInt(supplierId)),
], (products, supplier) => {
    if (!supplier) return products;

    return products.filter(product =>
        product.supplier === supplier.id || (!supplier.is_heineken && product.supplier === 4)
    );
});

const getByCategory = createMapSelector([
    (state, categoryId, supplierId) => supplierId ? getBySupplier(state, supplierId) : getAvailable(state),
    state => state.categories.items,
    (state, categoryId) => parseInt(categoryId),
], (products, categoriesState, categoryId) => {
    if (!categoryId) return products;

    let categories = [categoryId];
    // Build category list
    const category = categorySelectors.getById({ categories: { items: categoriesState }}, categoryId);
    if (!category) return [];

    if (category.children) {
        categories = categories.concat(
            category.children.map(child => child.id)
        );
    } else if (category.parent) {
        categories.push(parent);
    }

    return products.filter(product => {
        return categories.includes(product.category_id);
    });
});

export const selectors = {
    getById: getById,
    getByCategory: getByCategory,
    getBySupplier: getBySupplier,
    getPrice: createMapSelector([
        getById,
        (state, id, format = false) => format,
        (state, id, format = false, quantity = 1) => quantity,
        (state, id, format = false, quantity = 1, vat = false) => vat
    ], (product, format, quantity, vat) => {
        let total = parseFloat(product.price) * quantity;

        if (vat) {
            total = Utils.roundFloat(total * (product.vat / 100));
        }

        if (!format) {
            return parseFloat(total); // Just in case
        }

        return '€' + Utils.roundFloat(total);
    }),

    isReca: createMapSelector([
        getById,
        state => state.suppliers.items
    ], (product, suppliers) => {
        if (!product) throw new Error('Product not found');
        const supplier = supplierSelectors.getById({suppliers: { items: suppliers }}, product.supplier);
        if (!supplier) return false;

        return supplier.is_heineken && product.reca_visible;
    }),

    isCounter: createMapSelector([
        getById,
        state => state.categories.items,
    ], (product, categoriesState) => {
        if (!product) throw new Error('Product not found');

        const category = categorySelectors.getParent({ categories: { items: categoriesState }}, product?.category_id);

        return product.supplier == 1 // Only include Heineken Ireland products
            && category == 2 // Only include draught products
            && !product.sku.includes('BL') // Remove blade products
            && !product.name.startsWith('GAS ') // Remove gas products
    }),

    isVisible: createMapSelector([
        getById,
        state => state.suppliers.items,
        state => state.pub.details
    ], (product, suppliers, pubDetails) => {        
        if (!pubSelectors.details({ pub: { details: pubDetails } }, "reca")) {
            return true;
        }

        if (!product) return false;

        const supplier = supplierSelectors.getById({suppliers: { items: suppliers }}, product.supplier);
        if (!supplier) return false;

        return !supplier.is_heineken || product.reca_visible;
    }),

    getIcon: createMapSelector([
        getById
    ], (product) => product.image)
};
