import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetAll } from 'smartpub-common/redux/actionTypes';

import Button from '../includes/Button';
import { AppService as App }  from 'smartpub-common/services/app.js';
import cartIcon from '../../assets/icons/truck-icon.svg';
import editIcon from '../../assets/icons/down-green.svg';
import homeIcon from '../../assets/icons/home-icon.svg';
import homeIconActive from '../../assets/icons/home-icon-active.svg';
import stockIcon from '../../assets/icons/stock-icon.svg';
import stockIconActive from '../../assets/icons/stock-icon-active.svg';
import orderIcon from '../../assets/icons/order-icon.svg';
import orderIconActive from '../../assets/icons/order-icon-active.svg';
import financialIcon from '../../assets/icons/financial-icon.svg';
import financialIconActive from '../../assets/icons/financial-icon-active.svg';
import remindersIcon from '../../assets/icons/notifications-icon.svg';
import remindersIconActive from '../../assets/icons/notifications-icon-active.svg';
import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger-icon.svg';


export const Header = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [active, setActive] = useState(false);

    let pathname = useMemo(() => 
        location.pathname !== "/" ? (location.pathname[location.pathname.length - 1] == "/" ? location.pathname.substr(1, location.pathname.length - 2) : location.pathname.substr(1, location.pathname.length)) : location.pathname
    , [location.pathname]);

    var pub = null;
    
    if(App.user && App.user.getPub) {
        pub = App.user.getPub();
    }

    // Hide sidebar menu on navigate
    useEffect(() => {
        setActive(false)
    }, [location]);

    const logOut = () => {
        dispatch(resetAll());
        App.cache.clearAll().then((error) => {
            navigate("/");
        });
    }

    const getPubDetails = () => {
        if (App.user && App.user.details && App.user.details.pubs && App.user.details.pubs.length > 1) {
            var pub = App.user.details.pubs.filter((item) => item.id === App.user.details.pub);

            if (!pub || !pub.length) {
                return null;
            }

            pub = pub[0].name;

            var state = location.state || {};

            if(!state || (typeof state == "object" && Object.keys(state).length < 1)) {
                var params = new URLSearchParams(location.search);
                
                for(const [key, value] of params) {
                    state[key] = value;
                }
            }

            state.back = true;

            return (
                <a onClick={() => navigate("/pub", { state })} className="pub-switch">
                    <span>{pub.toLowerCase()}</span>
                    <img src={editIcon} />
                </a>
            );
        } else {
            var pub = App.user && App.user.getPub() ? App.user.getPub().name : '';
            return (
                <div className="pub-switch">
                    <span>{pub.toLowerCase()}</span>
                </div>
            );
        }
    }

    const headerNav = () => {
        const items = [
            {
                name: "Home",
                action: "/home",
                icons: {
                    default: homeIcon,
                    active: homeIconActive
                }
            }, {
                name: "Stock",
                permission: "stock_favourites,history",
                action: "/stock",
                pages: ["stock"],
                icons: {
                    default: stockIcon,
                    active: stockIconActive
                }
            }, {
                name: "Order",
                permission: "order,history",
                action: "/order",
                pages: ["order", "order/new", "order/checkout", "order/summary", "history", "history/order", "history/report", "order/others"],
                icons: {
                    default: orderIcon,
                    active: orderIconActive
                }
            }, {
                name: "Financial",
                permission: "financial,statements",
                action: "/financial",
                pages: ["financial"],
                icons: {
                    default: financialIcon,
                    active: financialIconActive
                }
            }, {
                name: "Reminders",
                action: "/reminders",
                pages: ["reminders"],
                icons: {
                    default: remindersIcon,
                    active: remindersIconActive
                }
            }
        ];

        
        const links = items.map((item) => {
            let active = pathname === item.name.toLowerCase();
            if (!active) {
                active = item.pages?.filter((page)=> {
                    return pathname.includes(page);
                }).length;
            }

            return (
                <li key={item.name}>
                    <Button
                        title={item.name}
                        permission={item.permission}
                        onClick={() => navigate(item.action)}
                        theme="blank"
                        icon={<img src={item.icons[active ? 'active' : 'default']}
                        alt={item.name}/>}
                    />
                </li>
            );
        });

        return (
            <React.Fragment>
                <li id="menu-burger"><Button title="Menu" onClick={() => setActive(!active)} theme="blank" icon={<img src={burgerIcon} alt="Menu icon"/>} /></li>
                {links}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div id="header">
                <div id="navigation">
                    <div className="container">
                        <div id="logo-container">
                            <a title="Home" onClick={() => navigate("/home")} className="logo"></a>
                        </div>
                        
                        <ul className='main-header-nav'>
                            {headerNav()}
                        </ul>

                        <div className="navigation-right">
                            <div className="checkout">
                                {getPubDetails()}
                                <Link to="/order/checkout" className='checkout-van'  onClick={() => { App.analytics.logEvent('tap_truck') }}>
                                    <img src={cartIcon} alt="Cart icon"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ active ? "draw active": "draw" }>
                <div className="logo-container">
                    <a title="Home" onClick={() => navigate("/home")} className="logo"></a>

                    {getPubDetails()}
                </div>

                <ul>
                    <li><Button title="Home" onClick={() => navigate("/home")} theme="blank"/></li>
                    <li><Button permission="stock_favourites" title="Stock" onClick={() => navigate("/stock")} theme="blank"/></li>
                    <li><Button permission="order" title="Order" onClick={() => navigate("/order")} theme="blank"/></li>
                    <li><Button permission="financial,statements" title="Financial" onClick={() => navigate("/financial")} theme="blank"/></li>
                    <li><Button permission="history" title="Order History" onClick={() => navigate("/history")} theme="blank"/></li>
                    <li><Button title="Reminders" onClick={() => navigate("/reminders")} theme="blank"/></li>
                    <li><Button title="Pub info" onClick={() => navigate("/pubinfo")} theme="blank"/></li>
                    <li><Button title="Setup" onClick={() => navigate("/settings")} theme="blank"/></li>
                    <li><Button title="Training & Social Media" onClick={() => navigate("/social")} theme="blank"/></li>
                    <li><Button title="Feedback" onClick={() => navigate("/feedback")} theme="blank"/></li>
                    <li><Button title="Contact Us" onClick={() => navigate("/contact")} theme="blank"/></li>
                    <li style={{marginTop: 20}}><Button title="Log out" onClick={logOut} theme="blank"/></li>
                </ul>

                

                <a className="draw-close" onClick={() => setActive(false)}><img src={closeIcon} alt="close" /></a>

            </div>
        </React.Fragment>
    );
};