import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CalendarIcon from '../../assets/icons/calendar-icon-active.svg';

export class DateInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            label: this.props.label ? this.props.label: "",
            visible: false,
            onChange: this.props.onChange,
            value: this.fixDate(this.props.value),
            min: this.fixDate(this.props.min ? this.props.min : null),
            max: this.fixDate(this.props.max ? this.props.max : null)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label ? nextProps.label: "",
            onChange: nextProps.onChange,
            value: this.fixDate(nextProps.value),
            min: this.fixDate(nextProps.min),
            max: this.fixDate(nextProps.max)
        })
    }

    fixDate(date) {
        if (date && typeof date != "object") {
            date = new Date(date);
        }

        return date;
    }

    render() {
        let calendarIcon = CalendarIcon;

        return (
            <div id={this.props.id ?? "calendar-to"} className="calendar-container">
                <label
                    id={(this.props.id ?? "calendar-to") +"-label"}
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { 
                        this.state.value ?
                            null
                        :
                            this.state.label
                    }
                </label>
                <DatePicker
                    selected={this.state.value}
                    onChange={date => {
                        this.setState({ visible: false, value: date });
                        this.state.onChange(date);
                    }}
                    minDate={this.state.min}
                    maxDate={this.state.max}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        )
    }
}