import React from 'react';
import {
	ActivityIndicator
} from 'react-native';
import { AppService as App } from 'smartpub-common/services/app.js';

export default class Button extends React.Component {
	constructor(props){
		super(props);

		this.setup(props);
	}

	setup(props) {
		this.disabled = false;

		var classes = ["btn"];

		classes.push("btn-" + (props.size ? props.size : 'primary'));

		if (props.disabled) {
			classes.push("disabled");
			this.disabled = true;
		}

		classes.push(props.theme ? props.theme : "green");

		if (props.borderColor)  {
			classes.push("border-"+ props.borderColor);
		}

		if (props.textColor)  {
			classes.push("text-"+ props.textColor);
		}

		if (props.className) {
			classes.push(props.className);
		}

		let allowed = false;
		if (this.props.permission) {
			let permissions = this.props.permission.split(",");

			for (let i = 0; i < permissions.length; i++) {
				if (App.user && App.user.hasPermission(permissions[i].trim())) {
					allowed = true;
				}
			}
		} else {
			allowed = true;
		}

		if (!allowed) {
			classes.push("btn-disabled");
			this.disabled = true;
		}

		this.className = classes.join(" ");

		this.activityColor = "#ffffff";

		if (props.theme === "light" || props.theme === "primary"){
			this.activityColor = "#1fa6a6";
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setup(nextProps);
	}

	render(){
		return (
			<button
				style={this.props.hasMargin ? ({marginBottom: 10}) : this.props.style || {}}
				id={this.props.id}
				type={this.props.type || "button"}
				className={this.className}
				onClick={!this.disabled ? (this.props.onClick || null) : null}
			>
				{
					this.props.icon ? this.props.icon : null
				}
				{
					!this.props.loading ? 
						this.props.title || this.props.value || this.props.text || this.props.children || "Button"
					:
						<ActivityIndicator size={this.props.activitySize || "small"} color={this.activityColor} />
				}
			</button>
		)
	}
}