import { Cache } from 'react-native-cache';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DeviceInfo from 'react-native-device-info';
import NetInfo from "@react-native-community/netinfo";

import packageJson from '../package.json';

import { ApiService as Api } from './api';
import { UserService as User } from './user';
import { PubService as Pub } from './pub';
// import { SuppliersService as Suppliers } from './_suppliers';
// import { ProductsService as Products } from './_products';
import { SmartlinksService as Smartlinks } from './smartlinks';
import { AnalyticsService as Analytics } from './analytics';
import { NotificationsService as Notifications } from './notifications';
import { Platform } from 'react-native';

import { LogBox } from 'react-native';
import { fetch as fetchProducts } from '../redux/slices/products';
import { fetch as fetchCategories } from '../redux/slices/categories';
import { fetch as fetchSuppliers } from '../redux/slices/suppliers';
import { fetch as fetchPub } from '../redux/slices/pub';
import { fetch as fetchReminders } from '../redux/slices/reminders';

Date.prototype.getWeek = (date = null, wkc = false) => {
    var curDate = new Date();
    var d = date == null ? new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay()) : new Date(date);
    
    if(date != null && !wkc){
        var weekDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (d.getDay() == 0 ? -6 : 1)-d.getDay());
        
        return weekDate.getDate() +" "+ months[weekDate.getMonth()];
    }
    
    if(date == null || wkc){
        var secs = ((60*60)*24)*1000;
        var firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        var pastDaysOfYear = (d - firstDayOfYear) / secs;
        
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }
}

export class AppService {
    static init(navigation, dispatch) {
        AppService.loaded = false;
        AppService.pushData = null;
        AppService.platform = DeviceInfo.getDeviceType() === 'unknown' ? 'web' : 'app';
        AppService.bundleID = DeviceInfo.getBundleId();
        AppService.user = new User();
        AppService.cache = new Cache({
            namespace: "smartpub",
            policy: {
                maxEntries: 50000
            },
            backend: AsyncStorage
        });
        
        if(Platform.OS == 'ios') {
            //Prod app ID by default
            AppService.appID = '1498045689';
            
            //Change appID to lite if bundleID ends in .lite or .dev
            if(AppService.bundleID.match(/lite|dev/i)) AppService.appID = '1518547248';
        }
        
        if (AppService.platform === 'web') {
            AppService.version = packageJson.version;
        } else {
            AppService.version = DeviceInfo.getVersion();
        }
        
        // Store navigation object
        AppService.navigation = navigation;
        
        // Set track for mobile - will be overwritten for web
        AppService.track = packageJson.track ?? 'local';
        
        var tracks = {
            "local": "http://localhost",
            "dev-lite": "https://dev-lite.smartpub.ie/admin",
            "dev-www": "https://dev-www.smartpub.ie/admin",
            "acc-lite": "https://acc-lite.eazle.ie/admin",
            "lite": "https://lite.eazle.ie/admin",
            "sequel-lite": "https://lite.smartpub.app-ic.co.uk/admin",
            "acc-www": "https://acc-www.eazle.ie/admin",
            "prod": "https://www.eazle.ie/admin",
            "sequel-dev": "https://eazle.development.sequelgroup.co.uk/admin",
            "local-test": "http://localhost:7780",
            "github-test": "https://testing.eazle.development.sequelgroup.co.uk"
        };
        
        if (AppService.platform === 'web') {
            var endpoint = window.location.host;

            if(endpoint.includes(".")) {
                if (endpoint.split(".")[0] == 'www') {
                    endpoint = endpoint.split(".")[1];
                } else {
                    endpoint = endpoint.split(".")[0];
                }
            }
            
            for(var track in tracks) {
                if(endpoint == track) {
                    AppService.track = track;
                    break;
                }
                
                if (endpoint.includes('local') || window.location.host.includes('development') || endpoint.includes('127')) {
                    AppService.track = packageJson.track ?? 'local';
                    break;
                }
                
                if(endpoint == "eazle") {
                    AppService.track = "prod";
                    break;
                }
            }
        }
        
        AppService.baseURI = tracks[AppService.track];
        
        if(Platform.OS.toLowerCase() == 'android') {
            AppService.baseURI = AppService.baseURI.replace('localhost', '10.0.2.2');
        }
        
        // Stop app if invalid track
        if (!AppService.baseURI) {
            alert("Invalid track");
            return new Promise(() => {});
        }
        
        switch (AppService.track) {
            case 'lite':
            case 'sequel-lite':
            case 'acc-lite':
            case 'eazle-acc-lite':
            case 'eazle-lite':
            case 'github-test':
            AppService.firebaseConfig = {
                apiKey: "AIzaSyAV0NjyMZ58mp4sDXWpeYSheG_JjeMr_Zo",
                projectId: "heineken-smartpub-lite",
                messagingSenderId: "649183088614",
                appId: "1:649183088614:web:4d900a15678ef481c15027",
                measurementId: "G-FJH45C16DZ"
            };
            Analytics.setupGTM('GTM-T6DCTT3'); // Web only
            break;
            case 'prod':
            case 'acc-www':
            case 'eazle-acc-www':
            case 'eazle':
            AppService.firebaseConfig = {
                apiKey: "AIzaSyCwmFoZjpkCGi_w1kuchkmp4_mVPARneNk",
                projectId: "heineken-smartpub-black",
                messagingSenderId: "578331587070",
                appId: "1:578331587070:web:8a04b8b6c5e78821bfd0af",
                measurementId: "G-HH54F889Q3"
            };
            Analytics.setupGTM('GTM-T6DCTT3'); // Web only
            break;
            default: // Default to dev firebase
            AppService.firebaseConfig = {
                apiKey: "AIzaSyDoUZ7hGhcdEVGpRXwEvxGOkDpq70_D80o",
                projectId: "heineken-smartpub-dev",
                messagingSenderId: "232138509980",
                appId: "1:232138509980:web:c693b1dc3dc4bddca22759",
                measurementId: "G-2L71QN5VM0"
            };
            Analytics.setupGTM('GTM-NXX4GZT'); // Web only
        }
        
        if (AppService.platform === 'app' && AppService.track === 'local-test') {
            LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
            LogBox.ignoreAllLogs();//Ignore all log notifications
        }
        
        // Check track firebase config against native firebase module config
        if (!AppService.track.includes("local") && !Notifications.checkConfig(AppService.firebaseConfig.projectId)) {
            alert("Invalid firebase config");
            return new Promise(() => {});
        }
        AppService.analytics = Analytics;
        
        if (typeof this.callbacks === 'undefined') {
            this.callbacks = [];
        }
        AppService.initUpdates = {};
        
        AppService.api = Api;
        AppService.headerData = null;
        AppService.headerTruck = null;
        AppService.netinfo = false;
        
        AppService.date = (date = null) => {
            if(date == null){
                var curDate = new Date();
                return new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay());
            } else {
                return new Date(date);
            }
        }
        
        //AppService.products = new Products();
        AppService.pub = new Pub();
        //AppService.suppliers = new Suppliers();
        AppService.smartlinks = new Smartlinks();
        
        // Check app status
        return AppService.checkStatus().then(() => {
            // Delay app load until user is initialised
            return AppService.user.setup().then(() => {
                if(AppService.user.isInactive()) {
                    throw "inactive";
                }
                
                return AppService.smartlinks.setup()
                    .then(() => dispatch(fetchProducts()).unwrap())
                    .then(() => dispatch(fetchCategories()).unwrap())
                    .then(() => dispatch(fetchSuppliers()).unwrap())
                    .then(() => dispatch(fetchReminders()).unwrap());
            }).then(()=> {
                let stage2 = [];
                if (AppService.user.pubIsSet()) {
                    stage2.push(dispatch(fetchPub()).unwrap());
                    stage2.push(AppService.pub.users.setup());
                }
                
                return Promise.all(stage2);
            }).then(()=> {
                AppService.loaded = true;
                if (AppService.pushData) {
                    var pushData = AppService.pushData;
                    AppService.pushData = null;
                    AppService.getNavigation().push(pushData.page, pushData.pageMeta);
                }
            }).catch(error => {
                console.error("232", error);
                AppService.loaded = true;
                throw error;
            });
        }, (status) => {
            if (AppService.platform == 'web') {
                AppService.loaded = true;
                throw {status: status};
            } else {
                navigation.replace("Update", status);
                throw 'Show update';
            }
        }).catch(error => {
            console.error(244, error);
            throw error;
        });
    };
    
    static checkStatus() {
        return new Promise((resolve, reject) => {
            if (AppService.appStatus) {
                reject(AppService.appStatus);
            }
            
            AppService.api.request("status").then((data) => {
                if (data.status) {
                    AppService.appStatus = data.status;
                    reject(AppService.appStatus);
                    return;
                }
                
                // Only check app version on app
                if (AppService.platform !== 'web' && versionCompare(DeviceInfo.getVersion(), data.version) < 0) {
                    AppService.appStatus = data.version;
                    reject(AppService.appStatus);
                    return;
                }
                
                resolve();
            }, () => {
                console.error('Error loading version');
                resolve();
            });
        });
    }
    
    static initUpdate(event, status) {
        if (!event) {
            this.initUpdates = [];
        } else {
            this.initUpdates[event] = status;
        }
        
        if (this.callbacks['initUpdate']) {
            this.callbacks['initUpdate'](this.initUpdates);
        }
    }
    
    static on(event, callback) {
        if (typeof this.callbacks === 'undefined') {
            this.callbacks = [];
        }
        
        this.callbacks[event] = callback;
        
        return this;
    }
    
    static getNavigation() {
        return AppService.navigation;
    }
}

function versionCompare(a, b) {
    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);
    
    for (i = 0; i < l; i++) {
        diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
        if (diff) {
            return diff;
        }
    }
    return segmentsA.length - segmentsB.length;
}