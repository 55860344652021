import React from 'react';

import Button from '../includes/Button';
import LoginLayout from '../includes/LoginLayout';

import { AppService as App } from 'smartpub-common/services/app.js';
import nextIcon from "#assets/icons/next-back-icon-green.svg";
import EazleLogo from '#assets/icons/Eazle_Logo.png';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginForgot() {
    const navigate = useNavigate();
    const location = useLocation();

    return <LoginForgotClass location={location} navigate={navigate} />
}

class LoginForgotClass extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            enteredEmail: null,
            loading: false
        }

        //S14B4 i781 note: Check for message passed via the URL from the API
        var message = this.props.location.state?.params;

        if(message && message != "") {
            var params = new URLSearchParams(message);
            var message = params.has("message") ? params.get("message") : "";

            alert('Notification: ' + decodeURIComponent(message));
        }

        this.request = this.request.bind(this); //bind this in order to access the state inside the function
    }

    request() {
        this.setState({ loading: true });

        App.user.request(this.state.enteredEmail).then(() => {
            this.setState({ loading: false });

            // Redirect user to homepage
            alert("Request submitted. You will receive an email in due course");
            this.props.navigate("/");
        }, (error) => {
            console.log(error);
            if (error == 'Server error: 422') {
                alert("This email address is not registered in SmartPub\n\nPlease check the spelling of your email and try again");
            } else {
                alert("The email format is not correct\n\nPlease check the spelling of your email and try again");
            }
            this.setState({ loading: false });
        });
    }

    render() {
        var content = (
            <div className="form">
                <img src={EazleLogo} alt="eazle logo" id="logo" />
                <p className="text-center">
                    Submit your details below
                    <br />
                    You will be sent a password re-set link via email
                </p>
                <form method="POST" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row">
                        <input
                            placeholder="Email"
                            type="text"
                            value={this.state.enteredEmail}
                            onChange={(e) => this.setState({enteredEmail: e.target.value})}
                        />
                    </div>
                    <div className="form-group row">
                        <Button
                            text="Submit"
                            theme="primary"
                            onClick={this.request}
                            loading={this.state.loading}
                        />
                    </div>
                </form>
                <a href="#" className="text-center" onClick={() => this.props.navigate("/")} id="forgot"><img src={nextIcon} className='rotate-180' /> Back to login</a>
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}