import React from 'react';

import Button from '../includes/Button';

import { AppService as App } from 'smartpub-common/services/app.js';
import { useNavigate, useLocation } from 'react-router-dom';

class TermsClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            terms: props.location.state && props.location.state.terms ? props.location.state.terms : this.getTerms()
        }

        this.acceptTerms = this.acceptTerms.bind(this);
        this.getTerms = this.getTerms.bind(this);
    }

    acceptTerms() {
        App.user.acceptTerms().then(() => {
            this.props.navigate("/home");
        });        
    }

    getTerms() {
        App.api.request("terms", {terms: true}).then(data => {
            this.setState({
                terms: data.terms
            });
        }, error => console.log('err', error));
    }

    render() {
        return (
            <div id="terms">
                <div className="container row">
                    <div className="col-md-12">
                        <div id="terms-content" dangerouslySetInnerHTML={{__html: this.state.terms}}>
                        </div>

                        <Button
                            title="Privacy"
                            hasMargin={true}
                            theme="secondary"
                            onClick={() => {
                                window.open('https://www.heinekenireland.ie/Privacy-Policy/#', '_blank');
                            }}
                        />

                        {
                            this.props.location.state && this.props.location.state.pubinfo ?
                                <Button
                                    theme="primary"
                                    title="Go back"
                                    onClick={() => this.props.navigate("/pubinfo")}
                                    hasMargin={true}
                                />
                            :
                                <Button
                                    theme="primary"
                                    title="Accept terms and conditions"
                                    onClick={this.acceptTerms}
                                    hasMargin={true}
                                />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default function Terms() {
    const location = useLocation();
    const navigate = useNavigate();
    return <TermsClass navigate={navigate} location={location} />
}