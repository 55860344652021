import React, { useState, useEffect } from 'react';
import Button from '../includes/Button';

import { AppService as App } from 'smartpub-common/services/app.js';
import UserList from '../includes/UserList.js';
import { useNavigate } from "react-router-dom";

export default function Users() {
    const [users, setUsers] = useState({active: [], inactive: []});
    const [loading, setLoading] = useState([]);

    let navigate = useNavigate();

    // Load user list on page load
    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        setUsers({active: App.pub.users.get(1, "is_active") || [], inactive: App.pub.users.get(0, "is_active") || []});
    };

    const update = (userID, permissions = null, active = null) => {
        // Check if we are in the middle of an operation on this item
        if (loading.indexOf(userID) > -1) {
            return;
        }

        // Add item to the list of loading
        setLoading([...loading, userID]);

        return App.pub.users.update(userID, permissions, active).then(() => {
            loadUsers();
        }, (error) => {
            alert("Error updating user", error);
            throw error;
        }).finally(() => {
            // Remove item from loading
            setLoading(loading.filter(item => item !== userID));
        });
    };

    return (
        <div id="users">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <UserList
                            users={users}
                            loading={loading}
                            onUpdate={update}
                        />

                        <div className="page-button-container">
                            <Button
                                theme="primary"
                                title="Add pub user"
                                containerStyle={{marginLeft: 10, marginRight: 10}}
                                onClick={() => navigate('/users/new', {
                                    state: {
                                        action: "new"
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}