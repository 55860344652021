import React from 'react';

import Button from '../includes/Button';
import LoginLayout from '../includes/LoginLayout';

import { AppService as App } from 'smartpub-common/services/app.js';

import visibility from "#assets/icons/visibility.svg";
import nextIcon from "#assets/icons/next-back-icon-green.svg";
import EazleLogo from '#assets/icons/Eazle_Logo.png';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();

    return <LoginClass location={location} navigate={navigate} />
}

class LoginClass extends React.Component {
    constructor(props) {

        super(props)

        this.state = {
            enteredUser: null,
            enteredPass: null,
            loading: false,
            showPassword: false
        }

        if(this.props.location.state.message && this.props.location.state.message) {
            alert('Notification: ' + this.props.location.state.message);
        }

        this.login = this.login.bind(this); //bind this in order to access the state inside the function
    }

    login() {
        this.setState({ loading: true });

        var login = App.user.login(this.state.enteredUser, this.state.enteredPass);

        Promise.resolve(login).then(() => {
            this.setState({ loading: false });

            // Redirect user to homepage
            this.props.navigate("/", {
                state: {
                    redirect: "/home"
                }
            });
        }, (error) => {
            console.log('error', error);
            alert("Invalid username or password\n\nPlease check and try again");
            this.setState({ loading: false });
        });
    }

    render(){
        var content = (
            <div className="form">
                <img src={EazleLogo} alt="eazle logo" id="logo" />
                <form method="POST" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row">
                        <input
                            placeholder="Email"
                            type="text"
                            value={this.state.enteredUser}
                            onChange={(e) => this.setState({enteredUser: e.target.value})}
                        />
                    </div>
                    <div className="form-group row">
                        <input
                            placeholder="Password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.enteredPass}
                            onChange={(e) => this.setState({enteredPass: e.target.value})}
                        />
                        <img src={visibility}
                            className="pw-visibility"
                            onClick={() => {this.setState({showPassword: !this.state.showPassword})}} />
                    </div>
                    <div className="form-group row">
                        <Button
                            type="submit"
                            text="Login"
                            theme="primary"
                            onClick={this.login}
                            loading={this.state.loading}
                        />
                    </div>
                </form>
                <a href="#!" className="text-center" onClick={() => this.props.navigate("/forgot")} id="forgot">Forgot password <img src={nextIcon} /></a>
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}