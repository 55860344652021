import { initialState as orderInitialState } from './slices/order';
import { initialState as categoriesInitialState } from './slices/categories';
import { initialState as productsInitialState } from './slices/products';
import { initialState as pubInitialState } from './slices/pub';
import { initialState as suppliersInitialState } from './slices/suppliers';
import { initialState as preferencesInitialState } from './slices/preference';


const mainInitialState = {
    order: orderInitialState,
    categories: categoriesInitialState,
    products: productsInitialState,
    pub: pubInitialState,
    suppliers: suppliersInitialState,
    preferences: preferencesInitialState
};

export default mainInitialState;