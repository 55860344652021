import React from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import { Utils } from 'smartpub-common/services/utils.js';
import { IconButton } from "../includes/IconButton";
import SearchBar from '../includes/financial/SearchBar';

import financialIcon from '../../assets/icons/financial-icon-teal.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';

const FinancialInfo = () => {
    if (!App.user || !App.user.hasPermission('financial')) {
        return null;
    }

    const metaData = useSelector(pubSelectors.metaData);

    var info = [];

    var total = metaData.total_balance;
    if (total) {
        info.push(
            <React.Fragment>
                <h4>Total account balance:</h4>
                <h5>€ { Utils.formatCurrency(total) }</h5>
            </React.Fragment>);
    }

    total = metaData.next_pay_amount;
    if (total) {
        var date = metaData.next_pay_dt;
        if(!info.length) {
            info.push(
                <React.Fragment>
                    <h4>Next payment</h4>
                    <h5>Due { Utils.dateUntil(date, false) }<br />€ { Utils.formatCurrency(total) }</h5>
                </React.Fragment>
            );
        } else {
            info.push(
                <React.Fragment>
                    <h4>Next payment</h4>
                    <h5>Due { Utils.dateUntil(date, false) }<br />€ { Utils.formatCurrency(total) }</h5>
                </React.Fragment>
            );
        }
    }

    total = metaData.last_pay_amount;
    if (total) {
        var date = metaData.last_pay_dt;
        info.push(
            <React.Fragment>
                <h4>Last payment</h4>
                <h5>{ Utils.dateUntil(date, false) } <br />€ { Utils.formatCurrency(total) }</h5>
            </React.Fragment>
        );
    }

    if (!info.length) {
        return null; // No financial info available
    }

    return (
        <div className="block block--financial-info">
            <div className='history-order-summary'>
                <h4>HEINEKEN payment info</h4>
                <p>Summary statements available on the 5th working day of the new month.</p>
                <p>Invoices/Credit/Debit notes are available 2 working days after transaction.</p>
                <div id="financial-info-excerpt">HEINEKEN payment info is correct up to previous working day</div>
            </div>
            <div className="history-order-detail">
                { info.map(item => {
                    return (
                        <div className='order-details'>
                            {item}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

const StatementButtons = () => {
    const navigate = useNavigate();

    if (!App.user.hasPermission('statements')) {
        return null;
    }

    let sections = [
        {
            items: [
                { title: "Invoices", screen: "Statements", icon: financialIcon, type: 'invoice' },
                { title: "Debit/Credit notes", screen: "Statements", icon: financialIcon, type: 'memo' },
                { title: "Summary statements", screen: "Statements", icon: financialIcon, type: 'statement' },
                { title: "Pre Feb 2022", subtitle: "Invoice/Statements", screen: "Statements", icon: financialIcon, type: 'sdoc' }
            ]
        }
    ]

    return sections.map((section) => {
        let links = section.items.map((item) => (
            <div className="col-md-12">
                <IconButton size="big" fileLocal={item.icon} arrow={true} file={item.icon} title={item.title} subTitle={item.subtitle} onClick={() => {
                    var type = encodeURIComponent(item.type);

                    var pathname = `/financial/statements/?type=${type}`;
                    navigate(pathname, {
                        state: { title: item.title, type: item.type }
                    })
                }} />
            </div>
        ));

        return (
            <div className="section-block section-block--financial">
                <div className="row">
                    { links }
                </div>
            </div>
        )
    });
};


export default function Financial() {
    return (
        <div id="financial-info">
            <div className="container row">
                <SearchBar/>
            </div>
            <div className='container fin'>
                <div id="financial-buttons" className="col-md-6">
                    <StatementButtons />
                </div>
                <div className="col-md-6" id="payment-info">
                    <FinancialInfo />
                </div>
            </div>
        </div>
    );
}