import React, { useState, useEffect } from 'react';
import { CategoryList } from '../includes/categories/CategoryList';
import { ProductList } from '../includes/categories/ProductList';
import { useSelector } from 'react-redux';
import { selectors as categorySelectors } from 'smartpub-common/redux/slices/categories';
import { useParams } from 'react-router-dom';

export default function ProductCategories({ type, showProducts, setPageTitle }) {
    let { category: categoryId } = useParams();
    const category = useSelector(state => categorySelectors.getById(state, categoryId));

    useEffect(() => {
        if (!category)
            return setPageTitle("All products");

        setPageTitle(category.name);
    }, [category]);

    const [searchText, setSearchText] = useState("");

    const header = (
        <div className='category-header'>
            <h3 id="page-title">&nbsp;</h3>
            <div id="search-container">
                <input
                    type="search"
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={"Search " + (category ? category.name.toLowerCase() : "products")}
                    value={searchText}
                    id="search"
                    autoComplete="off"
                />
            </div>
        </div>
    );

    return (
        <div className="product-category product-single-category">
            <div className="container row">
                <div className="col-md-12">
                    { header }
                    {
                        showProducts || searchText ?
                            <ProductList
                                searchText={searchText}
                                type={type}
                            />
                        :
                            <CategoryList
                                type={type}
                            />
                    }
                </div>
            </div>
        </div>
    );
}