import React from 'react';
import { IconButton } from '../../includes/IconButton';
import { AppService as App } from 'smartpub-common/services/app.js';
import { ReminderList } from "../../includes/ReminderList";

import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectors as orderSelectors } from 'smartpub-common/redux/slices/order';

import orderIcon from '../../../assets/icons/order-icon-active.svg';
import topUpIcon from '../../../assets/icons/top-up-icon-active.svg';
import calendarIcon from '../../../assets/icons/stock-icon-acitve.svg';
import depotIcon from '../../../assets/icons/depot-icon.svg';

export default function Menu() {
    let navigation = useNavigate();
    const inProgress = useSelector(orderSelectors.inProgress);
    const isTopUp = useSelector(orderSelectors.isTopUp);
    const isCounter = useSelector(orderSelectors.isCounter);
    const order = useSelector((state) => state.order);
    const isEdited = useSelector(orderSelectors.isEdited);
    const canClickCollect = useSelector(state => state.pub.details).click_collect;

    let title = "Order",
        pId = title.replace(/ /g, "-").toLowerCase(),
        items = [
            { title: "New order", permission: "order", type: "normal", path: "/order/new", icon: orderIcon },
            { title: "Top up order", permission: "order", type: "topup", path: "/order/new", icon: topUpIcon },
            { title: "Order history", permission: "history", path: "/history", icon: calendarIcon }
        ];

    // Insert click and collect if pub has it enabled
    if (canClickCollect) {
        items.splice(1, 0,
            { title: "Depot collection order", permission: "order", type: "counter", path: "/order/new", icon: depotIcon }
        );
    }
        
    if (order.items.length && isEdited) {
        if (inProgress && !isTopUp &&!isCounter) {
            items.unshift({ title: "Continue order", path: "/order/new", icon: orderIcon });
        } else if (inProgress && isTopUp) {
            items.unshift({ title: "Continue top up order", path: "/order/new", icon: orderIcon });
        } else if (inProgress && isCounter) {
            items.splice(1,1);
            items.splice(1, 0,{ title: "Depot collection order", path: "/order/new", icon: depotIcon });
        }
    }
    
    let links = items.map((item) => (
        <div className="col-md-6">
            <IconButton size="big" permission={item.permission} fileLocal={item.icon} file={item.icon} title={item.title} onClick={() => {
                navigation(item.path, {
                    state: {
                        type: item.type
                    }
                });
                App.analytics.logEvent('order_new', {'order_type': item.title.replace(/ /g,"_").toLowerCase()});
            }} />
        </div>
    ));

    return (
        <div id={pId} className="row">
            <div className="container container--small">
                <ReminderList summary={true} />
                <div className="row">
                    {links}
                </div>
            </div>
        </div>
    );
}