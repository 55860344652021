import React, { useMemo, useState } from 'react';
import {
    FlatList
} from 'react-native';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import useFuse from 'smartpub-common/helpers/useFuse';

import { useSelector } from 'react-redux';
import { OrderProductItem } from './OrderProductItem';
import { FavouritesProductItem } from './FavouritesProductItem';

import _ from "lodash";
import { useParams, useSearchParams } from 'react-router-dom';
import { PopUpQuantity } from '../PopUpQuantity';

export const ProductList = ({ searchText = null, type }) => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const [popupQuantityConfig, setPopupQuantityConfig] = useState();

    const favourites = useSelector(pubSelectors.favourites);
    const pinned = useSelector(pubSelectors.pinned);

    const products = useSelector(state => productSelectors.getByCategory(state, category, searchParams.get('supplier')));
    const orderItems = useSelector(state => state.order.items);

    const items = useMemo(() => {
        let items;

        if (type === "order") {
            // Filter out products already in order
            items = _.differenceWith(products, orderItems, (product, orderItem) => product.id === orderItem.product_id);

            if (pinned) {
                // Sorting items based on the order of product_ids
                items = _.sortBy(items, item => {
                    const index = pinned.indexOf(item.id);
                    // If product.id is not in pinned, place it at the end
                    return index !== -1 ? index : pinned.length;
                });
            }
        }

        if (type === "favourites") {
            // Filter out products already in favourites
            items = _.differenceWith(products, favourites, (product, orderItem) => product.id === orderItem.product_id);

            if (searchParams.get('supplier')) {
                // Filter out items not available for this supplier
                items = items.filter((item) => {
                    if (item.supplier == searchParams.get('supplier')) return true;

                    if (searchParams.get('supplier') !== 1 && searchParams.get('supplier') !== 3) {
                        return item.supplier == 4;
                    }
                });
            }
        }

        return items;
    }, [products, orderItems, favourites, type, pinned]);

    let searchResults = useFuse(items, searchText);

    const listEmptyComponent = () => {
        return (
            <h4 style={{ textAlign: 'center', color: "#ffffff" }}>No results</h4>
        )
    }
        

    return <>
        <div id="product-list-table">
            <FlatList
                keyExtractor={(item) => item.id}
                data={searchText?.length >= 3 ? searchResults : items}
                ListEmptyComponent={listEmptyComponent}
                renderItem={({ item }) => {
                    if (type === "favourites") {
                        return <FavouritesProductItem item={item} setPopupQuantityConfig={setPopupQuantityConfig} />
                    }

                    return <OrderProductItem item={item} setPopupQuantityConfig={setPopupQuantityConfig} />
                }}
            />
        </div>

        <PopUpQuantity
            title={type == 'favourites' ? 'Set par' : 'Set quantity'}
            isVisible={popupQuantityConfig}
            quantity={popupQuantityConfig?.quantity}
            onSave={popupQuantityConfig?.onSave}
            onDismiss={() => setPopupQuantityConfig(false)}
            product={{ product_id: popupQuantityConfig?.product }}
        />
    </>;
};