import {AppService as App} from './app.js';
import axios from 'axios'
import {Alert} from 'react-native';

export class ApiService { 
    static setKey(key) {
        this.key = key;
    }

    static setPub(pub) {
        this.pub = pub;
    }

    static request(route, formData = null, method = "GET") {
        var baseURL = App.baseURI + "/api";

        var url = `${baseURL}/${route}`;

        let data = method == 'POST' || !formData ? {} : formData;
        data.version = App.version;
        if (App.user.pubIsSet()) {
            data.pub_id = App.user.pubIsSet();
        }

        url += "?" + Object.entries(data).map(([key, val]) => `${key}=${(typeof val == 'object' ? JSON.stringify(val) : val)}`).join('&');

        var headers = {
            "Accept": "application/json"
        };

        if (this.key){
            headers["Authorization"] = "Bearer "+ this.key;
            //headers["Content-Type"] = "application/x-www-form-urlencoded";
        }

        // Timeout fix for Android
        var axiosCall = axios.CancelToken.source();
        setTimeout(() => { axiosCall.cancel(`Timeout of 40000ms exceeded`) }, 40000);

        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                data: method == 'POST' && formData ? formData : null,
                headers: headers,
                cancelToken: axiosCall.token
            }).then((response) => {
                resolve(response.data.data !== undefined ? response.data.data : response.data);
            }, (error) => {
                if (route.includes('/payment/response')) {
                    resolve(false);
                    return;
                }
                if (!error.response) {
                    reject("Network error: " + error.message);
                    return;
                }
                if (error.response.status === 401) {
                    var message = "Please log in again";
                    if (App.platform === 'web') {
                        alert(message);
                        App.user.logout();
                    } else {
                        // User shouldn't be logged in
                        Alert.alert("Session expired", message, [
                            {
                                text: 'OK',
                                onPress: () => {
                                    App.user.logout();
                                }
                            }
                        ]);
                    }
                    return;
                } else if (error.response.data && error.response.data.message) {
                    if(error.response.data.message.toLowerCase().includes("access denied")){
                        console.error('denied');
                        var preUser = App.user.isInactive();
                        //App.user.setInactive();

                        //if(!preUser) Restart.Restart();

                        resolve("error");
                        return;
                    }

                    console.log('apiError1', error, url);

                    if(error.response.data.message.toLowerCase().includes("permission denied")) {
                        reject("permission error");
                    } else {
                        reject(error.response.data.message);
                    }
                    /*if(!error.response.data.message.toLowerCase().includes("permission denied")) {
                        reject(error.response.data.message);
                    } else {
                        resolve("permission error");
                        return;
                    }*/
                } else if (error.response.status === 500) {
                    console.error('apiError2', error);
                    reject("Server error: unhandled PHP error");
                } else {
                    console.error('apiError3', error);
                    console.error(error.response);
                    reject("Server error: " + error.response.status);
                }
            })
        })
    }
}