import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { updateFavourite } from 'smartpub-common/redux/slices/pub';
import ProductName from '../product/Name';

import { showMessage } from "../../facades/showMessage";

import Value from '../product/Value';
import { useSearchParams } from 'react-router-dom';

export const FavouritesProductItem = ({ item, setPopupQuantityConfig }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const addItem = (quantity) => {
        return dispatch(updateFavourite({ product_id: item.id, par: quantity, supplier_id: searchParams.get('supplier') })).unwrap().then(data => {
            showMessage({
                message: item.name + " added to favourites",
                backgroundColor: "#1b9d54"
            });
        });
    }

    const popupConfig = {
        product: item.id,
        quantity: 0,
        onSave: addItem
    }

    return (
        <div className="product-list-item" key={"product" + item.product_id}>
            <div className="row sort">    
                <div className="col-md-12">
                    <div className="row">
                        <div className="product-list-item-left flex-fill">
                            <div className={"product-list-item-name-container sortable"}>
                                <ProductName item={{ product_id: item.id }} showPrice={false} onPress={()=> {
                                    setPopupQuantityConfig(popupConfig);
                                }} />
                            </div>
                        </div>
                        <div className="product-list-item-right col-md-3">
                            <div className="editable-content">
                                <Value
                                    value={0}
                                    onClick={() => {
                                        setPopupQuantityConfig(popupConfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}