import React from 'react';
import countryCodes from 'smartpub-common/assets/CountryCodes.json';
import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions } from 'smartpub-common/redux/slices/order';

export default function MobileForm({ isPopUp }) {
    const dispatch = useDispatch();
    const mobileNumber = useSelector(state => state.order.mobile_number);
    const mobileCountryCode = useSelector(state => state.order.mobile_country_code);

    return (
    <div className="col-md-12">
        { isPopUp ?
            <div>
                <p>You will receive an email once your order is ready for collection.</p>
                <p>If a mobile number is entered below, an SMS will also be sent.</p>
            </div>
        : 
            <div>
                <label>Mobile Number</label>
                <p>Please enter your mobile number to receive an SMS when your order is ready to be collected</p>
            </div>
        }
        <div className='mobile-form'>
            <select onChange={e => dispatch(orderActions.setMobileCountryCode(e.target.value))}>
                <option value="-1" disabled>Select country...</option>
                {countryCodes.map(({ value, label }, index) => <option value={value} selected={value == mobileCountryCode}>{label}</option>)}
            </select>
            <input
                type="text"
                value={mobileNumber}
                onChange={e => dispatch(orderActions.setMobileNumber(e.target.value.replace(/[^\d.-]+/g, '')))}
            />
        </div>
    </div>
    )
}