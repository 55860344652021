/* eslint-disable */
import React from 'react';
import { createPortal } from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";
import {AppService as App} from 'smartpub-common/services/app.js';

import CloseIcon from '../../assets/icons/close-icon-white.svg';
export class PopUp extends React.Component {
    constructor(props){
        super(props)

        // leave as is
        this.icon = this.props.allowCancel;
        this.dismiss = this.dismiss.bind(this);
    }

    dismiss(cancel = false) {
        this.typed = false;
        
        if(cancel) {
            this.props.onCancel();
        } else {
            this.props.onDismiss();
        }
    }

    render(){
        if (!this.props.isVisible) return null;
        let content;

        // For setting screennames on popups
        if (!this.triggered) {
            let title = this.state.title ? this.state.title : this.props.modalTitle;
    
            App.analytics.setScreen(title + ' Pop up');

            this.triggered = true;
        }

        if (this.renderContent) {
            content = this.renderContent();
        }

        var allowCancel = typeof this.props.allowCancel == 'undefined' ? true : this.props.allowCancel;
        var loading = typeof this.props.loading == 'undefined' ? false : this.props.loading;

        return createPortal(
            <div className={"popup popup--" + (this.props.popupType ?? this.popupType)}>
                <div id="popup-filter" onClick={() => this.props.bgDimiss ? this.dismiss() : null}>
                </div>

                <div id="popup-box">
                    <h5 className="text-center">
                        {this.state.title ? this.state.title : this.props.modalTitle}
                        {
                            !loading && allowCancel  ? 
                                <div id="popup-close" onClick={() => this.dismiss(typeof this.props.onCancel == "function")}>
                                    <img src={CloseIcon} alt="close" />
                                </div>
                            : null
                        }
                    </h5>

                    { this.state.success ? this.onSuccess() : content }
                </div>
            </div>
        , document.getElementById('root'))
    }
}