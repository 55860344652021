import React from 'react';
import { PubList } from '../includes/PubList.js';

import LoginLayout from '../includes/LoginLayout';
import EazleLogo from '../../assets/icons/Eazle_Logo.png';
export default class PubInit extends React.Component {
    render() {
        var content = (
            <div className="form">
                <img src={EazleLogo} alt="Eazle logo" id="logo" />
                <PubList
                    history={this.props.history}
                    location={this.props.location}
                />
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }

}