import React, { useState, useMemo } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
// import { PopUpQuantity } from '../PopUpQuantity';
import { ProductItem } from './ProductItem.js';
import {
    SectionList,
    FlatList
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as pubSelectors, actions as pubActions, reSortFavouriteProducts, updateStock } from 'smartpub-common/redux/slices/pub';
import { selectors as preferenceSelectors } from 'smartpub-common/redux/slices/preference';
import SectionHeader from './SectionHeader.js';
import { showMessage } from '../../facades/showMessage.js';

import _ from 'lodash';
import Button from '../Button.js';

import topIcon from '#assets/icons/top.svg';
import upIcon from '#assets/icons/up.svg';
import downIcon from '#assets/icons/down.svg';
import bottomIcon from '#assets/icons/bottom.svg';
import { useNavigate } from 'react-router-dom';

const OrderedList = ({ showQuantityPopup, ListEmptyComponent, header }) => {
    const dispatch = useDispatch();
    const items = useSelector(pubSelectors.favouritesFiltered);
    const [activeProduct, setActiveProduct] = useState(null);
    const [savingSort, setSavingSort] = useState(false);

    const moveItem = (direction) => {
        let favourites = items,
            activeIndex = items.findIndex(item => item.product_id == activeProduct),
            newIndex;

        switch(direction) {
            case "up": newIndex = activeIndex > 0 ? activeIndex - 1 : 0; break;
            case "down": newIndex = activeIndex < items.length ? activeIndex + 1 : items.length; break;
            case "top": newIndex = 0; break;
            case "bottom": newIndex = favourites.length - 1; break;
        }

        favourites.splice(newIndex, 0, favourites.splice(activeIndex, 1)[0]);

        App.analytics.logEvent('tap_move_selection', {'move_selection': direction});

        // Regenerate item order
        favourites = favourites.map((item, index) => ({ ...item, item_order: index }));

        // Update view
        dispatch(pubActions.moveProduct(favourites));
    }

    const finishSort = () => {
        setSavingSort(true)

        dispatch(reSortFavouriteProducts()).unwrap().then(()=>{
            showMessage({
                message: "New Stock sequence has been saved",
                backgroundColor: "#1b9d54"
            });
            setSavingSort(false);
            setActiveProduct(null);
            App.analytics.logEvent('tap_move_save', {'move_confirm': 'save'});
        });
    }

    if (!items.length) {
        return ListEmptyComponent;
    }

    return (
        <>
            <FlatList
                id="product-list"
                data={["header", ...items]}
                renderItem={
                    ({item}) => (
                        <ProductItem  
                            item={item}
                            showQuantityPopup={showQuantityPopup}
                            setActiveProduct={setActiveProduct}
                        />
                    )
                }
                keyExtractor={item => item === "header" ? item : item.product_id}
                ListHeaderComponent={header}

                stickyHeaderIndices={[1]}

                // Performance settings
                removeClippedSubviews={true} // Unmount components when outside of window
                initialNumToRender={50} // Reduce initial render amount
                maxToRenderPerBatch={50} // Reduce number in each render batch
                windowSize={50} // Reduce the window size
                keyboardShouldPersistTaps='handled'
            />

            {
                activeProduct ?
                    <div className="sort-bar">
                        <div id="sort-bar-container">
                            <Button
                                title="Save"
                                onClick={finishSort}
                                theme="primary"
                                loading={savingSort}
                                />
                            <Button
                                title="&nbsp;"
                                onClick={() => moveItem("top")}
                                theme="blank"
                                icon={<img src={topIcon} alt="Top" />}
                            />
                            <Button
                                title="&nbsp;"
                                onClick={() => moveItem("up")}
                                theme="blank"
                                icon={<img src={upIcon} alt="Up" />}
                            />
                            <Button
                                title="&nbsp;"
                                onClick={() => moveItem("down")}
                                theme="blank"
                                icon={<img src={downIcon} alt="Down" />}
                            />
                            <Button
                                title="&nbsp;"
                                onClick={() => moveItem("bottom")}
                                theme="blank"
                                icon={<img src={bottomIcon} alt="Bottom" />}
                            />
                        </div>
                    </div>
                :
                    null
            }
        </>
    );
}

const SuppliersList = ({ ListEmptyComponent, header, showQuantityPopup }) => {
    const favourites = useSelector(pubSelectors.favouritesFiltered);

    // Group favourites by supplier
    const sections = useMemo(() =>
        _.chain(favourites)
            .groupBy('supplier_id')
            .map((value, key) => ({
                id: parseInt(key),
                data: value 
            }))
            .value()
    , [favourites]);

    if (!sections.length) {
        return ListEmptyComponent;
    }

    return (
        <>
            <SectionList
                id="product-list"
                sections={sections}
                keyExtractor={(item, index) => index + '_' + item.supplier_id + '_' + item.product_id}
                keyboardShouldPersistTaps='handled'
                renderItem={({item}) => 
                    <ProductItem 
                        key={item.product_id}
                        item={item}
                        showQuantityPopup={showQuantityPopup}
                        sort={false}
                    />
                }
                ListHeaderComponent={header}
                renderSectionHeader={({section}) => <SectionHeader section={section}/>}
            />
        </>
    );
}

export const ProductList = ({ header, setQuantityPopup }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sortBy = useSelector(preferenceSelectors.getSort);

    const listEmptyComponent = (
        <h4 style={{ textAlign: 'center', color: "#ffffff" }} onClick={() => navigate("/settings/favourites")}>
            No favourites set. Click here to setup favourites.
        </h4>
    );

    const updateItem = (product, quantity) => {
        return dispatch(updateStock({ product_id: product.id, stock: quantity })).unwrap().then(data => {
            showMessage({
                title: "Enter stock",
                message: product.name + " updated"
            });
        });
    }

    const showQuantityPopup = (product, quantity) => {
        setQuantityPopup({
            product,
            quantity,
            onSave: updateItem
        });
    }

    return (
        <div className="col-md-12" id="product-list-table">
            { sortBy === 'stock' ?
                <OrderedList
                    header={header}
                    ListEmptyComponent={listEmptyComponent}
                    showQuantityPopup={showQuantityPopup}
                />
            :
                <SuppliersList
                    header={header}
                    ListEmptyComponent={listEmptyComponent}
                    showQuantityPopup={showQuantityPopup}
                />
            }
        </div>
    );
}