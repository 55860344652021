import React from 'react';
import { ActivityIndicator } from 'react-native';

import { AppService as App } from 'smartpub-common/services/app.js';

import EditIcon from '#assets/icons/edit-icon-green.svg';
import starClosedIcon from '#assets/icons/star-closed.svg';
import starOpenIcon from '#assets/icons/star-open.svg';
import { useNavigate } from 'react-router-dom';

export default function UserItem({item, onUpdate, loading}) {
    const navigate = useNavigate();

    return <UserItemClass item={item} onUpdate={onUpdate} loading={loading} navigate={navigate} />
}

class UserItemClass extends React.Component {
    // onUpdate(userID, permissions, is_active) {
    //     if(typeof this.props.onUpdate == 'function') {
    //         this.props.onUpdate(userID, permissions, is_active);
    //         return;
    //     }

    //     update(userID, permissions = null, active = null){
    //         // Check if we are in the middle of an operation on this item
    //         if (this.state.loading.indexOf(userID) > -1) {
    //             return;
    //         }
    
    //         // Add item to the list of loading
    //         var newLoading = this.state.loading.concat(userID);
    //         this.setState({ loading: newLoading });
    
    //         return App.pub.users.update(userID, permissions, active).then(() => {
    //             this.refresh();
    
    //             var productName = App.pub.users.get(userID).name;
    //             showMessage({
    //                 message: productName + (active ? " updated" : " deactivated"),
    //                 type: "success"
    //             });
    //         }, (error) => {
    //             alert("Error updating user", error);
    //             throw error;
    //         }).finally(() => {
    //             // Remove item from loading
    //             var oldLoading = this.state.loading;
    //             const index = oldLoading.indexOf(userID);
    //             if (index > -1) {
    //                 oldLoading.splice(index, 1);
    //             }
    //             this.setState({ loading: oldLoading });
    //         });
    //     }
    // }

    render() {
        var userID = this.props.item.id,
            user = App.pub.users.get(userID);

        if (!user) {
            return null;
        }
        
        var editIcon = EditIcon,
            activeIcon = starClosedIcon,
            inactiveIcon = starOpenIcon;

        var columnContent = (
            <React.Fragment>
                <div className="icon icon--edit">
                    <a onClick={() => {
                        this.props.navigate('/users/'+ userID, {
                            state: {
                                action: "edit",
                                user: user
                            }
                        });
                    }}>
                        <img src={editIcon} />
                    </a>
                </div>
                {
                    userID != App.user.ID ?
                        <div className={"icon icon--star" + (user.is_active ? ' active' : '')}>
                            <a onClick={() => {
                                this.props.onUpdate(user.id, user.permissions, !user.is_active);
                            }}>
                                <img src={user.is_active ? activeIcon : inactiveIcon} />
                            </a>
                        </div>
                    :
                        null
                }
            </React.Fragment>
        );

        if (user.role == 'PubAdmin') {
            columnContent = null;
        }

        return (
            <li key={"user" + userID}>
                <div className="list-contents">
                    <div className="list-label">
                        <p>{user.name}</p>
                    </div>

                    <div className="list-edit">
                        {
                            this.props.loading ? 
                                <ActivityIndicator size="small" color="#ffffff" />
                            :
                                columnContent
                        }
                    </div>
                </div>
            </li>
        );
    }

}