import React, { useState, useMemo, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import { Utils } from 'smartpub-common/services/utils.js';
import DatePicker from "react-datepicker";
import Button from "../Button";
import CalendarIcon from '#assets/icons/calendar-icon-active.svg';

import { useNavigate } from 'react-router-dom';

const DateRange = ({ fromDate, setFromDate, toDate, setToDate }) => {
    let calendarIcon = CalendarIcon;

    return (
        <div id="calendar-range">
            <div id="calendar-from" className={"calendar-container input date--show"}>
                <label
                    id="calendar-to-label"
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { fromDate ? null : "From" }
                </label>
                <DatePicker
                    selected={fromDate}
                    onChange={setFromDate}
                    maxDate={toDate}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div id="calendar-to" className={"calendar-container input date--show"}>
                <label
                    id="calendar-to-label"
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { toDate ? null : "To" }
                </label>
                <DatePicker
                    selected={toDate}
                    onChange={setToDate}
                    minDate={fromDate}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        </div>
    )
}

const DocFilter = ({ filter, setFilter }) => {
    const filterButtons = useMemo(() => {
        const filters = {
            all: "All docs",
            statement: "Summary statements",
            invoice: "Invoices",
            memo: "Debit/Credit notes"
        };

        return Object.entries(filters).map(([key, label]) => {
            let active = false;
            if (key === 'all' && filter.length === 0) {
                active = true;
            } else if (filter.indexOf(key) !== -1) {
                active = true;
            }

            return <Button
                theme={ active ? "primary" : "secondary" }
                title={ label }
                onClick={() => {
                    if (key === 'all') {
                        setFilter([]);
                    } else {
                        let index = filter.indexOf(key);

                        if (index === -1) {
                            setFilter([...filter, key]);
                        } else {
                            setFilter(filter.filter(item => item !== key));
                        }
                    }
                }}
            />;
        })
    }, [filter]);

    return (
        <div id="filters">
            {
                filterButtons
            }
        </div>
    );
}

export default function SearchBar({ selectedFilters, selectedDateRange }) {
    const navigate = useNavigate();

    const [filter, setFilter] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        setFilter(selectedFilters ? selectedFilters : []);
        setFromDate(selectedDateRange ? selectedDateRange[0] : null);
        setToDate(selectedDateRange ? selectedDateRange[1] : null);
    }, [selectedFilters, selectedDateRange]);

    const search = () => {
        var options = {
            searching: true,
            type: filter,
            from: fromDate,
            to: toDate
        }

        //i572 notes (AK):
        //Form url query search string in order to save search parameters in the URL.
        //Necessary in order to restore search parameters when refreshing the page or sending someone the link
        var searchString = '?'+ Object.keys(options).map(str => `${str}=${((str == 'to' || str == 'from') && options[str] ? Utils.getDateString(options[str]) : options[str] || '')}`).join('&');

        navigate("/financial/statements" + searchString);
        App.analytics.logEvent('financial_search');
    }

    return (
        <>
            <div className='search-container'>
                <div className='search-bar'>
                    <DateRange
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                    />
                    <DocFilter
                        filter={filter}
                        setFilter={setFilter}
                    />
                </div>
                <Button
                    theme="primary"
                    onClick={search}
                    title="Search"
                />
                {
                    selectedFilters ? 
                        <Button
                            theme="secondary"
                            style={{marginLeft: 10}}
                            onClick={() => navigate("/financial")}
                            title="Clear"
                        />
                    :
                        null
                }
            </div>
        </>
    );
}