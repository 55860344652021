
import React, { useState, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../includes/Button.js';
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';

import EazleLogo from '../../assets/icons/Eazle_Logo.png';
import Genie from '../../assets/icons/Genie-big.svg';

const Init = () => {
    const [initUpdates, setInitUpdates] = useState({});
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    App.on('initUpdate', updates => {
        setInitUpdates({...updates});
    });

    useEffect(() => {
        // Setup App service
        App.init(navigate, dispatch).then(async () => {
            //Redirect to initially requested URI
            var state = location.state ?? {};

            var redirect = location.state ? location.state.redirect : null;
            var avoid = ["/", "/login", "/home", "/init", "/forgot", "/reset", "/order/summary"];

            if(redirect && !avoid.includes(redirect)) {
                state.redirect = redirect;
            } else {
                redirect = "/home";
            }

            // Redirect user based on login status
            if (App.user.isLoggedIn()) {
                // Has the user accepted the terms and conditions?
                if(App.user.pubIsSet()){
                    App.user.hasAcceptedTerms().then(() => {
                        var queryParams = location.state && location.state.pathname ? location.state.pathname : "";

                        if(redirect == '/terms') {
                            state = {
                                pubinfo: true
                            }
                        } else {
                            state = null;
                        }

                        var path = redirect || "/home";
                        if(queryParams.length) path += queryParams;

                        navigate(path, { state });
                    }, (terms) => {
                        state.terms = terms;

                        navigate("/terms", { state });
                    }); 
                } else {
                    navigate("/pub", { state });
                }
            } else {
                var avoid = ["/", "/login", "/home", "/init", "/forgot",  "/reset"];
                if(redirect && avoid.includes(redirect)) delete state.redirect;

                var pathname = redirect && (redirect == "/forgot" || redirect == "/login" || redirect == "/reset") ? redirect : "/login";

                navigate(pathname, { state });
            }
        }, (error) => {
            if (error) {
                // Handle app status updates
                if (error.status) {
                    navigate("/update", { state: error.status });
                    return;
                }

                if(error != "inactive"){
                    alert(error);
                }

                this.setState({
                    error
                });
            } else {
                //Redirect to initially requested URI
                var state = location.state ?? {};

                var redirect = state.redirect ?? null;
                var avoid = ["/", "/login", "/home", "/init", "/forgot", "/reset"];

                if(redirect && !avoid.includes(redirect)) state.redirect = redirect;

                var pathname = redirect && (redirect == "/forgot" || redirect == "/login" || redirect == "/reset") ? redirect : "/login";

                navigate(pathname, { state });
            }
        });
    }, []);

    return (
        <div id="init">
            {
                error != "inactive" ?
                    <React.Fragment>
                        <div className='init-header'>
                            <h4 className='init-hi text-center'>Hi</h4>
                            <h4 className="text-center">Updating app data</h4>
                        </div>
                        <div className="loader">
                            
                            <div className="login-logo">
                                <img src={Genie} alt="Eazle logo" id="logo" />
                            </div>
                        </div>
                        <div className="updates">
                            <div className='updates-container'>
                                {Object.keys(initUpdates).map((key) => {
                                    var status = '...';
                                    if (initUpdates[key] === 1) {
                                        status = 'loaded'
                                    } else if (initUpdates[key] === 2) {
                                        status = 'failed'
                                    }
                                    return (
                                        <div key={key} style={{marginTop: 3, fontSize: 12, color: '#ffffff'}}>
                                            {key} [{status}]
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                : 
                    <div className="user-inactive">
                        <div className="login-logo">
                            <img src={EazleLogo} alt="Eazle logo" id="logo" />
                        </div>
                        <h5 className="text-center">Your account is inactive</h5>
                        <p className="text-center">Please choose a different pub, log in with a different account or contact your pub manager to resolve this issue.</p>
                        <Button
                            theme="primary"
                            title="Logout"
                            onClick={() => App.user?.logout()}
                            bgColor="white"
                            style={{flex: 0, marginTop: 15, marginBottom: 10, backgroundColor: "red"}}
                            titleStyle={{fontSize: 18}}
                        />
                        {
                            App.user?.getPubs().length > 1 ?
                                <Button
                                    theme="primary"
                                    title="Choose a different pub"
                                    onClick={() => navigate("/pub")}
                                    bgColor="green"
                                    style={{flex: 0, marginTop: 0}}
                                    titleStyle={{fontSize: 18}}
                                />
                            :
                                null
                        }
                    </div>
                }
        </div>
    );
};

export default Init;