import React from 'react';

export default function Value({value, name="input", onClick, visible = true, borderColour = "#cefab7", backgroundColour = "#cefab7", colour = "#00414B"}) {

    const number = value ? value : 0;

    if (!visible) return;
    return (<>
        { onClick ?
            <button onClick={onClick} className={"product-list-item-value editable product-list-item-value--" + name} style={{backgroundColor: backgroundColour, borderColor: borderColour, color: colour}}>
                {number}
            </button>
        :
            <div className={"product-list-item-value product-list-item-value--" + name}>
                {number}
            </div>
        }
    </>
    )
}