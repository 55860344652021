import React, { useState } from 'react';
import { AppService as App } from 'smartpub-common/services/app';
import { ProductList } from '../includes/stock/ProductList';
import { PopUpFilterSort } from '../includes/stock/PopUpFilterSort';

import filterIcon from '../../assets/icons/filter-button.svg';
import { PopUpQuantity } from '../includes/PopUpQuantity';

export default function StockCount() {
    const [showFilterPopup, setShowFilterPopup] = useState(false);

    const [quantityPopup, setQuantityPopup] = useState();
    const [quantityPopupLoading, setQuantityPopupLoading] = useState(false);

    return (
        <div id="stock-count-update">
            <div className='container'>
                <a href='#!' className='filter-sort' onClick={()=>{
                    App.analytics.logEvent('tap_filter_count');
                    setShowFilterPopup(true);
                }}>
                    Filter & sort
                    <img src={filterIcon} alt="Filter icon" />
                </a>
            </div>

            <div className="row">
                <ProductList
                    setQuantityPopup={setQuantityPopup}
                />
            </div>

            <PopUpQuantity
                title="Enter stock"
                loading={quantityPopupLoading}
                isVisible={quantityPopup}
                quantity={quantityPopup?.quantity}
                minOrderQty={false}
                onSave={(quantity) => {
                    setQuantityPopupLoading(true);
                    return quantityPopup.onSave(quantityPopup.product, quantity).then(() => {
                        setQuantityPopupLoading(false);
                        setQuantityPopup(null);
                    });
                }}
                onDismiss={()=>{
                    setQuantityPopupLoading(false);
                    setQuantityPopup(null);
                }}
                product={quantityPopup?.product}
            />

            <PopUpFilterSort
                visible={showFilterPopup}
                onDismiss={() => setShowFilterPopup(false)}
            />
        </div>
    )
}
