import React, { useState } from 'react';
import { PopUpEditSupplier } from '../includes/PopUpEditSupplier';
import { AppService as App } from 'smartpub-common/services/app';
import Button from '../includes/Button';
import SupplierList from '../includes/favourites/SupplierList';
import { PopUpConfirm } from '../includes/PopUpConfirm.js';
import { PopUpSelectSupplier } from '../includes/PopUpSelectSupplier';

import { store } from 'smartpub-common/redux/default';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { moveFavouriteProducts } from 'smartpub-common/redux/slices/pub';
import { useDispatch } from 'react-redux';
import { showMessage } from '../facades/showMessage.js';
import { PopUpQuantity } from '../includes/PopUpQuantity.js';

export default function Favourites() {
    const [showNewSupplierPopup, setShowNewSupplierPopup] = useState(false);
    const [confirm, setConfirm] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [quantityPopup, setQuantityPopup] = useState();
    const [quantityPopupLoading, setQuantityPopupLoading] = useState(false);

    const [selectSupplierCurrent, setSelectSupplierCurrent] = useState();
    const [selectSupplierLoading, setSelectSupplierLoading] = useState(false);

    const dispatch = useDispatch();

    const changeSupplier = (newSupplier) => {
        if (selectSupplierLoading) return;

        setSelectSupplierLoading(true);
        
        const supplier = supplierSelectors.getById(store.getState(), selectSupplierCurrent);

        dispatch(moveFavouriteProducts({ oldSupplierId: supplier.id, newSupplierId: newSupplier } )).unwrap().then(() => {
            const newSupplierObj = supplierSelectors.getById(store.getState(), newSupplier);
            const message = "Products moved from " + supplier.name + " to " + newSupplierObj.name;

            showMessage({
                message: "Supplier products moved",
                description: message,
                backgroundColor: "#1b9d54"
            });

            setSelectSupplierCurrent(null);
            setSelectSupplierLoading(false);
        });
    }

    return (
        <div id="favourites">
            <div className="container">
                <Button
                    title="Add supplier"
                    theme="primary"
                    className="mb-4 btn--add-supplier"
                    onClick={() => {
                        setShowNewSupplierPopup(true);
                        App.analytics.logEvent('tap_add_supplier');
                    }}
                />
            </div>

            <div id="favourites-wrapper">
                <div className="col-md-12" id="product-list-table">
                    <SupplierList
                        header={
                            <Button
                                title="Add supplier"
                                theme="primary"
                                className="mb-4 btn--add-supplier"
                                onClick={() => {
                                    this.setState({
                                        showSupplierPopup: true,
                                        showSupplierPopupSupplier: null,
                                        supplierEditType: "create"
                                    });
                                    App.analytics.logEvent('tap_add_supplier');
                                }}
                            />
                        }
                        setConfirm={setConfirm}
                        setQuantity={setQuantityPopup}
                        setSelectSupplierCurrent={setSelectSupplierCurrent}
                    />
                </div>

                <PopUpQuantity
                    title="Set par"
                    loading={quantityPopupLoading}
                    isVisible={quantityPopup}
                    quantity={quantityPopup?.quantity}
                    onSave={(quantity) => {
                        setQuantityPopupLoading(true);
                        return quantityPopup.onSave(quantity).then(() => {
                            setQuantityPopupLoading(false);
                            setQuantityPopup(null);
                        });
                    }}
                    onDismiss={()=>{
                        setQuantityPopupLoading(false);
                        setQuantityPopup(null);
                    }}
                    product={quantityPopup?.product}
                />
                
                <PopUpConfirm
                    loading={confirmLoading}
                    isVisible={confirm}
                    modalTitle={confirm?.title}
                    content={confirm?.content}
                    onConfirm={() => {
                        setConfirmLoading(true);
                        confirm.onConfirm().then(() => {
                            setConfirmLoading(false);
                            setConfirm(null);
                        });
                    }}
                    onCancel={()=>{
                        setConfirmLoading(false);
                        setConfirm(null);
                    }}
                />

                <PopUpEditSupplier
                    type={"create"}
                    onDismiss={() => {
                        setShowNewSupplierPopup(false);
                    }}
                    visible={showNewSupplierPopup}
                />

                <PopUpSelectSupplier
                    title="Select new supplier"
                    button="Move"
                    hideHIL={true}
                    onlyFavourites={true}
                    loading={selectSupplierLoading}
                    onSave={changeSupplier}
                    visible={!!selectSupplierCurrent}
                    exclude={selectSupplierCurrent}
                    onDismiss={() => {
                        setSelectSupplierCurrent(null);
                    }}
                />
            </div>
        </div>
    );
}