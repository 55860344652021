import React, { useEffect, useState } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import RealexHpp from '../../../vendor/rxp-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopUpAlert } from '../../includes/PopUpAlert.js';

export default function Payment() {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const order = location.state?.order;

    useEffect(() => {
        if (!order) {
            navigate('/order/checkout', { replace: true });
            return;
        }

        RealexHpp.setHppUrl(order.payment.endpoint);
        // get the HPP JSON from the server-side SDK
        RealexHpp.embedded.init(
            "autoload",
            "targetIframe",
            handleResponse,
            order.payment
        );
    }, []);

    const handleResponse = (answer, close) => {
        close(); // Close iFrame

        App.api.request("order/" + order.id + "/payment/response", answer, "POST").then((data) => {
            let result = {};
            if (data == false) {
                result.error = true;
            }
            result.data = data;
            return result;
        }).then((data) => {
            if(data.error) {
                setError("Please check your card details and try again\n\n" +  answer.MESSAGE);
            } else {
                navigate('/order/summary', {
                    state: {
                        order: data.data
                    },
                    replace: true
                });
            }
        });
    }

    return (
        <div id="order-summary">
            <div className="row container">
                <div className="col-md-12">
                    <h4 className="text-center">Order Payment</h4>

                    <iframe id="targetIframe" style={{ width: '100%' }}></iframe>
                </div>
            </div>

            <PopUpAlert
                modalTitle="Payment failed"
                content={ error }
                onDismiss={()=>{
                    navigate('/order/checkout', { replace: true });
                }}
                buttonTitle='Ok'
                isVisible={ error }
            />
        </div>
    );
}