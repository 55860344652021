import React, { useState, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../Button.js';
import {
    SectionList
} from 'react-native';
import { store } from 'smartpub-common/redux/default';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as pubSelectors, moveFavouriteProducts, removeSupplier as removeSupplierThunk } from 'smartpub-common/redux/slices/pub';
import { useSelector, useDispatch } from 'react-redux';

import { ProductItem } from './ProductItem.js';
import { PopUpSelectSupplier } from '../PopUpSelectSupplier';
import { PopUpEditSupplier } from '../PopUpEditSupplier';
import { showMessage } from "../../facades/showMessage.js";
import { useNavigate, createSearchParams } from 'react-router-dom';
import ColumnHeaders from './ColumnHeaders.js';

import closeIcon from '#assets/icons/close-teal.svg';
import nextIcon from '#assets/icons/next-back-icon-teal.svg';

import _ from 'lodash';

const SectionHeader = ({ section, toggleSection, open, setConfirm, setSelectSupplierCurrent }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    open = open && section.data.length;

    const [showEditSupplierPopup, setShowEditSupplierPopup] = useState(null);
    const [showChangeSupplierPopup, setShowChangeSupplierPopup] = useState(false);
    const [showSupplierLoading, setShowSupplierLoading] = useState(false);

    const supplier = useSelector(state => supplierSelectors.getById(state, section.supplier_id));

    if (!supplier) return;

    const confirm = {
        title: "Remove supplier",
        content: "Removing this supplier will delete all the favourites associated with this supplier.\n\nAre you sure you want to continue?",
        onConfirm: () => {
            return dispatch(removeSupplierThunk(supplier.id)).unwrap().then(data => {
                showMessage({
                    message: supplier.name + " removed",
                    backgroundColor: "#1b9d54"
                });
            });
        }
    }

    const changeSupplier = (newSupplier) => {
        if (showSupplierLoading) return;

        setShowSupplierLoading(true);
        
        dispatch(moveFavouriteProducts({ oldSupplierId: supplier.id, newSupplierId: newSupplier } )).unwrap().then(() => {
            const newSupplierObj = supplierSelectors.getById(store.getState(), newSupplier);
            const message = "Products moved from " + supplier.name + " to " + newSupplierObj.name;

            showMessage({
                message: "Supplier products moved",
                description: message,
                backgroundColor: "#1b9d54"
            });

            setShowChangeSupplierPopup(false);
            setShowSupplierLoading(false);
        });
    }

    let arrow;
    if(section.data.length) {
        arrow = (
            <span
                className={ "supplier-header-arrow-button supplier-header-arrow-button--" + (open ? "open" : "closed") }
                onClick={() => toggleSection(supplier.id, !open)}>
                    <img
                        src={nextIcon}
                    />
            </span>
        );
    }

    const header = (
        <>
            <div className={ "supplier-header-container supplier-header-container--" + (open ? "open" : "closed")}>
                <div className="container">
                    <div className="supplier-header">
                        { !supplier.is_heineken && supplier.supplier_id !== 4 ?
                            <div className="supplier-header-remove-wrapper">
                                <span className="supplier-header-remove" onClick={() => setConfirm(confirm)}>
                                    <img
                                        src={closeIcon}
                                    />
                                </span>
                            </div>
                            : null
                        }

                        <span className="supplier-header-name">
                            <h5>
                                { supplier.supplier_id == 4 ? 'Choose new supplier' : supplier.name }
                                <small style={{fontWeight: "normal"}}>{ section.supplier && section.supplier.email ? " (Orders sent to "+ section.supplier.email +")": "" }</small>
                            </h5>
                        </span>

                        <div className="supplier-header-arrow">
                            { arrow }
                        </div>
                    </div>
                        
                    <div className="supplier-header-buttons d-flex">
                        {
                            supplier && supplier.supplier_id !== 4 ?
                                <Button
                                    theme="secondary"
                                    title="Add product"
                                    onClick={() => {
                                        const query = createSearchParams({ supplier: supplier.id }).toString();
                                        navigate(`/settings/favourites/categories?${query}`);
                                        App.analytics.logEvent('tap_add_product', {'supplier': supplier.name});
                                    }}
                                />
                            :
                                    null
                        }

                        {
                            !supplier.is_heineken ?
                                <React.Fragment>
                                    {
                                        section.data.length ?
                                            <Button
                                                theme="secondary"
                                                title="Move products"
                                                onClick={() => {
                                                    setSelectSupplierCurrent(supplier.id);
                                                }}
                                            />
                                            : null
                                    }
                                    <Button
                                        theme="secondary"
                                        title="Edit supplier"
                                        onClick={() => {
                                            setShowEditSupplierPopup(true);
                                        }}
                                    />
                                </React.Fragment>
                            : null
                        }
                    </div>
                </div>
            </div>
            { open ? 
                <div className='columnheaders'>
                    <ColumnHeaders />
                </div>
            : null }
            {
                showEditSupplierPopup ?
                    <PopUpEditSupplier
                        supplier={supplier}
                        type={"edit"}
                        onDismiss={() => {
                            setShowEditSupplierPopup(false);
                        }}
                        visible={true}
                    />
                : null
            }

            {
                showChangeSupplierPopup ?
                    <PopUpSelectSupplier
                        visible={true}
                        title="Select new supplier"
                        button="Move"
                        hideHIL={true}
                        onlyFavourites={true}
                        loading={showSupplierLoading}
                        exclude={supplier.id}
                        onSave={changeSupplier}
                        onDismiss={() => {
                            setShowChangeSupplierPopup(false);
                        }}
                    />
                : null
            }
        </>
    )

    return header;
}

export default function SupplierList({ header, setConfirm, setQuantity, setSelectSupplierCurrent }) {
    const [sections, setSections] = useState([]);
    const [closedSections, setClosedSections] = useState([]);
    const pubSuppliers = useSelector(state => pubSelectors.suppliers(state, true));
    const pubFavourites = useSelector(pubSelectors.favourites);

    useEffect(() => {
        if (!pubSuppliers) return;

        let newSections = pubSuppliers.map(pubSupplier => ({
            supplier_id: pubSupplier.supplier_id,
            data: pubFavourites.filter(item => item.supplier_id === pubSupplier.supplier_id)
        }));

        const supplierSelectableProducts = pubFavourites.filter(item => item.supplier_id === 4);

        if (supplierSelectableProducts.length) {
            newSections.push({
                supplier_id: 4,
                data: supplierSelectableProducts
            });
        }

        setSections(newSections);
    }, [pubSuppliers, pubFavourites]);

    if (!sections || !sections.length) {
        return <div>
            No favourites
        </div>
    }

    const toggleSection = (toggleId, open) => {
        if (open) {
            setClosedSections(closedSections.filter(item => item !== toggleId));
        } else {
            setClosedSections([...closedSections, toggleId]);
        }
    }

    return (
        <SectionList
            sections={sections}
            keyExtractor={item => item.supplier_id + '|' + item.product_id}
            renderItem={
                ({ item, section }) => (
                    <ProductItem
                        item={item}
                        visible={!closedSections.includes(section.supplier_id)}
                        setConfirm={setConfirm}
                        setQuantity={setQuantity}
                    />
                )
            }
            renderSectionHeader={
                ({ section }) => (
                    <SectionHeader
                        section={section}
                        toggleSection={toggleSection}
                        open={!closedSections.includes(section.supplier_id)}
                        setConfirm={setConfirm} 
                        setSelectSupplierCurrent={setSelectSupplierCurrent}
                    />
                )
            }
            stickySectionHeadersEnabled={true}
            ListHeaderComponent={header}
        />
    )
};