import React from 'react';
import { View, Text } from 'react-native';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { useSelector } from 'react-redux';
import ColumnHeaders from './ColumnHeaders';

export default function SectionHeader({ section }) {
    const supplier_id = section.id;
    const supplier = useSelector(state => supplierSelectors.getById(state, supplier_id));


    return <>
        <div className="supplier-header" style={{marginTop: "1em"}}>
            <div className="container">
                <div className="supplier-name">
                    {supplier && supplier_id !== 4 ? supplier.name : "Choose new supplier"}
                </div>
            </div>
        </div>

        <ColumnHeaders />
    </>;
}