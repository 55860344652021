import React, { useState, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import { Utils } from "smartpub-common/services/utils";
import SearchBar from '../../includes/history/SearchBar';

import CartIcon from "#assets/icons/cart-icon.svg";
import CountIcon from "#assets/icons/tally-icon.svg";
import PackageIcon from "#assets/icons/packaged-icon.svg";
import arrowIcon from '#assets/icons/next-back-icon-green.svg';

import "react-datepicker/dist/react-datepicker.css";

import { FlatList } from 'react-native';

import { useSelector } from 'react-redux';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { useParams, useNavigate, Link } from 'react-router-dom';

/**
 * Single product line element
 */
const ProductLine = ({item}) => {
    const [showFull, setShowFull] = useState(false);
    const product = useSelector(state => productSelectors.getById(state, item.product_id));  

    if (item.cancelled && product?.name.toLowerCase().includes('blade')) {
        return null;
    }

    let renderCountIcon = (icon, count, text) =>
        <div className='history-product-drop-down-item'>
            {
                text ?
                    <div className='product-order-details-header'>
                        { text }
                    </div>
                    : null
            }
            <div className='product-order-details'>
                <img src={icon} />
                <p>{ count }</p>
            </div>
        </div>;

    return (
        <button
            className='history-order-product'
            onClick={() => setShowFull((prev) => !prev)}
        >
            <div className='shadow-container' style={showFull ? {boxShadow: "rgba(0, 0, 0, 0.2) 2px 6px 3px -3px"} : {boxShadow: null }}>
                <div className='container'>
                    <div className={"history-product-on-display " + (item.cancelled ? 'cancelled': '')}>
                        <div className={'history-order-item-bullet ' + (item.cancelled ? 'cancelled': '')}></div>
                        {
                            item.free_beer ?
                            <div className='order-info-container'>
                                <div className='order-info fb' data-tooltip="Free beer">
                                    FB
                                    <div className='order-info-hover order-info-hover--fb'>Free Beer<div className='arrow'></div></div>
                                </div>
                            </div>
                            : null
                        }
                        {
                            item.cancelled ?
                            <div className='order-info-container'>
                                    <div className='order-info i' data-tooltip="Cancelled">
                                        i
                                        <div className='order-info-hover order-info-hover--i'>Cancelled<div className='arrow'></div></div>
                                    </div>
                                </div>                            
                            : null
                        }
                        <div className='history-line-title'>{ product?.name }</div>
                        <div className='product-order-details-preview'>
                            { renderCountIcon(CartIcon, item.qty) }
                        </div>
                        <img src={arrowIcon} className={'history-item-arrow ' + (showFull ? 'active' : '')} />
                    </div>
                </div>
            </div>
            {
                showFull ?
                    <div className="history-product-drop-down">
                        { renderCountIcon(CountIcon, item.stock, "Stock\ncount") }
                        { renderCountIcon(CartIcon, item.qty, "Quantity\nordered") }
                        { renderCountIcon(PackageIcon, item.qty_delivered, "Quantity\ndelivered") }
                    </div>
                : null
            }

        </button>
    );
};


/**
 * History screen
 */
export default function HistoryOrder() {
    const [order, setOrder] = useState(null);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        App.pub.history.getSuborder(params.id).then((order) => {
            setOrder(order);
        }, () => {
            if(!order){
                setOrder([]);
            }
        });
    }, []);
    
    const supplier = useSelector(state => supplierSelectors.getById(state, order?.supplier_id));
    
    if (!order) return (
        <>Loading...</>
    );

    const search = (options) => {
        var opts = {
            end: options.toDate,
            start: options.fromDate,
            supplier_id: options.supplier_id || '',
            reference: options.reference
        };

        navigate('/history', { state: opts });

        App.analytics.logEvent('history_search');
    }

    const getOrderDetails = () => {
        let orderDetails = [
            {
                title: 'Supplier',
                content: supplier.name
            }
        ];

        if (order.po) {
            orderDetails.push({
                title: 'PO',
                content: order.po
            });
        } else {
            orderDetails.push({
                title: 'OrdRef',
                content: order.ref
            });
        }

        orderDetails = orderDetails.concat([{
            title: 'Order status',
            content: order.status,
            extra: 'Order status is up to date within the last 24 hours'
        },
            {
                title: 'Delivery date',
                content: (supplier.id == 1 || supplier.id == 3) && order.delivery_date ? Utils.getDateString(order.delivery_date, false, true) : 'N/A'
            },
            {
                title: 'Order source',
                content: order.source
            }
        ]);

        if (order.paid && order.paid.id) {
            orderDetails.push({
                title: 'Payment',
                content: `€${ Utils.roundFloat(order.paid.total_excl) } + €${ Utils.roundFloat(order.paid.total - order.paid.total_excl) } VAT`
            });

            orderDetails.push({
                title: 'Payment reference',
                content: order.paid.id
            });
        }

        orderDetails.push({
            title: 'Order notes',
            content: order.delivery_note
        });

        return orderDetails;
    }

    if (!order || !supplier) return null;

    let orderDetails = getOrderDetails();

    let backPath = `/history/`;
    if(window.location.search != '') backPath += window.location.search.replace(/order\=([0-9]+)\&/, '');

    let orderDetailsHTML = orderDetails.map((section, index) => (
        <div className='order-details'>
            <h4>{ section.title }</h4>
            <h5>{ section.content }</h5>
            {
                section.extra ?
                    <p>{ section.extra }</p>
                : null
            }
        </div>
    ));

    return (
        <div id="history-order">
            <div className='container row search'>
                <div className='col-md-12 history-search'>
                    <SearchBar />
                </div>
            </div>
            <div className="container row content">
                <div className="col-md-6">
                    <div className='history-order-detail-container'> 
                        <Link to={backPath} className="go-back"><img src={arrowIcon} />Back</Link>
                        <div className='history-order-detail'>
                            { orderDetailsHTML }
                        </div>
                        <div className='history-order-detail-info'>Please check quantity delivered and contact supplier directly with delivery queries</div>
                    </div>
                </div>
                <div className="col-md-6 history-orders-container">
                    <FlatList
                        data={order.products}
                        renderItem={({ item }) => {
                            return <ProductLine item={item} />
                        }}
                        keyExtractor={item => item.product_id}

                        // Performance settings
                        removeClippedSubviews={true} // Unmount components when outside of window
                        initialNumToRender={50} // Reduce initial render amount
                        maxToRenderPerBatch={50} // Reduce number in each render batch
                        windowSize={50} // Reduce the window size
                    />
                </div>
            </div>
        </div>
    );
}