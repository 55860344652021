import { configureStore, combineReducers } from '@reduxjs/toolkit';
import productsReducer from './slices/products';
import orderReducer from './slices/order';
import preferenceReducer from './slices/preference';
import suppliersReducer from './slices/suppliers';
import categoryReducer from './slices/categories';
import pubReducer from './slices/pub';
import remindersReducer from './slices/reminders';
import appReducer from './slices/app';

import mainInitialState from './mainInitialState';

const combinedReducer = combineReducers({
    products: productsReducer,
    order: orderReducer,
    suppliers: suppliersReducer,
    categories: categoryReducer,
    pub: pubReducer,
    preferences: preferenceReducer,
    reminders: remindersReducer,
    app: appReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL') {
        state = mainInitialState;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
});