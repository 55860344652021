import React, { useState } from 'react';

import { SectionList } from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions, selectors as orderSelectors } from 'smartpub-common/redux/slices/order';
import { ProductItem } from './ProductItem';

import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { PopUpQuantity } from '../../PopUpQuantity';


const SectionHeader = ({section}) => {
    const supplier = useSelector(state => supplierSelectors.getById(state, section.id));

    return (
        <>
            <div key={'section' + section.title} className={"supplier-header"}>
                <div className='container'>
                    <div className='supplier-name'>
                        { supplier.name }
                    </div>
                </div>
            </div>
        </>
    )
}

const SectionFooter = ({section}) => {
    const dispatch = useDispatch();
    const order = useSelector(state => state.order);

    const onPurchaseOrderUpdate = (purchase_order) => {
        dispatch(orderActions.setPurchaseOrder({
            purchase_order,
            supplier_id: section.id
        }));
    }

    return (
        <div style={{margin: 15}}>
            <p style={{marginBottom: 7, fontSize: 16, color: "#ffffff"}}>Purchase Order</p>
            <input
                type="text"
                value={order.purchase_orders[section.id]}
                style={{border: "1px solid #fff", borderRadius: 20, padding: 15}}
                onChange={
                    (e) => {
                        onPurchaseOrderUpdate(e.target.value);
                    }
                }
            />
        </div>
    )
}

export const ProductList = ({ footer }) => {
    const dispatch = useDispatch();
    const suppliers = useSelector(state => orderSelectors.getGroupedBySupplier(state, true));

    const [qtyPopupProduct, setQtyPopupProduct] = useState(null);

    const sections = suppliers.map(supplier => ({
        id: supplier.supplier_id,
        data: supplier.items
    }));

    const onQuantityUpdate = quantity => {
        dispatch(orderActions.addOrUpdate({
            product_id: qtyPopupProduct?.product_id,
            quantity
        }));
        
        return Promise.resolve();
    }

    return <>
        <SectionList
            sections={sections}
            keyExtractor={(item, index) => index + '_' + item.supplier_id + '_' + item.product_id}
            ListFooterComponent={footer}
            keyboardShouldPersistTaps='handled'
            renderItem={({item}) => 
                <ProductItem
                    item={item}
                    setQtyPopupProduct={setQtyPopupProduct}
                />
            }
            renderSectionHeader={({ section }) => <SectionHeader section={section} />}
            renderSectionFooter={({section}) => <SectionFooter section={section} />}
        />
        
        <PopUpQuantity
            quantity={qtyPopupProduct?.quantity ?? 0}
            onSave={onQuantityUpdate}
            onDismiss={() => setQtyPopupProduct(null)}
            product={qtyPopupProduct}
            isVisible={qtyPopupProduct}
        />
    </>;
}