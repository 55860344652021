import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { ApiService as Api } from '../../services/api';
import { Utils } from '../../services/utils';

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    loading: 'idle',
    items: []
}

const validate = (data) => new Promise((resolve, reject) => {
    if (!data.title) {
        return reject('Title is required');
    }
    if (!data.start_date) {
        return reject('Start date is required');
    }
    /*if (new Date(new Date(data.start_date).toDateString()) <= new Date(new Date().toDateString())) {
        return reject('Start date must be after today');
    }*/

    data.start_date = Utils.getDateString(data.start_date);
    if (data.end_date) {
        data.end_date = Utils.getDateString(data.end_date);
    } else {
        data.end_date = data.start_date;
    }

    if (new Date(new Date(data.end_date).toDateString()) < new Date(new Date(data.start_date).toDateString())) {
        return reject('End date must be after start date');
    }

    if (data.hasOwnProperty('description') && !data.description) {
        delete data.description;
    }

    resolve(data);
});

export const fetch = createAsyncThunk('reminders/fetch', async () => await Api.request('reminders'));

export const create = createAsyncThunk('reminders/create', async (data) => {
    return validate({...data}).then(data => App.api.request('reminder', data, 'POST'));
});

export const update = createAsyncThunk('reminders/update', async (data) => {
    return validate({...data}).then(data => App.api.request('reminder', data, 'PATCH'));
});

export const remove = createAsyncThunk('reminders/remove', async (id) => {
    return App.api.request('reminder', { id }, 'DELETE');
});

const slice = createSlice({
    name: 'reminders',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
                App.initUpdate('Reminders', 0);
            }
        });

        builder.addCase(fetch.fulfilled, (state, { meta, payload }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.items = payload.map(item => {
                item.end_date = new Date(item.end_date);
                item.start_date = new Date(item.start_date);
                return item;
            });

            state.loading = 'idle';
            state.currentRequestId = undefined;

            App.initUpdate('Reminders', 1);
            App.cache.set("reminders", state.items);
        });

        builder.addCase(fetch.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.loading = 'idle'
            state.currentRequestId = undefined

            console.error("Unable to load reminders");
            App.initUpdate('Reminders', 2);
            
            // Load from cache as offline
            App.cache.get("reminders").then((reminders) => {
                state.items = reminders.map(item => {
                    item.end_date = new Date(item.end_date);
                    item.start_date = new Date(item.start_date);
                    return item;
                });
            });
        });

        builder.addCase(create.fulfilled, (state, { meta, payload: item }) => {
            item.personal = true;
            item.end_date = new Date(item.end_date);
            item.start_date = new Date(item.start_date);

            state.items = [...state.items, item];
            App.cache.set("reminders", state.items);
        });

        builder.addCase(update.fulfilled, (state, { meta, payload: newItem }) => {
            newItem.end_date = new Date(newItem.end_date);
            newItem.start_date = new Date(newItem.start_date);

            // Find and update

            state.items = state.items.map(item => {
                if (item.id == newItem.id) {
                    return newItem;
                }

                return item;
            });
            App.cache.set("reminders", state.items);
        });

        builder.addCase(remove.fulfilled, (state, { meta: { arg: id } }) => {
            state.items = state.items.filter(item => item.id !== id);
            App.cache.set("reminders", state.items);
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;


export const selectors = {
    getList: createMapSelector([
        state => state.reminders.items,
        (state, filter = false) => filter,
        (state, filter = false, historical = false) => historical,
        (state, filter = false, historical = false, limit = false) => limit
    ], (items, filter, historical, limit) => {
        if (!items) return [];

        var filtered = items.filter((item) => {
            if (historical && !Utils.isDateBeforeToday(item.end_date)) {
                return false;
            }

            if (!historical && Utils.isDateBeforeToday(item.end_date)) {
                return false;
            }

            if (filter && ((filter == 'mypub' && !item.personal) || (filter != 'mypub' && item.personal ))) {
                return false;
            }

            let week = 7 * 24 * 60 * 60 * 1000;
            if (limit && (item.start_date - (new Date)) > week) {
                return false;
            }

            return true;
        });

        // Sort by start date
        filtered.sort((a, b) => {
            if (a.start_date - b.start_date === 0) { // If start is the same, order by end
                return a.end_date - b.end_date;
            }

            return a.start_date - b.start_date
        });

        return filtered;
    }),
    getById: createMapSelector([
        state => state.reminders.items,
        (state, id) => parseInt(id),
    ], (items, id) => {
        console.log(id, items);
        return items.find(item => item.id === id);
    })
};
