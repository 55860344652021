import React from 'react';

import { Provider } from 'react-redux'
import { store } from 'smartpub-common/redux/default.js';
import Page from './components/includes/Page';

import Init from './components/screens/Init';
import PubInit from './components/screens/PubInit';
import Update from './components/screens/Update';
import Login from './components/screens/Login';
import LoginForgot from './components/screens/LoginForgot';
import LoginReset from './components/screens/LoginReset';
import Home from './components/screens/Home';
import Favourites from './components/screens/Favourites';
import Settings from './components/screens/Settings';
import Financial from './components/screens/Financial';
import History from './components/screens/history/History.js';
import HistoryOrder from './components/screens/history/Order.js';
import HistoryReport from './components/screens/history/Report';
import Statements from './components/screens/Statements';
import OrderMenu from './components/screens/order/OrderMenu';
import StockMenu from './components/screens/StockMenu';
import StockCount from './components/screens/StockCount';
import Order from './components/screens/order/Order';
import OrderConfirmation from './components/screens/order/Confirmation';
import OrderSummary from './components/screens/order/Summary';
import OrderPayment from './components/screens/order/Payment';
import ProductCategories from './components/screens/ProductCategories';
import Users from './components/screens/Users.js';
import PubInfo from './components/screens/PubInfo';
import Feedback from './components/screens/Feedback';
import Request from './components/screens/Request';
import Reminders from './components/screens/Reminders';
import ReminderItem from './components/screens/ReminderItem.js';
import Terms from './components/screens/Terms';
import SocialMenu from './components/screens/SocialMenu';
import UserForm from './components/screens/UserForm';

import {
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";
import { PopUpMessage } from './components/includes/PopUpMessage.js';

String.prototype.ucFirst = function() {
	if(!this.length) return this;
	var firstChar = this[0].toUpperCase();
	var title = this.substring(1, this.length);
	title = firstChar + title;

	return title;
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <Page showBanner={false} screenName={'Init'} showHeader={false} component={<Init />}/>,
		errorElement: <Page showBanner={false} showHeader={false} component={
			<div style={{ flex: 1, justifyContent: "center", alignItems: "center", display: "flex"}}>404</div>
		}/>
	},
	{
		path: "/init",
		element: <Page showBanner={false} screenName={'Init'} showHeader={false} component={<Init />}/>
	},
	{
		path: "/update",
		element: <Page showBanner={false} screenName={'Update'} showHeader={false} component={<Update />}/>
	},
	{
		path: "/login",
		element: <Page showBanner={false} screenName={'Login'} showHeader={false} component={<Login />}/>
	},
	{
		path: "/forgot",
		element: <Page showBanner={false} screenName={'LoginForgot'} showHeader={false} component={<LoginForgot />}/>
	},
	{
		path: "/reset",
		element: <Page showBanner={false} screenName={'LoginReset'} showHeader={false} component={<LoginReset />}/>
	},
	{
		path: "/terms",
		element: <Page showBanner={false} screenName={'Terms'} showHeader={false} component={<Terms />}/>
	},
	{
		path: "/pub",
		element: <Page showBanner={false} screenName={'PubInit'} showHeader={false} component={<PubInit />}/>
	},
	{
		path: "/home",
		element: <Page showBanner={false} screenName={'Home'} component={<Home />}/>
	},
	{
		path: "/stock",
		element: <Page title="Stock count" screenName={'StockMenu'} component={<StockMenu />}/>,
	},
	{
		path: "/stock/count",
		element: <Page title="Update current stock" screenName={'StockCount'} component={<StockCount />}/>
	},
	{
		path: "/order",
		element: <Page title="Order" screenName={'OrderMenu'} component={<OrderMenu />}/>,
	},
	{
		path: "/order/others/:category?",
		element: <Page component={ProductCategories} screenName={'ProductCategories'} componentProps={{type: "order"}}/>
	},
	{
		path: "/order/others/:category/products",
		element: <Page component={ProductCategories} screenName={'ProductCategories'} componentProps={{type: "order", showProducts: true}}/>
	},
	{
		path: "/order/new",
		element: <Page screenName={'Order'} component={Order}/>
	},
	{
		path: "/order/checkout",
		element: <Page screenName={'OrderConfirmation'} component={OrderConfirmation}/>
	},
	{
		path: "/order/payment",
		element: <Page title="Payment" screenName={'OrderPayment'} component={<OrderPayment />}/>
	},
	{
		path: "/order/summary",
		element: <Page title="Summary" screenName={'OrderSummary'} component={<OrderSummary />}/>
	},
	{
		path: "/financial",
		element: <Page title="Financial" screenName={'Financial'} component={<Financial />}/>
	},
	{
		path: "/financial/statements",
		element: <Page title="Heineken statements" screenName={'Statements'} component={<Statements />}/>
	},
	{
		path: "/history",
		element: <Page title="Order history" screenName={'History'} component={<History />}/>
	},
	{
		path: "/history/order/:id",
		element: <Page title="History" screenName={'HistoryOrder'} component={<HistoryOrder />}/>
	},
	{
		path: "/history/report",
		element: <Page title="History" screenName={'HistoryReport'} component={<HistoryReport />}/>
	},
	{
		path: "/reminders",
		element: <Page title="Reminders" screenName={'Reminders'} component={<Reminders />}/>
	},
	{
		path: "/reminder/:rid",
		element: <Page title="Reminder" screenName={'ReminderItem'} component={<ReminderItem />}/>
	},
	{
		path: "/settings/favourites/categories/:category?",
		element: <Page component={ProductCategories} screenName={'ProductCategories'} componentProps={{type: "favourites"}}/>
	},
	{
		path: "/settings/favourites/categories/:category/products",
		element: <Page component={ProductCategories} screenName={'ProductCategories'} componentProps={{type: "favourites", showProducts: true}}/>
	},
	{
		path: "/settings/favourites",
		element: <Page title="Favourites" screenName={'Favourites'} component={<Favourites />}/>
	},
	{
		path: "/settings",
		element: <Page title="My pub" screenName={'Settings'} component={<Settings />}/>
	},
	{
		path: "/pubinfo",
		element: <Page title="Pub info" screenName={'PubInfo'} component={<PubInfo />}/>
	},
	{
		path: "/users",
		element: <Page title="My pub users" screenName={'Users'} component={<Users />}/>
	},
	{
		path: "/users/new",
		element: <Page title="New pub user" screenName={'UserForm'} component={<UserForm />}/>
	},
	{
		path: "/users/:uid",
		element: <Page title="Edit pub user" screenName={'UserForm'} component={<UserForm />}/>
	},
	{
		path: "/feedback",
		element: <Page title="Get in touch with HEINEKEN" screenName={'Feedback'} component={<Feedback />}/>
	},
	{
		path: "/contact",
		element: <Page title="Get in touch with HEINEKEN" screenName={'Request'} component={<Request />}/>
	},
	{
		path: "/social",
		element: <Page title="Content for you to use" screenName={'SocialMenu'} component={<SocialMenu />}/>
	},
]);


export default function App() {
	return (
		<Provider store={store}>
			<RouterProvider router={router} />

			<PopUpMessage />
		</Provider>
	);
}