import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { AppService as App } from 'smartpub-common/services/app.js';
import { Header } from './Header';


const Page = ({ title, subTitle, showHeader, showBanner, component, componentProps, screenName }) => {
    let location = useLocation();
    const navigate = useNavigate();

    const [renderableComponent, setRenderableComponent] = useState(null);

    const [pageTitle, setPageTitle] = useState(title);

    useEffect(() => {
        const pathname = (location.pathname !== "/" ? (location.pathname[location.pathname.length - 1] === "/" ? location.pathname.substr(1, location.pathname.length - 2) : location.pathname.substr(1, location.pathname.length)) : location.pathname);
    
        if (!App.loaded && pathname !== "/") {
            navigate("/", {
                replace: true,
                state: {
                    redirect: location.pathname,
                    pathname: location.search,
                    params: location.state?.params ?? location.search
                }
            });
    
            return undefined;
        }
    
        // Have we chosen a pub?
        if (pathname !== 'pub' && typeof App.user !== 'undefined' && App.user && App.user.details && !App.user.details.pub) {
            navigate( "/pub", {
                replace: true,
                state: {
                    user: App.user
                }
            });
        } else {        
            // Send analytics
            if (App.analytics && component) {
                App.analytics.setScreen(screenName);
            }
            
            // Clear searchTerm after logging event
            App.searchTerm = null;
    

            // Add CCE CHAT widget
            if (App.user?.details) {
                document.body.removeEventListener("click", recordGaTapCceChat);
                document.body.addEventListener("click", recordGaTapCceChat);
            }

        }
    
        if (title) {
            setPageTitle(title);
        }
    }, [location.pathname]);

    useEffect(() => {
        setRenderableComponent(null);

        if (typeof component === "function") {
            console.log(componentProps);
            setRenderableComponent(React.createElement(component, {...componentProps, setPageTitle}));
        } else {
            setRenderableComponent(component);
        }
    }, [component, componentProps]);

    const recordGaTapCceChat = (e) => {
        if (e.target.classList.contains("chat-button")) {
            App.analytics.logEvent('tap_chat', {'page': document.URL});
        }
    }

    if (!renderableComponent) return;

    return (
        <div>
            <div id="page-wrapper" className={"" + (showHeader === false ? null : " has-header")}>
                {
                    showHeader === false ?
                        null
                    :
                        <Header />
                }
                
                {
                    showBanner === false ?
                        null
                    :
                        <div className="banner">
                            <div className={"ratio-container ratio-container--" + location.pathname.replace('/', '') }>
                                <h3 id="page-title">{ pageTitle }{ subTitle ? <span className="page-sub-title">{ subTitle }</span> : null }</h3>
                            </div>
                        </div>
                }
                
                { renderableComponent }
            </div>
        </div>
    );
}

export default Page;