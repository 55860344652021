import { AppService as App } from './app.js';
import { ApiService as Api } from './api.js';
import { NotificationsService as Notifications } from './notifications';
import Restart from 'react-native-restart';
import { Alert } from 'react-native';

export class UserService { 
    setup() {
        App.initUpdate('User details', 0);
        var loadUser = this._loadUser().then(()=> {
            if (this.isLoggedIn() && App.platform === 'web') {
                this._loadCCEChat().then((data)=> {
                // Init CCE chat
                    const key = data.chat ? data.chat.cce : '';
                    const input = document.getElementById('DataEnghouse');
                    input.value = key;
                    setTimeout(() => {
                        window.cceChat?.setVariables({
                            "origin": "HeinekenIreland",
                            "brand": "SmartPub"
                        })
                    }, 2000);
                });
            }

        });

        return loadUser;
    }

    isLoggedIn() {
        return !!this.details;
    }

    login(email, password) {
        if (this.isLoggedIn()) {
            return false;
        }

        var data = {
            email: email,
            password: password
        };

        return App.api.request("login", data, "POST").then((data) => {
            var userData = data.user;
            userData.access_token = data.access_token;
            userData.pubs = data.pubs;

            if (!data.pubs || !data.pubs.length) {
                throw "You are not assigned to pub";
                return;
            }

            this.details = userData;

            if(this.details.pubs.length === 1) {
                const pub = this.details.pubs[0];
                this.setPub(pub.id, pub.hil_payer_no);
            }

            // Store user details in cache
            App.cache.set("user", userData);

            App.api.setKey(userData.access_token);

            // Setup user
            this.setup();

            // Send event to analytics
            App.analytics.loggedIn();
            Notifications.setup();

            return data;
        });
    }

    logout() {
        App.analytics.logEvent('logout');
        App.cache.clearAll().then((error) => {
            if (App.platform === 'web') {
                App.navigation.replace("/");
            } else {
                Restart.Restart();
            }
        });
    }

    resetPassword(email, password, confirmPassword, token) {
        if(!token) {
            Alert.alert("Password and Password confirm fields don't match. Please check and try again");
            return;
        }   

        var data = {
            email,
            password,
            confirmPassword,
            token
        };

        return App.api.request("resetPassword", data, "POST").then(() => {
            App.analytics.logEvent('reset_password');
        });
    }

    request(email) {
        if (this.isLoggedIn()) {
            return false;
        }

        var data = {
            email: email,
            platform: App.platform
        };

        return App.api.request("requestPassword", data, "POST").then((data) => {
            return data;
        });
    }

    update(data) {
        Object.keys(data).forEach((key) => {
            this.details[key] = data[key];
        });

        var updatedResponse = Api.request("user", data, "PATCH");
        if(this.rerenderKnownAs) this.rerenderKnownAs();
        return updatedResponse;
    }

    hasAcceptedTerms() {
        return new Promise((resolve, reject) => {
            return App.api.request("terms").then(data => {
                if (data.accepted) {
                    this.details.acceptedTerms = true;
                    this.save();
                    resolve();
                } else {
                    this.details.acceptedTerms = false;
                    this.save();
                    reject(data.terms);
                }
            }, error => {
                if (this.details.acceptedTerms) {
                    resolve();
                }
            })
        });
    }

    acceptTerms() {
        return App.api.request("terms/accept", null, "POST").then(data => {
            this.details.acceptedTerms = true;
            this.save();
        });
    }

    save() {
        App.cache.set("user", this.details);
    }

    hasPermission(name) {
        var pub = this.getPub();

        if (!pub) return;

        return pub.permissions.indexOf(name.toLowerCase()) !== -1;
    }

    setPub(id, hil_payer_no){
        return new Promise((resolve, reject) => {
            if(id) {
                App.api.setPub(id);
                this.details.pub = id;
                this.details.hil_payer_no = hil_payer_no;

                App.cache.clearAll().then((error) => {
                    if (error) {
                        reject();
                    }

                    App.cache.set("user", this.details).then(() => { resolve(id); }, () => { reject() });
                });
            } else {
                reject();
            }
        });
    }

    setFilterPreferences(filter, screen = null) {
        
        if (filter.sort == 'favourites') filter.sort = 'category';
        var supplierFilter, categoryFilter = 'all';
        if (filter.filter !== 'all') {
            supplierFilter = App.suppliers.get(filter.filter).name;
        }

        if (filter.categoryFilter !== 'all') {
            categoryFilter = App.products.categories.get(filter.categoryFilter).name;
        }

        if (screen !== 'order') screen = 'count';
        App.analytics.logEvent('select_filter_' + screen, {'filter_type': filter.sort, 'filter_supplier': supplierFilter, 'filter_category': categoryFilter });
        this.details.filter = filter;
    }

    /*setFilterPreferences(options) {
        this.details.filter = options;

        this.filterPreferencesSet = true;
        //App.cache.set("user", this.details, () => { });
    }*/

    isSetFilterPreferences() {
        return this.filterPreferencesSet || false;
    }

    getFilterPreferences() {
        return this.details.filter || {
            filter: 'all',
            categoryFilter: 'all',
            sort: 'favourites'
        };
    }

    /*getFilterPreferences() {
        if (this.details.filter) {
            return this.details.filter;
        }

        return {
            filter: 'all',
            categoryFilter: 'all',
            sort: 'favourites'
        }
    }*/

    resetFilterPreferences() {
        this.details.filter = 'all';
        this.details.sort = 'favourites';

        this.filterPreferencesSet = false;
    }

    getPubs() {
        return this.details ? this.details.pubs : [];
    }

    getPub(pubId = null) {
        if (!this.details) return null;
        if (!pubId) {
            pubId = this.details.pub; // If no id is provided use current pub
        }
        var index = this.details.pubs.findIndex(item => item["id"] === pubId);

        if (index >= 0) {
            return this.details.pubs[index];
        }
    }

    pubIsSet(){
        return this.details && this.details.pub ? this.details.pub : false;
    }

    isInactive(){
        return this.details && this.details.inactive ? this.details.inactive : false;
    }

    setInactive(){
        this.details.inactive = true;

        App.cache.set("user", this.details, () => {});
    }

    _loadCCEChat() {
        return App.api.request("user/ccechat");
    }

    _loadUser() {
        return App.cache.get("user").then((details) => {
            if (details) {
                this.details = details;
                this.ID = details.id;

                App.api.setKey(details.access_token);

                if(details.inactive) resolve();

                App.api.request("user").then((data) => {
                    var userData = data.user;
                    if (this.details.delivery_note) {
                        userData.delivery_note = this.details.delivery_note;
                    }
                    if (this.details.acceptedTerms) {
                        userData.acceptedTerms = this.details.acceptedTerms;
                    }

                    userData.access_token = details.access_token;
                    userData.pubs = data.pubs;
                    userData.pub = details.pub;

                    this.details = userData;
                    App.cache.set("user", userData);

                    if (!this.details.pubs || !this.details.pubs.length) {
                        this.logout();

                        throw "User not assigned to pub";
                    }

                    if (this.details.pubs.length == 1) {
                        this.details.pub = this.details.pubs[0].id;
                    }

                    var selectedPub = this.details.pubs.find(item => item.id === this.details.pub);
                    if (!selectedPub && this.details.pub) {
                        throw "User is inactive";
                    }

                    if (this.details.pub) {
                        this.setPub(this.details.pub, selectedPub.hil_payer_no);
                    }
                    
                    Notifications.setup();
                    
                    App.initUpdate('User details', 1);

                    App.analytics.setUser(this.details.id, this.details.hil_payer_no);

                    return this.details;
                }, async (e) => {
                    if (!this.details.pubs || !this.details.pubs.length) {
                        throw Error("User not assigned to pub");
                    }

                    if (this.details.pubs.length == 1) {
                        this.setPub(this.details.pubs[0].id, this.details.pubs[0].hil_payer_no);
                    }

                    App.initUpdate('User details', 2);

                    return this.details;
                });
            } else {
                throw null;
            }
        });
    }
}