import React from 'react';

import Button from '../../includes/Button';

import { HistoryReportingService as HistoryReporting } from "smartpub-common/services/historyReporting";
import DatePicker from "react-datepicker";
import { Utils } from "smartpub-common/services/utils";
import { ActivityIndicator } from 'react-native';

import { VictoryLine, VictoryAxis, VictoryChart } from 'victory';
import VictoryTheme from 'smartpub-common/assets/victoryTheme';

import { store } from 'smartpub-common/redux/default';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';

export default class HistoryReport extends React.Component {
    constructor(props) {
        super(props);

        this.reporting = new HistoryReporting()

        this.state = {
            loaded: false,
            suppliers: [],
            products: [],
            filter: {},
            chartWidth: 0
        }

        this.filter = this.filter.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this.reporting.load().then((data) => {
            this.setState({
                loaded: true
            });
        }).then(() => {
            let filters = this.reporting.getFilters(),
                report = this.reporting.getReport({});

            this.setState({
                suppliers: filters.suppliers.map(id => {
                    const supplier = supplierSelectors.getById(store.getState(), id);

                    return {
                        label: supplier.name,
                        value: supplier.supplier_id
                    }
                }),
                products: filters.products.map(id => {
                    const product = productSelectors.getById(store.getState(), id);

                    return {
                        label: product.name,
                        value: product.id
                    }
                }),
                report: report
            })
        });
    }

    filter(key, value) {
        if (key) {
            let params = this.state.filter;
            if (value) {
                params[key] = value;
            } else {
                delete params[key];
            }

            this.setState({
                filter: params
            });
        }

        let report = this.reporting.getReport(this.state.filter);

        this.setState({
            report: report
        })
    }

    download() {
        this.reporting.download(this.state.filter);
    }


    renderReport() {
        return (
            <div className="container">
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, padding: '0 1em 1em'}}>
                        <h4 style={{fontSize: 18, color: "#ffffff", marginBottom: 5}}>Product</h4>
                        <select onChange={({ target }) => this.filter("product_id", parseInt(target.value))}>
                            <option value="0">Filter by product...</option>
                            {this.state.products.map(({ value, label }, index) => <option key={value} value={value}>{label}</option>)}
                        </select>
                    </div>
                    <div style={{flex: 1, padding: '0 1em 1em'}}>
                        <h4 style={{fontSize: 18, color: "#ffffff", marginBottom: 5}}>Supplier</h4>
                        <select onChange={({ target }) => this.filter("supplier_id", parseInt(target.value))}>
                            <option value="0">Filter by supplier...</option>
                            {this.state.suppliers.map(({ value, label }, index) => <option key={value} value={value}>{label}</option>)}
                        </select>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, padding: '0 1em 1em'}}>
                        <h4 style={{fontSize: 18, color: "#ffffff", marginBottom: 5}}>From</h4>
                        <DatePicker
                            selected={this.state.filter.from}
                            onChange={date => {
                                date.setHours(0,0,0,0);
                                this.filter("from", date);
                            }}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div style={{flex: 1, padding: '0 1em 1em'}}>
                        <h4 style={{fontSize: 18, color: "#ffffff", marginBottom: 5}}>To</h4>
                        <DatePicker
                            selected={this.state.filter.to}
                            onChange={date => {
                                date.setHours(23,59,59,0);
                                this.filter("to", date);
                            }}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>

                {
                    this.state.report ?
                        <React.Fragment>
                            <p>Orders: { this.state.report.orders.length }</p>
                            <p>Lines: { this.state.report.lines.length }</p>
                            <p>Suppliers: { this.state.report.suppliers.length }</p>
                            <p>Products: { this.state.report.products.length }</p>

                            { this.renderChart() }
                        </React.Fragment>
                        : null
                }
                <div style={{maxWidth: 300, margin: '1em auto'}}>
                    <Button
                        disabled={!this.state.report || !this.state.report.orders || !this.state.report.orders.length}
                        theme="primary"
                        title="Download"
                        onClick={() => this.download() }
                        style={{marginLeft: 5}}
                    />
                </div>
            </div>
        );
    }

    renderChart() {
        if (!this.state.report.orders || !this.state.report.orders.length) return null;

        return (
            <React.Fragment>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <h5>Order quantity, par and stock over time</h5>
                    <div style={{height: 300, maxWidth: 650}}>
                        <VictoryChart theme={VictoryTheme} padding={{ left: 40, top: 5, right: 0, bottom: 55}}>
                            <VictoryAxis
                                dependentAxis={true}
                                style={{
                                    grid: { stroke: "#333" }
                                }}
                                tickFormat={(t) => Math.round(t)}
                            />
                            <VictoryAxis
                                tickFormat={(x) => Utils.getDateString(x)}
                                style={{
                                    grid: { stroke: "transparent" }
                                }}
                                fixLabelOverlap={true}
                                height={500}
                            />

                            <VictoryLine
                                data={this.state.report.orders}
                                x={(item) => item.date}
                                y="qty"
                                style={{
                                    data: { stroke: "#9BBB59" }
                                }}
                            />
                            <VictoryLine
                                data={this.state.report.orders}
                                x={(item) => item.date}
                                y="stock"
                                style={{
                                    data: { stroke: "#C0504D" }
                                }}
                            />
                            <VictoryLine
                                data={this.state.report.orders}
                                x={(item) => item.date}
                                y="par"
                                style={{
                                    data: { stroke: "#5081BD" }
                                }}
                            />
                        </VictoryChart>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-evenly"}}>
                    <div style={{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
                        <div style={{ width: 8, height: 8, backgroundColor: "#9BBB59", marginRight: 6 }} />
                        <span style={{fontSize: 12, color: "#ffffff"}}>Quantity</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
                        <div style={{ width: 8, height: 8, backgroundColor: "#C0504D", marginRight: 6 }} />
                        <span style={{fontSize: 12, color: "#ffffff"}}>Stock</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
                        <div style={{ width: 8, height: 8, backgroundColor: "#5081BD", marginRight: 6 }} />
                        <span style={{fontSize: 12, color: "#ffffff"}}>Par</span>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderLoading() {
        return (
            <div className="container">
                <div className="loader">
                    <ActivityIndicator size="large" color="#ffffff" />
                    <h4 className="text-center">Loading order history</h4>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.loaded) {
            return this.renderReport();
        } else {
            return this.renderLoading();
        }
    }
}