import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { ApiService as Api } from '../../services/api';
import _ from "lodash";

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    loading: 'idle',
    items: []
}

export const fetch = createAsyncThunk('categories/fetch', async () => await Api.request('categories'));

const slice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
                App.initUpdate('Product categories', 0);
            }
        });

        builder.addCase(fetch.fulfilled, (state, { meta, payload }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.items = payload.map((category) => {
                var name = (category.name.toLowerCase() == "keg") ? "Draught" : category.name,
                    image = category.placeholder_image;

                if (category.children) {
                    var children = category.children.map((child) => {
                        child.image = image;
    
                        return child;
                    });
                }

                return {
                    id: category.id,
                    name: name,
                    image: image,
                    children: children
                }
            });

            state.loading = 'idle'
            state.currentRequestId = undefined

            App.initUpdate('Product categories', 1);
            App.cache.set("categories", state.items);
        });

        builder.addCase(fetch.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.loading = 'idle'
            state.currentRequestId = undefined

            console.error("Unable to load categories");
            App.initUpdate('Product categories', 2);
            
            // Load from cache as offline
            App.cache.get("categories").then((items) => {
                state.items = items;
            });
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;

const getById = createMapSelector([
    state => state.categories.items,
    (state, id) => parseInt(id),
], (categories, id) => {
    // Flatten categories and children
    const flattenData = categories.reduce((newArr, category) => {
        newArr.push(category);
        return newArr.concat(category.children || [])
    }, []);

    return flattenData.find(product => product.id === id);
});

export const selectors = {
    getById: getById,
    getChildren: createMapSelector([
        state => state.categories.items,
        (state, parentId = 0) => parseInt(parentId),
    ], (categories, parentId) => {
        if (!parentId) {
            return categories;
        }

        const parent = _.find(categories, { 'id': parentId });
        return parent ? parent.children : null;
    }),

    getParent: createMapSelector([
        state => state.categories.items,
        (state, child) => child,
    ], (categories, child) => {
        if (!child){
            return categories;
        }

        var result = categories.filter(category => {
            return category.children.filter(subcat => {
                return (subcat.id === child);
            }).length;
        });

        return result[0].id;
    })
};
