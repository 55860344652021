import React from 'react';

import LoginLayout from '../includes/LoginLayout';
import EazleLogo from '../../assets/icons/Eazle_Logo.png';
import Genie from '../../assets/icons/Genie-big.svg';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Update() {
    const navigate = useNavigate();
    const location = useLocation();

    return <UpdateClass location={location} navigate={navigate} />
}


class UpdateClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: this.props.location.state ? this.props.location.state : null
        }
    }

    render(){
        var content = (
            <div className="form">
                <img src={EazleLogo} alt="Eazle logo" id="logo" />

                { this.state ?
                    <div className="block block--next-delivery block--update">
                        <img src={Genie} className='genie' />
                        <h4>{this.state.status.title}</h4>
                        <p>{this.state.status.message}</p>
                    </div>
                : null }
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}