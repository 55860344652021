import React from 'react';

import Button  from '../includes/Button';
import { FeedbackService } from 'smartpub-common/services/feedback.js';

import { showMessage } from '../facades/showMessage.js';

export default class Request extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            comment: null,
            request: null,
            stars: null,
            submitted: false,
            loading: false,
            requestType: null
        }

        this.submitRequest = this.submitRequest.bind(this);
        this.handleRequestChange = this.handleRequestChange.bind(this);
    }

    submitRequest() {
        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        });

        FeedbackService.submit(this.state.comment, this.state.request, this.state.stars, this.state.requestType).then((data) => {
            this.setState({
                submitted: true
            });

            setTimeout(() => {
                this.setState({ submitted: false, comment: null, stars: null, requestType: null, request: null });
            }, 2000);
        }, (error) => {
            showMessage({
                title: error.message ? error.message : "Error submitting feedback",
                message: error.description ? error.description : error,
                error: true,
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    handleRequestChange(event) {
        var value = event.target.value;
        if (value == 0) {
            value = false;
        }

        this.setState({requestType: value});
    }

    render() {
        let requestText = this.state.requestType ? this.state.requestType[0].toUpperCase() + this.state.requestType.substring(1, this.state.requestType.length) : '';

        return (
            <div id="feedback">
                <div className="container row">
                    <div className="col-md-12">
                    {
                        this.state.submitted ?
                            <>
                                <h5 className="text-center">
                                    Thank you for your query
                                </h5>
                                <p className="text-center">In order to deal with your query, a member of the team may need to contact you during office hours.</p>
                            </>
                        :
                            <React.Fragment>
                                <h4>Customer service number: 0818 51 44 55</h4>

                                <select value={this.state.requestType} onChange={this.handleRequestChange}>
                                    <option value="0">Submit a query</option>
                                    <option value="order">Order query</option>
                                    <option value="financial">Financial query</option>
                                    <option value="other">Other query</option>
                                </select>

                                {
                                    this.state.requestType ?
                                        <>
                                            <label>Details</label>
                                            <textarea
                                                id="request-text"
                                                placeholder={"Enter your query details here"}
                                                onChange={(e) => this.setState({request: e.target.value})}
                                            >{this.state.request}</textarea>

                                            <Button
                                                title="Submit"
                                                theme="light"
                                                textColor="green"
                                                loading={this.state.loading}
                                                onClick={() => this.submitRequest()}
                                            />
                                        </>
                                    : null
                                }

                            </React.Fragment>
                    }
                    </div>
                </div>
            </div>
        );
    }
}