import React, { useState } from 'react';
import { PopUpReminder } from '../includes/PopUpReminder.js';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../includes/Button.js';
import { ReminderList } from '../includes/ReminderList';
import { showMessage } from "../facades/showMessage";

import { create as createReminderThunk } from 'smartpub-common/redux/slices/reminders';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import filterIcon from '#assets/icons/filter-button.svg';

export default function Reminders() {
    const [filter, setFilter] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [historical, setHistorical] = useState(false);
    const [historicalDrop, setHistoricalDrop] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const clearFilter = () => {
        setHistorical(false);
    }

    const hidePopup = () => {
        setShowPopup(false);
        setLoading(false);
    }

    const createReminder = (reminder) => {
        if (loading) return false;

        setLoading(true);

        return dispatch(createReminderThunk(reminder)).unwrap().then(() => {
            showMessage({
                message: "Reminder created",
                description: reminder.title + " created",
                backgroundColor: "#1b9d54"
            });

            hidePopup();
        }, (e) => {
            console.error(e);
            alert("Reminder could not be created", e.message);

            //Alert.alert("Error creating reminder", e);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div id="reminders">
            <div className="container">
                <div className="row reminders-title">
                    <h4 className="title">{ historical ? "Past" : "Upcoming" } reminders</h4>

                    <div className={"dropdown" + (historicalDrop ? " show" : "")}>
                        <Button
                            theme="blank"
                            title=" "
                            icon={<img src={filterIcon} />}
                            onClick={() => setHistoricalDrop(!historicalDrop)}
                        />
                        <div className={"dropdown-menu dropdown-menu-right " + (historicalDrop ? "show" : "")}>
                            <button
                                className={"dropdown-item" + (!historical ? " active" : "")}
                                onClick={() => {
                                    setHistorical(false);
                                    setHistoricalDrop(false);
                                }}
                            >
                                Upcoming reminder
                            </button>
                            <button
                                className={"dropdown-item" + (historical ? " active" : "")}
                                onClick={() => {
                                    setHistorical(true);
                                    setHistoricalDrop(false);
                                }}
                            >
                                Past reminders
                            </button>
                        </div>
                    </div>
                    <div className='new-reminder'>
                        <Button
                            permission="reminder"
                            title="New reminder"
                            style={{flex: 0}}
                            theme='primary'
                            onClick={() => setShowPopup(true)}
                        />
                    </div>
                </div>

                <div className="reminders-type">
                    
                    <Button
                        title="All"
                        theme={!filter ? 'primary' : 'secondary'}
                        onClick={() => setFilter(false)}
                    />
                
                    <Button
                        title="My pub"
                        theme={filter == 'mypub' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('mypub')}
                    />
                
                    <Button
                        title="eazle"
                        theme={filter == 'smartpub' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('smartpub')}
                    />
                    
                </div>

                <div className="row">
                    <ReminderList
                        fullView={true}
                        filter={filter}
                        changeHistoricalFilter={setHistorical}
                        historical={historical}
                    />
                </div>

                <div className="row justify-content-md-center mt-4">
                    <div className="col-4">

                        {
                            <PopUpReminder
                                modalBtn="Create"
                                modalTitle="New reminder"
                                onSubmit={createReminder}
                                onDismiss={hidePopup}
                                isVisible={showPopup}
                                loading={loading}
                                filter={clearFilter}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}