import React, { useMemo, useState } from "react";
import { createPortal } from 'react-dom';
import Button from "../Button.js";
import { AppService as App } from "smartpub-common/services/app";

import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions, selectors as orderSelectors, create as createOrder } from 'smartpub-common/redux/slices/order';
import { store } from 'smartpub-common/redux/default';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { selectors as categorySelectors } from 'smartpub-common/redux/slices/categories';

import closeIcon from '#assets/icons/close.svg';

import _ from 'lodash';

const SelectSortFilter = ({ setFilter }) => {
    const currentSort = useSelector(orderSelectors.getSort);

    let currentFilter;
    switch (currentSort) {
        case 'supplier': currentFilter = 'Supplier'; break;
        case 'stock': currentFilter = 'Stock sequence'; break;
    }

    const items = [
        { label: 'Supplier', value: 'supplier' },
        { label: 'Stock sequence', value: 'stock' }
    ];

    return <>
        <label>Sort by</label>

        <select
            onChange={({target}) => {
                App.analytics.logEvent('tap_filter_order');
                setFilter(target.value);
        }}>
            {items.map(({ value, label}) => <option key={value} value={value} selected={currentSort === value}>{label}</option>)}
        </select>
    </>
}

const SelectSupplierFilter = ({ setFilter }) => {    const items = useSelector(state => orderSelectors.getItems(state, false, false));
    const currentSupplierId = useSelector(orderSelectors.getFilterSupplier);
    const currentSupplier = useSelector(state => supplierSelectors.getById(state, currentSupplierId));

    const suppliers = useMemo(() =>
        _.chain(items)
            .groupBy('supplier_id')
            .map((value, key) => ({
                value: parseInt(key),
                label: supplierSelectors.getById(store.getState(), parseInt(key))?.name,
            }))
            .reject({ label: undefined })
            .value()
    , [items]);

    let currentFilter = 'All';
    if (currentSupplier) {
        currentFilter = currentSupplier.name;
    }

    return <>
        <label>Filter by supplier</label>

        <select 
            defaultValue={currentSupplierId}
            onChange={({target}) => {
                App.analytics.logEvent('tap_filter_order');
                setFilter(target.value);
            }}
        >
            <option value="">All</option>
            {suppliers.map(({ value, label}) => <option key={value} value={value}>{label}</option>)}
        </select>
    </>
}

const SelectCategoryFilter = ({ setFilter }) => {
    const items = useSelector(state => orderSelectors.getItems(state, false, false));
    const currentCategoryId = useSelector(orderSelectors.getFilterCategory);
    const currentCategory = useSelector(state => categorySelectors.getById(state, currentCategoryId));
    
    const categories = useMemo(() => {
        return _.chain(items)
            .map((value, key) => ({
                ...value,
                category_id: productSelectors.getById(store.getState(), value.product_id).category_id
            }))
            .groupBy('category_id')
            .map((value, key) => {
                return {
                    value: parseInt(key),
                    label: categorySelectors.getById(store.getState(), parseInt(key))?.name
                }
            })
            .reject({ label: undefined })
            .value()
    }, [items]);

    let currentFilter = 'All';
    if (currentCategory) {
        currentFilter = currentCategory.name;
    }

    return <>
        <label>Filter by category</label>
        <select
            defaultValue={currentCategoryId}
            onChange={({target}) => {
                App.analytics.logEvent('tap_filter_order');
                setFilter(target.value);
        }}>
            <option value="">All</option>
            {categories.map(({ value, label}) => <option key={value} value={value}>{label}</option>)}
        </select>
    </>
}

export const PopUpFilterSort = ({ visible = false, loading, onDismiss }) => {
    const dispatch = useDispatch();

    const [sortFilter, setSortFilter] = useState();
    const [supplierFilter, setSupplierFilter] = useState();
    const [categoryFilter, setCategoryFilter] = useState();
    const currentCategoryId = useSelector(orderSelectors.getFilterCategory);
    const currentSupplierId = useSelector(orderSelectors.getFilterSupplier);

    if (!visible) return;
    
    const saveFilters = () => {
        dispatch(orderActions.setSort(sortFilter));

        if (supplierFilter != currentSupplierId || categoryFilter != currentCategoryId) {
            var alertTitle = "Update order";
            var alertContent = "All products that you have amended/added will be removed and reset. Are you sure you would like to update order?";
    
            var conf = window.confirm(alertTitle +"\n"+ alertContent);
    
            if(conf) {
                dispatch(createOrder('reset'));
                dispatch(orderActions.setFilterSupplier(supplierFilter));
                dispatch(orderActions.setFilterCategory(categoryFilter));
            } else {
                onDismiss();
            }
        }
        
    }

    return createPortal(
        <div className="popup popup--select-supplier">
            <div id="popup-filter" onClick={onDismiss}> </div>

            <div id="popup-box">
                <h5 className="text-center">
                Filter & Sort
                    <div id="popup-close" onClick={onDismiss}>
                        <img src={closeIcon} alt="close" />
                    </div>
                </h5>
                <SelectSortFilter setFilter={setSortFilter} />
                <SelectSupplierFilter setFilter={setSupplierFilter} />
                <SelectCategoryFilter setFilter={setCategoryFilter} />
                <Button
                    loading={loading}
                    theme="primary"
                    title="Update Order"
                    onClick={() => {
                        saveFilters();
                        onDismiss()
                    }}
                /> 
            </div>
        </div>
    , document.getElementById('root'));
};