import React from 'react';
import Button from '../includes/Button';
import { AppService as App } from 'smartpub-common/services/app.js';
import {
    ActivityIndicator
} from 'react-native';
import { useNavigate, useLocation } from 'react-router-dom';
import { showMessage } from '../facades/showMessage';

export default function UserForm({item, onUpdate, loading}) {
    const navigate = useNavigate();
    const location = useLocation();

    return <UserFormClass navigate={navigate} location={location} />
}

class UserFormClass extends React.Component {
    constructor(props) {
        super(props);

        var action = props.location.state && props.location.state.action ? props.location.state.action : "new",
            user = props.location.state && props.location.state.user ? props.location.state.user : null;

        //If page was refreshed or directly accessed
        var path = window.location.pathname;
        var matches = path.match(/users\/(\d+)/i);

        if(matches) {
            var userID = matches[1];
            action = "edit";
            user = App.pub.users.get(userID);
        }

        this.state = {
            action,
            user,
            savingNew: false,
            loading: [],
            permissionLoading: false,
            checked: false,
            permissions: [{
                level: "admin",
                title: "Pub user administration",
                category: "Set-up admin",
                active: false
            }, {
                level: "reminder",
                title: "Reminders admin",
                category: "Set-up admin",
                active: false
            }, {
                level: "stock_favourites",
                title: "Stock count, favourites, suppliers & pars",
                category: "Stock management",
                active: false
            }, {
                level: "history",
                title: "Order and stock history",
                category: "History & Finance",
                active: false
            }, {
                level: "order",
                category: "Stock management",
                active: false
            }, {
                level: "financial",
                category: "History & Finance",
                title: "HEINEKEN financial info",
                active: false
            }, {
                level: "statements",
                category: "History & Finance",
                title: "HEINEKEN statements",
                active: false
            }]
        }

        var permission_groups = [];

        this.state.permissions.forEach((permission) => {
            //Check if group already exists
            var group_index = permission_groups.findIndex((group) => group.title == permission.category);

            //If it doesn't, add it
            if(group_index < 0) permission_groups.push({title: permission.category, items: []});
            //Update the index value, since it now exists, in order to add to it's properties
            group_index = permission_groups.findIndex((group) => group.title == permission.category);

            permission_groups[group_index].items.push(permission);
        });

        this.state.permissions = permission_groups;

        if (this.state.action == "edit") {
            if (this.state.user) {
                this.state.user.new = false;
            } else {
                this.state.user = {new: true, permissions: [], name: '', email: ''};
            }
        } else {
            this.state.user = {new: true, permissions: [], name: '', email: ''};
        }

        this.save = this.save.bind(this);
        this.update = this.update.bind(this);
        this.handler = this.handler.bind(this);
        this.setPermission = this.setPermission.bind(this);
    }

    update(userID, permissions = null, active = null){
        // Check if we are in the middle of an operation on this item
        if (this.state.loading.indexOf(userID) > -1) {
            return;
        }

        // Add item to the list of loading
        var newLoading = this.state.loading.concat(userID);
        this.setState({ loading: newLoading });

        return App.pub.users.update(userID, permissions, active).then(() => {
            showMessage({
                title: 'Edit user',
                message: App.pub.users.get(userID).name + (active ? " updated" : " deactivated")
            });

            this.props.navigate('/users');
        }, (error) => {
            alert("Error updating user\n\nPlease try again");
            throw error;
        });
    }

    save(details) {
        var validationExp = /\S+@\S+\.\S+/gi;
        if(!validationExp.test(details.email)) {
            alert("Invalid email address format\n\nPlease check and try again");
            return;
        }

        // Check if the user already exists in the same pub
        if(App.pub.users.get(details.email, "email")) {
            alert("User already exists\n\nThe pub user "+ details.email +" already exists");
            return;
        }

        // Check if we are in the middle of an operation on this item
        if (this.state.savingNew) {
            return;
        }
        this.setState({ savingNew: true });

        return App.pub.users.save(details).then(() => {
            showMessage({
                title: 'New user',
                message: details.name + " created"
            });

            this.props.navigate('/users');
        }, (error) => {
            alert("Error creating user\n\nPlease try again");
            throw error;
        });
    }

    setPermission(permission) {
        if(this.state.permissionLoading) return;

        this.state.permissionLoading = true;

        var index = this.state.user.permissions.indexOf(permission),
            found = index != -1;

        if (!found) {
            this.state.user.permissions.push(permission);

            var perms = this.state.permissions.map(item => {
                if (item.level == permission) {
                    item.active = true;
                }

                return item;
            });

            this.setState({
                permissions: perms,
                permissionLoading: false
            })

            return;
        }

        var perms = this.state.permissions;
        
        perms = perms.map((item) => {
            if(item.level == permission) item.active = !item.active;

            return item;
        });

        this.setState({
            permissions: perms,
            permissionLoading: false
        });

        this.state.user.permissions.splice(index, 1);
    }

    handler() {
        var user = this.state.user;
        if(!user.new) {
            this.update(this.state.user.id, this.state.user.permissions, this.state.user.is_active);
            return;
        } else {
            if(this.state.checked && this.state.user.email && this.state.user.name && this.state.user.permissions.length) {
                this.save(this.state.user);
                return;
            }
        }
    }

    render(){
        var user = this.state.user;

        var disableSave = true;
        // Enable save button if editing user or all details are filled out
        if (!user.new || (this.state.checked && this.state.user.email && this.state.user.name && this.state.user.permissions.length)) {
            disableSave = false;
        }

        var permissions = this.state.permissions.map((category, ci) => {
            var permission_items = category.items.map((permission, pi) => {
                var active = user.permissions && permission ? user.permissions.indexOf(permission.level) != -1 : false;
                permission.active = active;

                var permissionName;

                if(permission.title) {
                    permissionName =  permission.title;
                } else {
                    permissionName = permission.level[0].toUpperCase() + permission.level.slice(1);
                    permissionName = permissionName.replace("_", " ");
                }

                var activePerms = active ? 'user-permissions user-permissions--active' : 'user-permissions';

                return (
                    <div className={"permission-category"+ (active ? " active" : "")}>
                        <li key={"permission-"+ ci +"-"+ pi} className={activePerms} onClick={() => this.setPermission(permission.level)}><span className="permission-name">{permissionName}</span>
                        {
                            this.state.permissionLoading ?
                                <ActivityIndicator size="small" color="#ffffff" />
                            :
                                <div className='checkbox-container'>
                                    <input
                                        type="checkbox"
                                        checkedColor="#33CC66"
                                        uncheckedColor="#33CC66"
                                        checked={active}
                                        onClick={() => {this.setPermission(permission.level)}}
                                    />
                                    <span className="checkmark"></span>
                                </div>
                        }
                        </li>
                    </div>
                )
            });

            var categories = <h5 className={ci == 0 ? 'first' : ''} onClick={() => {}}>{category.title}</h5>

            return (
                <ul className="permission-types">
                    {categories}
                    {permission_items}
                </ul>
            )
        });

        return (
            <React.Fragment>
                <div id="user-form">
                    <div className="container">
                        <div id="permissions-form">
                            <div className="label">Name</div>
                            {
                                !user.new ?
                                    <div className="data">{user.name}</div>
                                :
                                    <input
                                        autoFocus={true}
                                        defaultValue={user.name}
                                        onChange={(e) => { user.name = e.target.value; this.setState({ user: user })}}
                                        autoCompleteType="name"
                                    />
                            }

                            <div className="label">Email</div>
                            {
                                !user.new ?
                                    <div className="data">{user.email}</div>
                                :
                                    <input
                                        placeholderTextColor="#555"
                                        defaultValue={user.email}
                                        onChange={(e) => { user.email = e.target.value; this.setState({ user: user })}}
                                        autoCompleteType="email"
                                        keyboardType="email-address"
                                    />
                            }

                            <div className="label big">Permissions</div>

                            <div id="permissions">
                                {permissions}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='user-form-footer'>
                    <div className='container container--small'>
                        { user.new ?
                            (
                                <div id="confirmation">
                                    <div className='checkbox-container'>
                                        <input
                                            type="checkbox"
                                            checkedColor="#33CC66"
                                            uncheckedColor="#33CC66"
                                            checked={this.state.checked}
                                            onClick={() => {this.setState({checked: !this.state.checked})}}
                                        />
                                        <span className="checkmark"></span>
                                    </div>
                                    <p>By creating this user I confirm I am authorised and have read and understood the terms and conditions, and am aware of the permissions I am giving to this user</p>
                                </div>
                            )
                        :
                            null
                        }
                        <Button
                            theme="primary"
                            loading={this.props.loading}
                            style={{flex: 0, marginTop: 15, marginBottom: 10}}
                            title={this.state.action == "new" ? "Create" : "Update"}
                            disabled={disableSave}
                            onClick={() => this.handler()}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}