import { AppService as App } from './app.js';

export class FeedbackService {

    static submit(comment, request, rating, type = 'feedback') {
        type != null ? type : 'feedback';

        if (!comment && !request && !rating) {
            return Promise.reject({
                message: "Missing details",
                description: "Comment, query or rating is required"
            });
        }

        var data = {};
        if (rating) { data.rating = rating; }
        if (comment) { data.comment = comment; }
        if (request) { data.pub_request = request; }

        data.type = type;

        return App.api.request('feedback', data, "POST");
    }

}