import React from 'react';

import Button from '../includes/Button';
import LoginLayout from '../includes/LoginLayout';

import { AppService as App } from 'smartpub-common/services/app.js';
import { Utils } from 'smartpub-common/services/utils.js';

import visibility from "../../assets/icons/visibility.svg";
import EazleLogo from '../../assets/icons/Eazle_Logo.png';
import nextIcon from "../../assets/icons/next-back-icon-green.svg";
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginReset() {
    const navigate = useNavigate();
    const location = useLocation();

    return <LoginResetClass location={location} navigate={navigate} />
}

class LoginResetClass extends React.Component {
    constructor(props) {
        super(props)

        var email = new URLSearchParams(this.props.location.state.params).get('email');
        var token = new URLSearchParams(this.props.location.state.params).get('token');

        if(!token) this.props.navigate("/login", {state: {
            message: "You do not have a token or it has expired. Please use the reset password link from your email or request a new one."
        }});

        this.state = {
            enteredEmail: decodeURIComponent(email) ?? null,
            enteredPass: null,
            enteredPassConfirm: null,
            loading: false,
            showPassword: false,
            showPasswordConfirm: false,
            token: token
        }

        this.resetPassword = this.resetPassword.bind(this); //bind this in order to access the state inside the function
    }

    resetPassword() {
        this.setState({ loading: true });
        var token = this.state.token ?? null;

        if(!token) {
            var params = {
                message: "You do not have a token or it has expired. Please use the reset password link from your email or request a new one."
            };

            this.props.navigate("/login", {
                state: params
            });
        }

        if(!Utils.isEmail(this.state.enteredEmail)) {
            alert("Password Reset failed: Invalid email address. Please check and try again");
            this.setState({ loading: false });
            return;
        }

        if(this.state.enteredPass !== this.state.enteredPassConfirm) {
            alert("Password Reset failed: Password and Password confirm fields don't match. Please check and try again");
            this.setState({ loading: false });
            return;
        }

        App.user.resetPassword(this.state.enteredEmail, this.state.enteredPass, this.state.enteredPassConfirm, token).then(() => {
            this.setState({ loading: false });

            var params = {
                message: "Your password has been reset successfully. Please log in now."
            };

            // Redirect user to Login
            this.props.navigate("/login", {
                state: params
            });
        }, (error) => {
            console.log("Could not reset password: ", error);
            alert("Password Reset failed " +  (typeof error == "string" ? error : "Invalid credentials"));
            this.setState({ loading: false });
        });
    }

    render() {
        var content = (
            <div className="form">
                <img src={EazleLogo} alt="eazle logo" id="logo" />
                <form method="POST" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row">
                        <input
                            placeholder="Email"
                            type="text"
                            value={this.state.enteredEmail}
                            onChange={(e) => this.setState({enteredEmail: e.target.value})}
                            disabled={true}
                        />
                    </div>
                    <div className="form-group row">
                        <input
                            placeholder="Password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.enteredPass}
                            onChange={(e) => this.setState({enteredPass: e.target.value})}
                        />
                        <img src={visibility}
                            className="pw-visibility"
                            onClick={() => {this.setState({showPassword: !this.state.showPassword})}} />
                        
                    </div>
                    <div className="form-group row">
                        <input
                            placeholder="Confirm password"
                            type={this.state.showPasswordConfirm ? 'text' : 'password'}
                            value={this.state.enteredPassConfirm}
                            onChange={(e) => this.setState({enteredPassConfirm: e.target.value})}
                        />
                        <img src={visibility}
                            className="pw-visibility"
                            onClick={() => {this.setState({showPasswordConfirm: !this.state.showPasswordConfirm})}} />
                    </div>
                    <div>
                        <p style={{color: 'white'}}>Password must be at least 12 characters, and contain at least: one lowercase letter, one uppercase letter, one number and a special character (e.g. ! or @) </p>
                    </div>
                    <div className="form-group row">
                        <Button
                            text="Submit"
                            theme="primary"
                            onClick={this.resetPassword}
                            loading={this.state.loading}
                        />
                    </div>
                </form>
                <a href="#" className="text-center" onClick={() => this.props.navigate("/")} id="forgot"><img src={nextIcon} className='rotate-180' /> Back to login</a>
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}