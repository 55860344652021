import { Platform } from "react-native";

export class Utils {
    static dateUntil(date, time = true) {
        var date = new Date(date);
        var diffHours = date.getTimezoneOffset() / 60;

        var month = Utils.monthToString(date.getMonth()),
            day  = Utils.dayToString(date.getDay()) + ' ' + (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()),
            hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours(),
            minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();

        if(Platform.OS == "android") {
            if(diffHours < 0) {
                hour -= Math.abs(diffHours);
            } else if (diffHours > 0) {
                hour += Math.abs(diffHours);
            }
        }                                         

        var dateStr = day + ' ' + month,
            timeStr = hour + ':' + minute;

        // Is date today
        if (date.toDateString() === (new Date()).toDateString()) {
            dateStr = 'Today';
        }

        // Is date tomorrow
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (date.toDateString() === tomorrow.toDateString()) {
            dateStr = 'Tomorrow';
        }

        // Is date yesterday
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        if (date.toDateString() === yesterday.toDateString()) {
            dateStr = 'Yesterday';
        }

        if (hour == '00') {
            return dateStr;
        }
        if (time)
            return dateStr + ' ' + timeStr;
        else
            return dateStr;
    }

    static download(fileURL, fileName) {
        var request = new XMLHttpRequest();
        request.open('GET', fileURL, true);
        request.responseType = 'blob';
        request.onload = function() {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onloadend =  function(){
                var downloadElem = document.createElement('a');
                downloadElem.href = reader.result;
                downloadElem.download = fileName;

                downloadElem.click();
            };
        };
        request.send();
    } 

    static ucfirst(str) {
        return str[0].toUpperCase() + str.substring(1, str.length);
    }

    static sleep(ms = 2000) {
        return new Promise((res, rej) => setTimeout(() => res(true), ms));
    }

    static isEmail(str) {
        return str.match(/([0-9A-Za-z-_]+)@([0-9A-Za-z-_]+).([0-9A-Za-z-.]+)/i);
    }

    static isDate(str) {
        var regexpression = new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);
        return regexpression.test(str);
    }

    static getDateString(date, en = false, readable = false) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        var year = date.getFullYear(),
            month = date.getMonth() + 1,
            day  = date.getDate();

        if (readable) {
            return (day < 10 ? "0" + day : day) + " " + Utils.monthToString(date.getMonth()) + "  " + year.toString().substr(-2);
        }

        // Pad month and day
        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;

        if (en) {
            return day + '/' + month + '/' + year;
        } else {
            return year + '-' + month + '-' + day;
        }
    }

    static isDateBeforeToday(date) {
        return new Date(new Date(date).toDateString()) < new Date(new Date().toDateString());
    }

    static formatDateRange(start, end) {
        var one_day = ((24*60)*60)*1000,
            startStr,
            endStr;

        start = new Date(start);
        end = new Date(end);

        // Is date today
        if (start.toDateString() === (new Date()).toDateString()) {
            startStr = 'Today';
        }
        if (end.toDateString() === (new Date()).toDateString()) {
            endStr = 'Today';
        }

        // Is date tomorrow
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (start.toDateString() === tomorrow.toDateString()) {
            startStr = 'Tomorrow';
        }
        if (end.toDateString() === tomorrow.toDateString()) {
            endStr = 'Tomorrow';
        }

        // Is date yesterday
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        if (start.toDateString() === yesterday.toDateString()) {
            startStr = 'Yesterday';
        }
        if (end.toDateString() === yesterday.toDateString()) {
            endStr = 'Yesterday';
        }

        if (!endStr) {
            endStr = end.getDate() + " " + Utils.monthToString(end.getMonth()) + "  " + end.getFullYear()
        }

        // Is the event a single day?
        if (end - start < one_day) {
            return startStr ? startStr : start.getDate() + " " + Utils.monthToString(start.getMonth()) + "  " + start.getFullYear();
        } else {
            if (this.isDateBeforeToday(start) || start.toDateString() === (new Date()).toDateString()) {
                return "Until " + endStr;
            }

            if (end.getMonth() !== start.getMonth()) {
                if (!startStr) {
                    startStr = start.getDate() + " " + Utils.monthToString(start.getMonth());
                }
                return startStr + " - " + endStr;
            } else {
                if (!startStr) {
                    startStr = start.getDate();
                }
                return startStr + " - " + endStr;
            }
        }
    }

    static monthToString(month) {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        return months[month];
    }

    static dayToString(day) {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[day];
    }

    static formatCurrency(value) {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static roundFloat(value, digits = 2) {
        return (Math.round((value*Math.pow(10,digits)).toFixed(digits-1))/Math.pow(10,digits)).toFixed(digits);
    }

}