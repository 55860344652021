import React from "react";
import Button from "./Button";
import {PopUp} from "./PopUp";
import {DateInput} from "./DateInput";

export class PopUpReminder extends PopUp {
    constructor(props) {
        super(props);

        this.popupType = 'reminder';

        this.state = {
            title: 'New reminder',
            reminder: props.reminder ? props.reminder : {},
            editing: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            reminder: nextProps.reminder ? nextProps.reminder : {},
            editing: !!nextProps.reminder
        });
    }

    renderContent() {
        let reminder = this.state.reminder;

        this.state.reminder.start_date = this.state.reminder.start_date && typeof this.state.reminder.start_date != "object" ? new Date(this.state.reminder.start_date) : this.state.reminder.start_date || new Date();
        this.state.reminder.end_date = this.state.reminder.end_date && typeof this.state.reminder.end_date != "object" ? new Date(this.state.reminder.end_date) : this.state.reminder.end_date || null;

        return (
            <React.Fragment>
                <div className="label">Title</div>
                <input
                    autoFocus={true}
                    defaultValue={reminder.title}
                    onChange={(e) => { reminder.title = e.target.value; this.setState({ reminder: reminder })}}
                />

                <div className="label">Date</div>
                <DateInput
                    id="calendar-from"
                    label="Start"
                    value={this.state.reminder.start_date}
                    min={new Date()}
                    onChange={date => {
                        reminder.start_date = date;
                        this.setState({ reminder: reminder });
                    }}
                    />
                <DateInput
                    id="calendar-to"
                    label="End"
                    min={this.state.reminder.start_date}
                    value={this.state.reminder.end_date}
                    onChange={date => {
                        reminder.end_date = date;
                        this.setState({ reminder: reminder });
                    }}
                />

                <div className="label">Notes</div>
                <input
                    defaultValue={reminder.description}
                    onChange={(e) => { reminder.description = e.target.value; this.setState({ reminder: reminder })}}
                />

                <div id="buttons">
                    <Button
                        loading={this.props.loading}
                        theme="primary"
                        onClick={() => {
                            this.props.onSubmit(this.state.reminder).then((success) => {
                                this.typed = false;
                                this.setState({
                                    success
                                });

                                setTimeout(function(){
                                    this.setState({
                                        success: false
                                    });
                                }.bind(this), 1500);
                            }, message => {
                            });
                            this.props.filter();
                        }}
                        title={this.state.editing ? 'Update' : 'Create'}
                    />
                    {
                        this.state.editing ?
                            <Button
                                loading={this.props.loading}
                                theme="secondary"
                                className="ml-2"
                                title="Remove"
                                onClick={() => {
                                    this.props.onRemove(this.state.reminder).then((success) => {
                                        this.setState({
                                            success
                                        });
        
                                        setTimeout(function(){
                                            this.setState({
                                                success: false
                                            });
                                        }.bind(this), 1500);
                                    });
                                }}
                            />
                        :
                            null
                    }
                </div>
            </React.Fragment>
        )
    }
}