import React, { useState } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../includes/Button.js';
import { useDispatch } from 'react-redux';
import { resetAll } from 'smartpub-common/redux/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';

export function PubList() {
    const dispatch = useDispatch();
    const route = useLocation();
    const navigation = useNavigate();
    const [highlighted, setHighlighted] = useState(App.user?.details?.pub);   

    const setPub = () => {
        App.user.setPub(highlighted).then(() => {
            dispatch(resetAll());
            // Redirect user to homepage
            window.location.href = "/";
        }, (error) => {
            console.log(error);
        });
    }

    const highlight = (id) => {
        setHighlighted(id);
    }

    const renderPub = (pub) => {
        return (
            <a className={pub.id === highlighted ? 'pub-name pub-name--active' : 'pub-name'} key={"pub-"+ pub.id} onClick={() => highlight(pub.id)}>
                <p>{pub.name.toLowerCase()}</p>
            </a>
        );
    }

    const renderPubs = () => {
        let pubs = App.user?.details?.pubs;

        if(!pubs || !pubs.length) {
            return;
        }

        pubs.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); // Sort by pub name

        pubs = pubs.map((pub) => {
            return renderPub(pub);
        });

        return (
            <div className='pub-list'>
                {pubs}
            </div>
        );
    }

    return (

        <React.Fragment>
            <div className='block block--pub-select'>
                <div className='header'>
                    <h4>Choose pub</h4>
                </div>
                <div className='content'>
                    {renderPubs()}
                </div>
            </div>
            <div id="buttons">
                {
                    route.state?.back ?
                        <Button
                            title="Cancel"
                            theme="secondary"
                            onClick={() => navigation(-1)}
                            style={{marginRight: 5}}
                        />
                    :
                        null
                }

                <Button
                    text="Select"
                    theme="primary"
                    onClick={setPub}
                />
            </div>
        </React.Fragment>
    )
}