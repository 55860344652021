import React, { useState, useEffect } from 'react';
import { PopUpSelectSupplier } from '../PopUpSelectSupplier';
import { PopUpQuantity } from '../PopUpQuantity';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from '../Button.js';
import { ProductItem } from './ProductItem.js';
import {
    SectionList,
    FlatList
} from 'react-native';

import { useSelector, useDispatch } from 'react-redux';
import { store } from 'smartpub-common/redux/default';
import { actions as orderActions, selectors as orderSelectors } from 'smartpub-common/redux/slices/order';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import SectionHeader from './SectionHeader.js';
import ColumnHeaders from './ColumnHeaders.js';
import { useNavigate, createSearchParams } from "react-router-dom";
import { showMessage } from '../../facades/showMessage.js';


const OrderedList = ({ListEmptyComponent, header, setQtyPopupProduct}) => {
    const items = useSelector(orderSelectors.getItems);
    
    if (!items.length) return ListEmptyComponent;

    return (
        <>
            <FlatList
                data={items}
                renderItem={
                    ({item}) => (
                        <ProductItem  
                            item={item}
                            setQtyPopupProduct={setQtyPopupProduct}
                        />
                    )
                }
                keyExtractor={item => item === "header" ? item : item.product_id}
                ListHeaderComponent={header}
                stickyHeaderIndices={[1]}

                // Performance settings
                removeClippedSubviews={true} // Unmount components when outside of window
                initialNumToRender={50} // Reduce initial render amount
                maxToRenderPerBatch={50} // Reduce number in each render batch
                windowSize={50} // Reduce the window size
                keyboardShouldPersistTaps='handled'
            />
        </>
    );
}

const SuppliersList = ({ ListEmptyComponent, setShowChangeSupplierToggle, showChangeSupplierToggle, checked, toggleProduct, header, setQtyPopupProduct }) => {
    const suppliers = useSelector(orderSelectors.getGroupedBySupplier);

    const sections = suppliers.map(supplier => ({
        id: supplier.supplier_id,
        data: supplier.items
    }));

    if (!sections.length) return ListEmptyComponent;
    
    return (
        <div id="product-list">
            <SectionList
                sections={sections}
                keyExtractor={(item, index) => index + '_' + item.supplier_id + '_' + item.product_id}
                renderItem={({item}) => 
                    <ProductItem 
                        key={item.product_id}
                        item={item} 
                        toggleProduct={toggleProduct} 
                        showChangeSupplierToggle={showChangeSupplierToggle}
                        selectedChangeSupplier={checked.includes(item.product_id)}
                        setQtyPopupProduct={setQtyPopupProduct}
                    />
                }
                ListHeaderComponent={header}
                renderSectionHeader={({section}) => <SectionHeader section={section} setShowChangeSupplierToggle={setShowChangeSupplierToggle} showChangeSupplierToggle={showChangeSupplierToggle}/>}
            />
        </div>
    );
}

export const ProductList = ({ header }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const sortBy = useSelector(orderSelectors.getSort);
    const isTopUp = useSelector(orderSelectors.isTopUp);
    
    const [showSupplierPopup, setShowSupplierPopup] = useState(false);
    const [checked, setChecked] = useState([]);
    const [toChange, setToChange] = useState([]);
    const [showChangeSupplierToggle, setShowChangeSupplierToggle] = useState(null);
    const [showingFooter, setShowingFooter] = useState(true);

    const [qtyPopupProduct, setQtyPopupProduct] = useState(null);
    
    const hasMinOrderQtyBeenMet = useSelector(orderSelectors.hasMinOrderQtyBeenMet);
    
    const confirmOrder = () => {
        navigate('/order/checkout');
        App.analytics.logEvent('tap_order_checkout_click', {'order_checkout_start': 'confirm'});
    }
    
    const toggleProduct = (productID, supplierID) => {
        var index = toChange.findIndex(item => item.product_id === productID);
        
        var productName = productSelectors.getById(store.getState(), productID).name;
        App.analytics.logEvent('tap_trolley', {'product': productName});
        
        let tempToChange = [...toChange];
        let tempChecked = [...checked];
        
        if(index == -1){
            tempToChange.push({product_id: productID, supplier_id : supplierID});
            tempChecked.push(productID);
        } else {
            tempToChange.splice(index, 1);
            tempChecked.splice(index, 1);
        }
        
        setToChange(tempToChange);
        setChecked(tempChecked);
    }
    
    useEffect(() => {
        setToChange([]);
        setChecked([]);
        setShowSupplierPopup(false);
    }, [showChangeSupplierToggle])
    
    const updateSupplier = (supplier_id) => {
        checked.forEach(product_id => {
            dispatch(orderActions.addOrUpdate({
                product_id,
                supplier_id
            }));
        });
        setShowChangeSupplierToggle(null);
    }

    const onQuantityUpdate = quantity => {
        dispatch(orderActions.addOrUpdate({
            product_id: qtyPopupProduct.product_id,
            quantity
        }));

        var product = productSelectors.getById(store.getState(), qtyPopupProduct.product_id);

        showMessage({
            title: "Set quantity",
            message: product.name + " updated"
        });
        
        return Promise.resolve();
    }

    const listEmptyComponent = !isTopUp ? (
        <h4 class="p-3 text-center" onClick={() => navigate("/settings/favourites")}>
            No favourites set. Click here to setup favourites.
        </h4>
    ) : (
        <h4 class="p-3 text-center">No items added to order</h4>
    );

    var product = productSelectors.getById(store.getState(), qtyPopupProduct?.product_id) ?? null;

    return <>
        <div id="product-list-table" className='col-md-12 order-list'>
            <ColumnHeaders />            
            { sortBy === 'stock' ?
                <OrderedList
                    setShowChangeSupplierToggle={setShowChangeSupplierToggle}
                    showChangeSupplierToggle={showChangeSupplierToggle}
                    checked={checked}
                    toggleProduct={toggleProduct}
                    header={header}
                    ListEmptyComponent={listEmptyComponent}
                    setQtyPopupProduct={setQtyPopupProduct}
                />
            :
                <SuppliersList
                    setShowChangeSupplierToggle={setShowChangeSupplierToggle}
                    showChangeSupplierToggle={showChangeSupplierToggle}
                    checked={checked}
                    toggleProduct={toggleProduct}
                    header={header}
                    ListEmptyComponent={listEmptyComponent}
                    setQtyPopupProduct={setQtyPopupProduct}
                />
            }
        </div>

        
        <div id='product-list-buttons-footer'>
            <div className='container'>
                <div className='col-md-6'>

                    { checked.length && showChangeSupplierToggle ?
                        <React.Fragment>
                            <Button
                                title="Cancel"
                                theme="secondary"
                                onClick={() => {
                                    setShowChangeSupplierToggle(false);
                                    App.analytics.logEvent('tap_select_new_supplier', {'change': 'cancel'});
                                }}
                            />
                            <Button
                                title="Select new supplier"
                                theme="primary"
                                onClick={() => {
                                    setShowSupplierPopup(true);
                                    App.analytics.logEvent('tap_select_new_supplier', {'change': 'new'});
                                }}
                            />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Button
                                testID="test:id/buttonOrderOtherProducts"
                                title="Add products"
                                theme="secondary"
                                onClick={() => {
                                    navigate(`/order/others`);
                                    App.analytics.logEvent('tap_other_products');
                                }}
                            />

                            {
                                !hasMinOrderQtyBeenMet ?
                                    <div className='blank-error'>
                                        Minimum Order Quantity {"\n"} 
                                        not met
                                    </div>
                                :
                                    <Button
                                        title="Checkout"
                                        theme="primary"
                                        permission="order"
                                        onClick={() => {
                                            confirmOrder();
                                            App.analytics.logEvent('tap_order_checkout_click', {'order_checkout_start': 'confirm'});
                                        }}
                                    />
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
        
        <PopUpQuantity
            quantity={qtyPopupProduct?.quantity ?? 0}
            onSave={onQuantityUpdate}
            onDismiss={() => setQtyPopupProduct(null)}
            product={product}
            isVisible={qtyPopupProduct}
        />

        <PopUpSelectSupplier
            visible={showSupplierPopup}
            title="Select supplier"
            button="Move"
            hideHIL={true}
            onlyFavourites={true}
            exclude={toChange.length ? toChange[0].supplier_id : null}
            onSave={updateSupplier}
            onDismiss={()=> {setShowSupplierPopup(false)}}
        />
    </>;
}