import React from 'react';

import Button  from '../includes/Button';
import { FeedbackService } from 'smartpub-common/services/feedback.js';

import { showMessage } from '../facades/showMessage.js';

import starClosedIcon from '../../assets/icons/star-closed.svg';
import starOpenIcon from '../../assets/icons/star-open.svg';

export default class Feedback extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            comment: null,
            request: null,
            stars: null,
            submitted: false,
            loading: false,
            requestType: null
        }

        this.submitFeedback = this.submitFeedback.bind(this);
    }

    submitFeedback() {
        if (this.state.loading) {
            return;
        }

        FeedbackService.submit(this.state.comment, this.state.request, this.state.stars, this.state.requestType).then((data) => {
            this.setState({
                submitted: true
            });

            setTimeout(() => {
                this.setState({ submitted: false, comment: null, stars: null });
            }, 2000);
        }, (error) => {
            showMessage({
                title: error.message ? error.message : "Error submitting feedback",
                message: error.description ? error.description : error,
                error: true,
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        let stars = [];

        for(let i = 0; i < 5; i++) {
            var image = starOpenIcon;
            if (this.state.stars > i) {
                image = starClosedIcon;
            }

            stars.push(
                <a href="#/" key={"star-"+ i} onClick={() => this.setState({ stars: i+1 })}>
                    <img src={image} alt={i + "star"} />
                </a>
            );
        }

        return (
            <div id="feedback">
                <div className="container row">
                    <div className="col-md-12">
                    {
                        this.state.submitted ?
                            <h5 className="text-center">
                                Thank you for your feedback
                            </h5>
                        :
                            <React.Fragment>
                                <h4>Customer service number: 0818 51 44 55</h4>

                                <label>Your feedback</label>
                                <textarea
                                    id="feedback-text"
                                    placeholder="Your feedback here"
                                    onChange={(e) => this.setState({comment: e.target.value})}
                                >{this.state.comment}</textarea>

                                <div id="stars-container">
                                    {stars}
                                </div>

                                <Button
                                    title="Submit"
                                    theme="light"
                                    textColor="green"
                                    loading={this.state.loading}
                                    onClick={() => this.submitFeedback()}
                                />

                            </React.Fragment>
                    }
                    </div>
                </div>
            </div>
        );
    }
}