import React from "react";
import {PopUp} from "./PopUp";
import Button from "./Button";

export class PopUpAlert extends PopUp {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content,
            htmlContent: props.htmlContent,
            success: props.success === true ? props.content : props.success
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: nextProps.title,
            content: nextProps.content,
            htmlContent: nextProps.htmlContent,
        });
    }

    renderContent() {
        if(this.props.icon && !this.props.icon.includes('admin/null')) {
            this.icon = (<img
                id="alert-popup-icon"
                src={this.props.icon}
            />);
        }

        return (
            <React.Fragment>
                <p id="alert-popup-content">
                    {
                        this.state.htmlContent ?
                            <div dangerouslySetInnerHTML={{__html: this.state.htmlContent}} />
                        :
                            this.state.content
                    }
                </p>

                <div id="alert-popup-btn-container">
                    <Button
                        title={!this.props.buttonTitle ? "Visit link" : this.props.buttonTitle}
                        theme="primary"
                        onClick={() => this.props.link ? 
                            window.open(
                                this.props.link,
                                '_blank'
                            )
                            : 
                            this.props.onDismiss()
                        }
                    />
                </div>
            </React.Fragment>
        )
    }
}