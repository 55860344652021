import React, { useState } from 'react';

import { IconButton } from '../includes/IconButton';
import Button from '../includes/Button';

import { AppService, AppService as App } from 'smartpub-common/services/app.js';

import starIcon from '#assets/icons/star-open-teal.svg';
import userIcon from '#assets/icons/user-teal.svg';
import editIcon from '#assets/icons/edit-icon-green.svg';

import { useDispatch } from 'react-redux';
import { resetAll } from 'smartpub-common/redux/actionTypes';
import { useNavigate } from 'react-router-dom';

import { showMessage } from '../facades/showMessage';

export default function Settings() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const userDetails = App.user.details;
    const [knownAs, setKnownAs] = useState(userDetails['known_as']);
    const [telephone, setTelephone] = useState(userDetails['telephone']);

    const logOut = () => {
        dispatch(resetAll());
        App.cache.clearAll().then((error) => {
            navigate("/");
        });
    }

    const clearCache = () => {
        dispatch(resetAll());

        // Get list of all items in cache
        App.cache.getAll().then(function(keys) {
            var promises = [];
        
            // Delete all items except user
            Object.keys(keys).forEach((key) => {
                if (key !== 'user') {
                    promises.push(App.cache.remove(key));
                }
            });
        
            // Wait for all delete promises to resolve before restarting app
            Promise.all(promises).then(() => {
                navigate("/");
            }).catch((err) => {
                // Handle errors here
                console.error(err);
            });
        });
    }

    const saveInfo = () => {
        if (loading) return;

        setLoading(true);

        var phoneRegexTest = /^[\d\s+()]{5,20}$/;
        if (!phoneRegexTest.test(telephone)) {
            showMessage({
                message: "Error saving settings",
                description: "Invalid phone number",
                backgroundColor: "#f7462b"
            });

            setLoading(false);

            return;
        }

        var data = {
            'known_as': knownAs,
            'telephone': telephone
        }

        // Save delivery note
        userDetails["delivery_note"] = userDetails['delivery_note'];

        App.user.update(data).then(data => {
            setLoading(false);

            showMessage({
                title: "Info update",
                message: "Settings have been saved successfully"
            })

            setEditing(false);

            App.user.save();
        });
    }

    return (
        <div id="settings">
            <div className="settings-buttons">
                <div className='container'>
                    <div className="row flex-column">
                    <div className="log-button">
                        <div className='btn-container'>
                            <Button
                                title="Logout"
                                theme="secondary"
                                onClick={logOut}
                            />
                        </div>
                    </div>
                        <IconButton
                            title="Favourites, Suppliers & Pars"
                            theme="blank"
                            size="big"
                            permission="stock_favourites"
                            file={starIcon}
                            arrow={true}
                            onClick={() => {
                                navigate("/settings/favourites", {
                                    state: { 'type': 'favourites', 'title': 'Favourite order' }  
                                })
                                App.analytics.logEvent('tap_favourites');
                            }}
                        />
                        <IconButton
                            title="Pub Users"
                            theme="blank"
                            size="big"
                            permission="admin"
                            file={userIcon}
                            arrow={true}
                            onClick={() => navigate("/users", {
                                state: { 'type': 'users', 'title': 'My pub users' }  
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row justify-content-md-center">
                    <div className="col-md-12">
                        <div className="block block--pub-info">
                            <div className="header">
                                <h4>My information</h4>
                                <a href="#!" onClick={() => setEditing(!editing)}>
                                    <img src={editIcon}  />
                                </a>
                            </div>
                            <div className="content">
                                { !editing ?
                                    <React.Fragment>
                                        <div>
                                            <label>Full name:</label>
                                            <p>{userDetails.name}</p>
                                        </div>

                                        <div>
                                            <label>Known as:</label>
                                            <p>{knownAs}</p>
                                        </div>

                                        <div>
                                            <label>Email:</label>
                                            <p>{userDetails["email"]}</p>
                                        </div>

                                        <div>
                                        <label>Phone:</label>
                                            <p>{telephone}</p>
                                        </div>
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        <div>
                                            <label>Full name:</label>
                                            <p>{userDetails["name"]}</p>
                                        </div>

                                        <div>
                                            <label>Known as:</label>
                                            <input
                                                type="text"
                                                value={knownAs}
                                                onChange={(e) => setKnownAs(e.target.value)}
                                            />
                                        </div>

                                        <div>
                                            <label>Email:</label>
                                            <p>{userDetails["email"]}</p>
                                        </div>

                                        <div>
                                            <label>Phone:</label>
                                            <input 
                                                type="text" 
                                                value={telephone} 
                                                onChange={(e) => setTelephone(e.target.value)} />
                                        </div>

                                        <div>
                                            <Button
                                                title="Save"
                                                theme="primary"
                                                loading={loading}
                                                onClick={() => saveInfo()}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <p className="text-center">
                    <small>App version: {AppService.version}</small>
                </p>
            </div>
        </div>
    );
}
