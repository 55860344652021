import React, { useMemo } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import { FlatList } from 'react-native';
import { IconButton } from '../IconButton';

import { store } from 'smartpub-common/redux/default';
import { selectors as categorySelectors } from 'smartpub-common/redux/slices/categories';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import kegIcon from "#assets/icons/categories/draught-icon.svg";
import draughtIcon from "#assets/icons/categories/draught-icon.svg";
import posIcon from "#assets/icons/categories/POS-icon.svg";
import packagedIcon from "#assets/icons/categories/packaged-icon-teal.svg";
import otherIcon from "#assets/icons/categories/draught-icon.svg";
import glasswareIcon from "#assets/icons/categories/draught-icon.svg";
import bottleIcon from "#assets/icons/categories/draught-icon.svg";
import pgIcon from "#assets/icons/categories/draught-icon.svg";
import snacksIcon from "#assets/icons/categories/snack-icon.svg";
import wineIcon from "#assets/icons/categories/wine-icon.svg";
import spiritsIcon from "#assets/icons/categories/spirits-icon.svg";
import softDrinksIcon from "#assets/icons/categories/soft-drinks-icon.svg";

import { createSearchParams, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import order from 'smartpub-common/redux/slices/order';

export const CategoryList = ({ type, }) => {
    const routeParams = useParams();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    let categories = useSelector(state => categorySelectors.getChildren(state, routeParams.category));
    const parentCategory = useSelector(state => categorySelectors.getById(state, routeParams.category));

    const categoryList = useMemo(() => {
        if (!categories) return;

        return _.chain(categories)
            // Remove categories that have no child products
            .filter(category => {
                return productSelectors.getByCategory(store.getState(), category.id, searchParams.get('supplier')).length;
            })
            // Sort alphabetically
            .orderBy(['name'], ['asc'])
            .value()
    }, [categories]);

    const getIcon = (name, parent = false) => {
        let icon = null;
        switch(name.replaceAll(" ", "").toLowerCase()) {
            case "keg": icon = kegIcon; break;
            case "draught": icon = draughtIcon; break;
            case "pos": icon = posIcon; break;
            case "packaged": icon = packagedIcon; break;
            case "other": icon = otherIcon; break;
            case "glassware": icon = glasswareIcon; break;
            case "bottle": icon = bottleIcon; break;
            case "pg": icon = pgIcon; break;
            case "snacks": icon = snacksIcon; break;
            case "wine": icon = wineIcon; break;
            case "spirits": icon = spiritsIcon; break;
            case "softdrinks": icon = softDrinksIcon; break;
        }

        if (!icon && parentCategory && !parent) {
            return getIcon(parentCategory.name, true);
        }

        return icon;
    }

    const selectCategory = category => {
        let showProducts = true;
        if (category.children && category.children.length) {
            App.analytics.logEvent('tap_add_category', {'category': category.name});

            // Are there more than 100 products in all these subcategories?
            const productCount = productSelectors.getByCategory(store.getState(), category.id).length;
            if (productCount > 100) {
                showProducts = false;
            }
        } else {
            App.analytics.logEvent('tap_add_subcategory', {'sub_category':category.name});
        }

        const query = createSearchParams({ supplier: searchParams.get('supplier') }).toString();

        let route = `/order/others/${category.id}`;
        if (type === "favourites") {
            route = `/settings/favourites/categories/${category.id}`;
        }

        navigate(`${route}/${showProducts ? 'products' : ''}?${query}`);
    }

    return (
        <FlatList
            data={categoryList}
            id="category-list"
            renderItem={({item: category}) => (
                <IconButton
                    arrow={true}
                    title={category.name}
                    size="medium"
                    fileLocal={getIcon(category.name)}
                    file={getIcon(category.name)}
                    onClick={() => selectCategory(category) }
                />
            )}
        />
    )
};