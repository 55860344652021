import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { ApiService as Api } from '../../services/api';
import { selectors as supplierSelectors } from './suppliers';
import { selectors as preferenceSelectors } from './preference';
import { selectors as productSelectors } from './products';
import { Utils } from '../../services/utils.js';

import _ from 'lodash';

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    loading: 'idle',
    details: null,
    suppliers: [],
    favourites: [],
    stock: [],
    metaData: [],
    pinned: []
}

export const fetchDetails = createAsyncThunk('pub/details/fetch', async () => await Api.request('pub'));
export const fetchFavourites = createAsyncThunk('pub/favourites/fetch', async () => await Api.request('pub/favourites'));
export const fetchPinned = createAsyncThunk('pub/pinned/fetch', async () => await Api.request('pub/pins'));
export const fetchSuppliers = createAsyncThunk('pub/suppliers/fetch', async () => await Api.request('pub/suppliers'));
export const fetchStock = createAsyncThunk('pub/stock/fetch', async () => await Api.request('pub/stock'));
export const fetchMetadata = createAsyncThunk('pub/metadata/fetch', async () => await Api.request('pub/metadata'));
export const fetch = createAsyncThunk('pub/fetch', async (_, { dispatch }) => await Promise.all([
    dispatch(fetchDetails()),
    dispatch(fetchFavourites()),
    dispatch(fetchPinned()),
    dispatch(fetchSuppliers()),
    dispatch(fetchStock()),
    dispatch(fetchMetadata())
]));

export const updateSupplier = createAsyncThunk('pub/suppliers/update', async ({supplier_id, account_number}, { getState }) => {
    if (!account_number) {
        throw Error("Supplier account number is required...");
    }
    
    const data = {
        supplier_id,
        account_number,
        is_active: 1
    };

    const supplierObj = supplierSelectors.getById(getState(), data.supplier_id);
    App.analytics.logEvent('tap_add_supplier_confirm', {'supplier': supplierObj.name, 'account_number': data.account_number, 'confirm': 'confirm'});

    console.log("updateSupplier", data);

    return await App.api.request("pub/supplier", data, "POST");
});

export const removeSupplier = createAsyncThunk('pub/suppliers/remove', async (supplier_id, { getState, dispatch }) => {
    const favouriteSupplier = selectors.supplierById(getState(), supplier_id);

    if (!favouriteSupplier) {
        throw Error("Supplier not in favourites...");
    }

    const data = {
        supplier_id,
        account_number: favouriteSupplier.account_number,
        is_active: 0
    };

    // Remove products from supplier
    const supplierFavourites = selectors.favouriteBySupplier(getState(), data.supplier_id);
    supplierFavourites.map(async favourite => {
        console.log("Remove", favourite.product_id);
        await dispatch(removeFavourite(favourite.product_id));
    });

    const supplierObj = supplierSelectors.getById(getState(), data.supplier_id);
    App.analytics.logEvent('tap_remove_supplier', {'supplier': supplierObj.name, 'account_number': data.account_number, 'confirm': 'confirm'});

    return await App.api.request("pub/supplier", data, "POST");
});

export const updateFavourite = createAsyncThunk('pub/favourites/update', async ({product_id, par, supplier_id, item_order}) => {
    return await App.api.request("pub/favourites", {data: JSON.stringify([{
        product_id,
        par,
        supplier_id,
        item_order
    }])}, "POST");
});

export const removeFavourite = createAsyncThunk('pub/favourites/remove', async (product_id) => {
    return await App.api.request("pub/favourites", {data: JSON.stringify([{
        product_id,
        par: -1
    }])}, "POST");
});

export const moveFavouriteProducts = createAsyncThunk('pub/favourites/move', async ({oldSupplierId, newSupplierId}, { dispatch, getState }) => {
    const products = getState().pub.favourites.filter(item => item.supplier_id === oldSupplierId);
    const promises = products.map(product =>
        dispatch(updateFavourite({...product, supplier_id: newSupplierId}))
    );
    
    return await Promise.all(promises);
});

export const reSortFavouriteProducts = createAsyncThunk('pub/favourites/re-sort', async(_, { dispatch, getState }) => {
    return await App.api.request("pub/favourites", {data: JSON.stringify(getState().pub.favourites)}, "POST");
});

export const pinProduct = createAsyncThunk('pub/pinned/add', async (product_id) => {
    return await App.api.request("pub/pins", {product_id}, "POST");
});

export const updateDetails = createAsyncThunk('pub/details/update', async (data) => {
    return await App.api.request('pub', data, 'PATCH');
});

export const updateStock = createAsyncThunk('pub/stock/update', async ({product_id, stock}) => {
    return await App.api.request("pub/stock", {stock: JSON.stringify([{
        product_id,
        quantity: stock
    }])}, "POST");
});

const updateCache = state => {
    App.cache.set("pub", {...state.details, ...state.favourites, ...state.suppliers, ...state.stock, ...state.pinned});
}

const slice = createSlice({
    name: 'pub',
    initialState,
    reducers: {
        moveProduct: (state, action) => ({ ...state, favourites: action.payload })
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
                App.initUpdate('Pub details', 0);
            }
        });

        builder.addCase(fetch.fulfilled, (state, { meta, payload: [{ payload: details }, { payload: favourites }, { payload: pinned }, { payload: suppliers }, { payload: stock }, { payload: metaData }] }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }
            
            state.details = details;
            state.stock = stock;
            state.favourites = favourites;
            state.suppliers = suppliers;
            state.metaData = metaData;
            state.pinned = pinned;
            
            state.loading = 'idle';
            state.currentRequestId = undefined;

            App.initUpdate('Pub details', 1);
            updateCache(state);
        });

        builder.addCase(fetch.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            state.loading = 'idle';
            state.currentRequestId = undefined;

            console.error("Unable to load suppliers");
            App.initUpdate('Suppliers', 2);
            
            // Load from cache as offline
            App.cache.get("pub").then(({ details, favourites, suppliers, stock, metaData, pinned }) => {
                state.details = details;
                state.favourites = favourites;
                state.suppliers = suppliers;
                state.stock = stock;
                state.metaData = metaData;
                state.pinned = pinned;
            });
        });

        builder.addCase(reSortFavouriteProducts.fulfilled, (state, { payload }) => {
            state.favourite = payload;
            updateCache(state);
        });

        builder.addCase(fetchStock.fulfilled, (state, { meta, payload: stock }) => {
            state.stock = stock;
            updateCache(state);
        });

        builder.addCase(updateSupplier.fulfilled, (state, { payload }) => {
            state.suppliers = payload;
            updateCache(state);
        });

        builder.addCase(removeSupplier.fulfilled, (state, { payload }) => {
            state.suppliers = payload;
            updateCache(state);
        });

        builder.addCase(pinProduct.fulfilled, (state, { payload }) => {
            state.pinned = payload;
            updateCache(state);
        });
            
        builder.addCase(removeFavourite.pending, (state, { meta: { arg: product_id } }) => {
            state.favourites = state.favourites.filter(item => item.product_id !== product_id);
        });

        builder.addCase(removeFavourite.fulfilled, (state, { payload }) => {
            state.favourites = payload;
            updateCache(state);
        });

        // builder.addCase(updateFavourite.pending, (state, { meta: { arg: { product_id, par }} }) => {
        //     var index = _.findIndex(state.favourites, {product_id: product_id});
        //     state.favourites.splice(index, 1, {
        //         ...state.favourites[index],
        //         par
        //     });
        // });

        builder.addCase(updateFavourite.fulfilled, (state, { meta, payload: favourites }) => {
            state.favourites = favourites;
            updateCache(state);
        });

        builder.addCase(updateDetails.fulfilled, (state, { meta, payload: details }) => {
            state.details = details;
            updateCache(state);
        });

        builder.addCase(updateStock.fulfilled, (state, { meta, payload: stock }) => {
            state.stock = stock;
            updateCache(state);
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;

const favouritesSelector = createMapSelector([
    state => state.pub.favourites,
    state => state.pub.details,
    state => state.products.items,
    state => state.suppliers.items
], (items, details, products, suppliers) => {
    items = _
        .chain(items)
        .filter(favourite => {
            const matchingSupplier = _.find(suppliers, { id: favourite.supplier_id });
            const product = productSelectors.getById({products: {items: products}}, favourite.product_id);

            return (matchingSupplier && matchingSupplier.active !== 0) || (product && product.supplier === 4);
        })
        .map(favourite => {
            const matchingSupplier = _.find(suppliers, { id: favourite.supplier_id ? favourite.supplier_id : 4 });

            return (matchingSupplier && matchingSupplier.active === 0) ? { ...favourite, supplier_id: 4 } : favourite;
        })
        .value();

    // Remove non-reca items for reca pubs
    if (details?.reca) {
        items = items.filter(item =>
            productSelectors.isVisible({
                products: {items: products}, 
                suppliers: {items: suppliers},
                pub: {details: details}
            }, item.product_id)
        );
    }

    return items;
});

export const selectors = {
    details: createMapSelector([
        state => state.pub.details,
        (state, key) => key
    ], (details, key) => {
        if (!key) return details;

        return details[key];
    }),
    metaData: createMapSelector([
        state => state.pub.metaData,
        (state, key) => key
    ], (metaData, key) => {
        if (!key) return metaData;

        return metaData[key];
    }),
    deliveryDates: createMapSelector([
        state => state.pub.metaData
    ], (deliveryDates) => {
        var dates = [];
        for (var i = 1; i <= 3; i++) {
            var delivery = deliveryDates['delivery_date' + i],
            cutoff = deliveryDates['cut_off' + i];

            if (!delivery || Utils.isDateBeforeToday(cutoff)) continue;
            
            dates.push({
                deliveryDate: delivery,
                cutoffDate: cutoff,
                delivery: Utils.dateUntil(delivery, false),
                cutoff: Utils.dateUntil(cutoff)
            });
        }
        
        return dates;
    }),
    suppliers: createMapSelector([
        state => state.pub.suppliers,
        state => state.suppliers.items,
        (state, includeHil = false) => includeHil,
    ], (pubSuppliers, suppliers, includeHil) => {
        pubSuppliers = pubSuppliers.slice(); // Shallow copy

        // Remove HIL
        if (!includeHil) {
            pubSuppliers = pubSuppliers.filter(supplier => {
                return supplier.supplier_id !== 1 &&
                    supplier.supplier_id !== 3
            });
        } else {
            // Double check that pub has force suppliers
            const hil = supplierSelectors.getByName({ suppliers: { items: suppliers }}, "Heineken Ireland Limited");
            const glass = supplierSelectors.getByName({ suppliers: { items: suppliers }}, "Heineken Ireland Glassware");

            if(hil && !pubSuppliers.find((item) => item.supplier_id == hil.supplier_id)){
                pubSuppliers.push({
                    supplier_id: hil.id,
                    is_active: 1,
                });
            }

            if(glass && !pubSuppliers.find((item) => item.supplier_id == glass.supplier_id)) {
                pubSuppliers.push({
                    supplier_id: glass.id,
                    is_active: 1,
                });
            }
        }
        if (!pubSuppliers.length) {
            return [];
        }

        pubSuppliers = pubSuppliers.filter(supplier => supplier.is_active);

        pubSuppliers = pubSuppliers.filter(supplier => !Array.isArray(supplier));

        // Remove inactive suppliers
        let allSuppliers = suppliers;
        allSuppliers = _.filter(suppliers, ['active', 1]);

        pubSuppliers = _.intersectionBy(pubSuppliers, allSuppliers, 'supplier_id');
        
        // Sort
        pubSuppliers = _.orderBy(pubSuppliers, [
            // Get Heienken suppliers to the top
            supplier => supplier.supplier_id == 1 ? 0 : (supplier.supplier_id == 3 ? 1 : 2),
            // Sort remaining by name
            supplier => supplierSelectors.getById({suppliers: { items: suppliers}}, supplier.supplier_id).name.toLowerCase()
        ], ['asc']);

        return pubSuppliers;
    }),
    supplierById: createMapSelector([
        state => state.pub.suppliers,
        (state, id) => id
    ], (suppliers, id) => suppliers.find(item => item.supplier_id === id)),

    favourites: favouritesSelector, 

    favouritesFiltered: createMapSelector([
        favouritesSelector,
        state => ({
            products: {
                items: state.products.items
            }
        }),
        state => ({
            preferences: {
                filter_supplier: state.preferences.filter_supplier,
                filter_category: state.preferences.filter_category
            }
        })
    ], (favourites, productsState, preferencesState) => {
        // Filter
        const filterSupplier = preferenceSelectors.getFilterSupplier(preferencesState);
        const filterCategory = preferenceSelectors.getFilterCategory(preferencesState);

        return _.chain(favourites)
            .orderBy('item_order', 'ASC')
            .filter(item => !filterSupplier || item.supplier_id === filterSupplier)
            .filter(item => !filterCategory || productSelectors.getById(productsState, item.product_id)?.category_id === filterCategory)
            .value();
    }),

    pinned: state => state.pub.pinned,

    isPinned: createMapSelector([
        state => state.pub.pinned,
        (state, id) => id
    ], (pinned, id) => {
        return pinned.includes(id)
    }),

    favouriteById: createMapSelector([
        state => state.pub.favourites,
        (state, id) => id
    ], (favourites, id) => favourites.find(item => item.product_id === id)),

    favouriteBySupplier: createMapSelector([
        favouritesSelector,
        (state, supplier) => supplier
    ], (favourites, supplier) => favourites.filter(item => item.supplier_id === supplier)),

    stockById: createMapSelector([
        state => state.pub.stock,
        (state, id) => id
    ], (stock, id) => stock.find(item => item.product_id === id)),
};
