import React from 'react';
import { useSelector } from 'react-redux';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';

export default ({item, showPrice, onPress}) => {
    const product = useSelector(state => productSelectors.getById(state, item.product_id));
    const minOrderQty = product && product.min_order_qty ? product.min_order_qty : 0;
    const isReca = useSelector(state => productSelectors.isReca(state, item.product_id));
    const pubIsReca = useSelector(state => pubSelectors.details(state, "reca"));
    const productUnitPrice = useSelector(state => productSelectors.getPrice(state, product.id, true));
    const productTotal = useSelector(state => productSelectors.getPrice(state, product.id, true, item.quantity));
    const pubTotalVat = useSelector(state => productSelectors.getPrice(state, product.id, true, item.quantity, true));

    if (!product) {
        return;
    }

    return <button onClick={onPress}>
        <div className="product-list-item-name-container">
            <div className="product-list-item-name">
                {
                    product.status === 2 ?
                        <p style={{color: "#808080"}}>{product.name} - Supplier out of stock</p>
                    :
                        <p style={{color: "#ffffff"}}>{product.name}</p>
                }
                {
                    minOrderQty ? 
                        <div className="min-order-qty">
                            Minimum order quantity: {minOrderQty}
                        </div>
                    : 
                        null
                }
                {
                    product.label ?
                        <p className='label'>{product.label}</p>
                    :
                        null
                }
                {
                    (isReca && pubIsReca & product.status !== 2) && showPrice ?
                        <div>
                            <p>{ ' ' + productUnitPrice }
                            {
                                item.quantity ?
                                    'ea, ' + productTotal + ' + ' + pubTotalVat
                                    :
                                    ' excl VAT'
                            }
                            </p>
                        </div>
                    : null
                }
            </div>
        </div>
    </button>;
};