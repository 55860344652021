import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '../includes/IconButton';
import Button from '../includes/Button';
import { AppService as App } from 'smartpub-common/services/app.js';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';

import Genie from '../../assets/icons/Genie-big.svg';
import editIcon from '../../assets/icons/down-green.svg';
import pubInfoIcon from '../../assets/icons/info-icon.svg';
import setupIcon from '../../assets/icons/settings-icon.svg';
import socialIcon from '../../assets/icons/social-icon.svg';
import contactIcon from '../../assets/icons/contact-icon.svg';
import feedbackIcon from '../../assets/icons/feedback.svg';
import stockIcon from '../../assets/icons/stock-icon-acitve.svg';
import orderIcon from '../../assets/icons/order-icon-active.svg';
import financialIcon from '../../assets/icons/financial-icon-active.svg';



const DeliveryDates = () => {
    const deliveryDates = useSelector(pubSelectors.deliveryDates);
    if (!deliveryDates.length) return;

    var delivery = deliveryDates[0].delivery,
        cutoff = deliveryDates[0].cutoff;

    return (
        <div className="block block--next-delivery">
            <div className='block--row block--top'>
                <div className='block--column block--header'>Next HEINEKEN delivery date</div>
                <div className='block--column'>{delivery}</div>
                <img src={Genie} className='genie' />
            </div>
            <div className='block--row'>
                <div className='block--column block--header'>Order cut off</div>
                <div className='block--column'>{cutoff}</div>
            </div>
        </div>
    );
}

const PubDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (App.user && App.user.details && App.user.details.pubs && App.user.details.pubs.length > 1) {
        var pub = App.user.details.pubs.filter((item) => item.id === App.user.details.pub);

        if (!pub || !pub.length) {
            return null;
        }

        pub = pub[0].name;

        var state = location.state || {};

        if(!state || (typeof state == "object" && Object.keys(state).length < 1)) {
            var params = new URLSearchParams(window.location.search);
            
            for(const [key, value] of params) {
                state[key] = value;
            }
        }

        state.back = true;

        return (
            <a onClick={() => navigate("/pub", { state })} className="pub-switch">
                <span>{pub.toLowerCase()}</span>
                <img src={editIcon} />
            </a>
        );
    } else {
        var pub = App.user && App.user.getPub() ? App.user.getPub().name : '';
        return (
            <div className="pub-switch">
                <span>{pub.toLowerCase()}</span>
            </div>
        );
    }
}

const Home = () => {
    const knownAs = useMemo(() => App.user && App.user.details ? App.user.details.known_as : '', []);

    const navigate = useNavigate();

    return (
        <div id="home">
            <div className="content-background-wrapper">
                <div className="header">
                    <img
                        id="logo"
                        alt="SmartPub logo"
                        src={Genie}
                    />
                    <h2>Hi { knownAs }</h2>
                    <PubDetails />
                </div>
                <div className="home-nav">
                    <div className="row justify-content-center home-main-nav">
                        <div className="col-md-4">
                            <IconButton
                                theme="green"
                                title="Stock count"
                                size="big"
                                file={stockIcon}
                                permission="stock_favourites,history"
                                onClick={() => {App.analytics.logEvent('tap_stock_count'); navigate("/stock")}}
                            />
                        </div>
                        <div className="col-md-4">
                            <IconButton
                                theme="green"
                                title="Order"
                                size="big"
                                file={orderIcon}
                                permission="order,history"
                                onClick={() => {App.analytics.logEvent('tap_order'); navigate("/order")}}
                            />
                        </div>

                        <div className="col-md-4">
                            <IconButton
                                theme="green" 
                                title="Financial" 
                                file={financialIcon} 
                                size="big"
                                permission="financial,statements"
                                onClick={() => {App.analytics.logEvent('tap_financial'); navigate("/financial")}}
                            />
                        </div>

                    </div>
                    <div className='home-footer-container'>
                        <ul className="row justify-content-center home-footer">
                            <li>
                                <Button 
                                    onClick={() => {App.analytics.logEvent('tap_home_footer', {'footer_link': 'Pub info'}); navigate("/pubinfo")}}
                                    theme="blank"
                                    title="Pub info" 
                                    icon={<img src={pubInfoIcon} alt="Pub info" />} 
                                />
                            </li>
                            <li>
                                <Button 
                                    onClick={() => {App.analytics.logEvent('tap_home_footer', {'footer_link': 'Setup'}); navigate("/settings")}}
                                    theme="blank"
                                    title="Setup" 
                                    icon={<img src={setupIcon} alt="Setup" />} 
                                />
                            </li>
                            <li>
                                <Button
                                    onClick={() => {App.analytics.logEvent('tap_home_footer', {'footer_link': 'Training & Social Media'}); navigate("/social")}}
                                    theme="blank"
                                    title="Training & Social Media"
                                    icon={<img src={socialIcon} alt="Training & Social Media" />} 
                                />
                            </li>
                            <li>
                                <Button
                                    onClick={() => {App.analytics.logEvent('tap_home_footer', {'footer_link': 'Contact Us'}); navigate("/contact")}}
                                    theme="blank"
                                    title="Contact Us"
                                    icon={<img src={contactIcon} alt="Contact us" />} 
                                />
                            </li>
                            <li>
                                <Button
                                    onClick={() => {App.analytics.logEvent('tap_home_footer', {'footer_link': 'Feedback'}); navigate("/feedback")}}
                                    theme="blank"
                                    title="Feedback"
                                    icon={<img src={feedbackIcon} alt="Feedback" />} 
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <DeliveryDates />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;