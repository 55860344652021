import React from 'react';

export default function ColumnHeaders() {
    return (
        <div className="table-header blank">
            <div className="col-md-9">
                <p className="text-left">Item</p>
            </div>

            <div className="col-md-3">
                <p className="text-right">Par</p>
            </div>
        </div>
    )
}