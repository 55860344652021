import React, { useState, useEffect } from 'react';
import { PopUpConfirm } from '../../includes/PopUpConfirm';
import { AppService as App } from 'smartpub-common/services/app.js';

import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions, selectors as orderSelectors } from 'smartpub-common/redux/slices/order';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import ProductName from '../product/Name';
import Value from '../product/Value';


import nextIcon from '../../../assets/icons/next-back-icon-green.svg';
import deleteIcon from '../../../assets/icons/close.svg';
import calendarIcon from '../../../assets/icons/calendar-icon.svg';
import forwardIcon from '../../../assets/icons/forward-icon.svg';

import {
    ActivityIndicator
} from 'react-native';


export const ProductItem = ({item, toggleProduct, showChangeSupplierToggle, selectedChangeSupplier, setQtyPopupProduct }) => {
    const dispatch = useDispatch();

    const [showHistory, setShowHistory] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const product = useSelector(state => productSelectors.getById(state, item.product_id));
    const minOrderQty = product.min_order_qty ? product.min_order_qty : 0;
    let borderColour = '#ffffff';
    let backgroundColour = null;
    let colour = '#ffffff';
    if (item.quantity >= minOrderQty || item.quantity == 0) {
        borderColour = '#cefab7';
        backgroundColour = '#cefab7';
        colour = '#00414B';
    } else {
        borderColour = '#ffffff';
        backgroundColour = '#f74429';
    }

    if (product.status == 2) {
        colour = '#808080';
        borderColour = '#808080';
        backgroundColour = 'transparent';
    }

    let stock = useSelector(state => pubSelectors.stockById(state, item.product_id));
    stock = stock ? stock.stock : null;
    let favourite = useSelector(state => pubSelectors.favouriteById(state, item.product_id));
    const isCounter = useSelector(orderSelectors.isCounter);
    
    let visible = true;
    if (isCounter) {
        visible = false;
    }

    let quantity = product.status !== 2 ? item.quantity : 0;

    const removeItem = () => {
        dispatch(orderActions.remove(item.product_id))
    }

    return (
        <>
            <div className="product-list-item" key={"product" + item.product_id}>
                <div className="row sort">    
                    <div className="col-md-12">
                        <div className="row">
                            <div className="product-list-item-left flex-fill">
                                <div className={"product-list-item-name-container sortable"}>
                                    <ProductName item={item} showPrice={true} onClick={()=> {
                                        if (product.status !== 2) {
                                            setQtyPopupProduct(item);
                                            App.analytics.logEvent('tap_product_line', {'product': product.name});
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className='col-md-0'>
                                <Value value={stock} name="stock" borderColour="#fff" backgroundColour={null} colour="#fff" visible={visible} />
                            </div>
                            <div className='col-md-0'>
                                <Value value={favourite?.par} name="par" borderColour="#fff" backgroundColour={null} colour="#fff" visible={visible} />
                            </div>
                            <div className='col-md-0'>
                                <Value value={quantity} name="quantity" borderColour={borderColour} backgroundColour={backgroundColour} colour={colour} visible={true} onClick={() => {
                                        if (product.status !== 2) {
                                            setQtyPopupProduct(item);
                                            App.analytics.logEvent('tap_product_line', {'product': product.name});
                                        }
                                    }} 
                                />
                            </div>
                            <div className='col-md-0'>
                                { product.supplier == 4 && (showChangeSupplierToggle == item.supplier_id || showChangeSupplierToggle == 'undefined') ?
                                    <a className='toggle' onClick={() => toggleProduct(item.product_id, item.supplier_id)}>
                                        <div className='tick' style={{backgroundColor: selectedChangeSupplier ? '#CEFAB7' : '#00414B'}}></div>
                                    </a>
                                : !!favourite ? 
                                    <a onClick={() => setShowHistory(!showHistory)}>
                                        <img className={showHistory ? 'arrow arrow-down' : 'arrow'} src={nextIcon}  />
                                    </a>
                                : 
                                    <a onClick={() => setShowConfirm(true)}>
                                        <img  src={deleteIcon}  />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        showHistory ?
                            <ProductHistory productID={item.product_id} />
                        : null
                    }
                </div>
            </div>


            { showConfirm ?
                <PopUpConfirm
                    loading={false}
                    isVisible={showConfirm}
                    modalTitle={'Remove item'}
                    content={'Remove '+ product.name + '.\n\nAre you sure you want to continue?'}
                    history={false}
                    onConfirm={()=>{
                        removeItem()
                    }}
                    onCancel={()=>{
                        setShowConfirm(false);
                    }}
                />
            :
                null
            }

        </>
    );
}

const ProductHistory = ({productID}) => {
    const [loading, setLoading] = useState(false);
    const [productHistory, setProductHistory] = useState({});

    useEffect(() => {
        setLoading(true);
        App.pub.history.getProductHistory(productID).then(history=> {
            if (history.length) history[0].active = true;

            setProductHistory(history);
            setLoading(false);
        })
    }, [])

    const historyPrevious = () => {
        var history = [...productHistory];
        var activeItem = history.findIndex(item=>item.active);
        if (activeItem == history.length - 1) return;
        history[activeItem].active = false;
        history[activeItem + 1].active = true;
        setProductHistory(history);
    }

    const historyNext = () => {
        var history = [...productHistory];
        var activeItem = history.findIndex(item=>item.active);
        if (activeItem == 0) return;
        history[activeItem].active = false;
        history[activeItem - 1].active = true;
        setProductHistory(history);
    }

    return (
        <div className="product-list-item-history col-md-12">
            {
                loading ?
                    <div className="row" id="history-loading">
                        <ActivityIndicator size="small" color="#ffffff" />
                    </div>
                :
                    productHistory.length ? 
                        productHistory.map(item => {
                            if (!item.active) return;

                            return (
                                <div className='product-list-item-history-content'>
                                    <div className='shadow'></div>
                                    <a onClick={()=>historyPrevious()} className='history-arrow history-arrow--prev'>
                                        <img className="history-item-arrow" src={forwardIcon} />
                                    </a>

                                    <div className='history-date'>
                                        <img className="history-item-calendar" src={calendarIcon} />
                                        <span>{ item.year } - WK { item.week }</span>
                                    </div>

                                    <a onClick={()=>historyNext()} className='history-arrow history-arrow--next'>
                                        <img className="history-item-arrow" src={forwardIcon} />
                                    </a>

                                    <div className='history-qty'>
                                        <span className="editable">{ item.qty }</span>
                                    </div>
                                </div>
                            )
                        })
                    : <div style={{textAlign: 'center'}}>You haven't ordered this product before!</div>
            }
        </div>
    );
}
