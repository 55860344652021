import { AppService as App } from './app.js';
import { Linking } from 'react-native';
import {Utils} from "./utils";

export class HistoryService {
    // @deprecated #589
    getOrders(full = false, limit = false, start = "", end = "") {//
        this.isFullHistory = full;
        start = start ? Utils.getDateString(start) : "";
        end =  end ? Utils.getDateString(end) : "";
        return App.api.request("order/history/suborders", {full: full, limit: limit, start: start, end: end}).then((orders) => {
            var history = {};
            var weekIndex = 1;

            // Sort orders
            for (var order of orders) {
                var d = new Date(order.order_date);

                order.year = d.getFullYear();
                order.week = d.getWeek(d, true);

                if (!history.hasOwnProperty("y"+ order.year)) {
                    history["y"+ order.year] = {}
                }

                if (!history["y"+ order.year].hasOwnProperty(order.week)) {
                    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                    var day = d.getDay();
                    var weekDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day == 0 ? -6 : 1)-day);
                    weekDate = weekDate.getDate() +" "+ months[weekDate.getMonth()];

                    history["y"+ order.year][order.week] = {
                        date: weekDate,
                        dateObj: d,
                        orders: [],
                        sdocs: [],
                        index: weekIndex
                    };
                    weekIndex++;
                }

                history["y"+ order.year][order.week].orders.push(order);
            }

            if (start == "" && end == "") {
                this.history = history;
            }
            return history;
        });
    }

    // @deprecated #589
    getOrder(orderID) {
        return App.api.request("order/history/" + orderID);
    }

    getSuborders(options) {
        console.log("order/history/suborders", options);
        if (options.start) {
            options.start = Utils.getDateString(options.start);
        }
        if (options.end) {
            options.end = Utils.getDateString(options.end);
        }

        Object.keys(options).forEach(key => {
            if (options[key] === null) {
                delete options[key];
            }
        });


        console.log("order/history/suborders", options);
        return App.api.request("order/history/suborders", options).then((orders) => {
            this.history = orders;
            return orders;
        });
    }

    getSuborder(suborderID) {
        console.log("order/history/suborder/" + suborderID);
        return App.api.request("order/history/suborder/" + suborderID);
    }

    getProductHistory(productID) {
        return App.api.request("product/"+ productID +"/history").then((data) => {
            data.active = false;
            return data;
        });
    }

    getRange() {
        return App.api.request("order/range").then((data) => {
            this.historyRange = data;
            return data;
        });
    }

    export(from = null, to = null, supplierID = null) {
        var url = App.baseURI + '/api/order/export?pub_id=' + App.api.pub;
        url += "&token=" + App.user.details.token;
        if (from) {
            url += '&start=' + encodeURIComponent(from);
        }
        if (to) {
            url += '&end=' + encodeURIComponent(to);
        }
        if (supplierID) {
            url += '&supplier=' + encodeURIComponent(supplierID);
        }

        Linking.openURL(url);
    }
}