import { createSlice, createAsyncThunk, createDraftSafeSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import { AppService as App } from '../../services/app';
import { Utils } from '../../services/utils';

import { selectors as supplierSelectors } from './suppliers';
import { selectors as pubSelectors, fetchStock } from './pub';
import { selectors as productSelectors } from './products';
import { selectors as categorySelectors } from './categories';
import { selectors as preferenceSelectors, actions as preferenceActions } from './preference';

import _ from 'lodash';

// Used to have unlimited memorized selectors
export const createMapSelector = createDraftSafeSelectorCreator(weakMapMemoize);

export const initialState = {
    items: [],
    edited: false,
    type: null,
    purchase_orders: {},
    delivery_date: null,
    delivery_note: "",
    sort: "supplier",
    filter_supplier: null,
    filter_category: null,
    mobile_number: null,
    mobile_country_code: '+353',
}

export const submit = createAsyncThunk('order/submitOrderStatus', async (status_response, ThunkAPI) => {
    const order = ThunkAPI.getState().order;

    // Remove invalid products
    const items = selectors.getItems(ThunkAPI.getState(), true);

    var data = {
        'order': JSON.stringify(items),
        'notes': order.delivery_note,
        'purchase_orders': JSON.stringify(order.purchase_orders),
        'type': order.type === 'counter' ? 'counter' : 'delivery'
    };

    if (order.delivery_date) {
        data.delivery = order.delivery_date.deliveryDate;

        // Check order is still valid
        try {
            await App.api.request("order/checkdelivery", {delivery: data.delivery}, "POST");
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    if (order.type === 'counter' && order.mobile_number) {
        if (order.mobile_country_code) {
            data.mobile_no = order.mobile_country_code + order.mobile_number.replace(/^0|[^\d]/gm, '');
        } else {
            data.mobile_no = order.mobile_number;
        }
    }

    const response = await App.api.request("order", data, "POST");

    // Reset stock count filters
    ThunkAPI.dispatch(preferenceActions.clearFilters());

    // Update stock counts
    ThunkAPI.dispatch(fetchStock());

    // Remove current order
    ThunkAPI.dispatch(slice.actions.clear());

    // Send analytics events
    let totalValue = 0;
    let allProducts = [];
    let favourites = pubSelectors.favourites(ThunkAPI.getState());
    response.sub_orders.forEach(function(sub_order, index) {
        // Added timeout to prevent event errors in loop.
        setTimeout(function(){
            App.analytics.logEvent('order_submitted', {
                'transaction_id': sub_order.ref,
                'transaction_supplier': supplierSelectors.getById(ThunkAPI.getState(), sub_order.supplier_id)?.name,
            });
        }, index * 500);
        
        // If hein supplier add product costs together
        if (sub_order.supplier_id == 1) {
            let value = sub_order.products.map(product => {
                if (product.unit_price && product.qty) {
                    product.total_excl = product.unit_price * product.qty;
                } else {
                    product.total_excl = 0;
                }
                return product;
            });

            totalValue += _.sumBy(value, 'total_excl')
        }

        sub_order.products.map(product => {

            let isProductInFavorites = _.some(favourites, { 'product_id': product.product_id, 'supplier_id': product.supplier_id });
            if (order.type == 'topup') {
                isProductInFavorites = false;
            }

            let prodObj = productSelectors.getById(ThunkAPI.getState(), product.product_id);
            let parentCat = categorySelectors.getParent(ThunkAPI.getState(), prodObj?.category_id);
            let category = categorySelectors.getById(ThunkAPI.getState(), parentCat).name;
            let category2 = categorySelectors.getById(ThunkAPI.getState(), prodObj?.category_id).name;
            let supplier = supplierSelectors.getById(ThunkAPI.getState(), product.supplier_id)?.name;
            let orderProduct = {
                'item_list_name': isProductInFavorites ? 'favourites' : 'order',
                'item_name': prodObj?.name,
                'item_id': product.product_id,
                'item_category': category,
                'item_category2': category2,
                'item_brand': prodObj.brand_owner,
                'item_variant': prodObj.purchase_UOM,
                'supplier': supplier,
                'price': product.unit_price ? product.unit_price : 0,
                'quantity': product.qty
            };

            allProducts.push(orderProduct);
        });
        
    });
    
    const purchase = {
        'value': totalValue,
        'currency': 'EUR',
        'transaction_id': response.id,
        'items': allProducts
    };

    App.analytics.logEvent('purchase', purchase);

    return response;
});

export const create = createAsyncThunk('order/create', async (type, { getState }) => {
    let items = [];

    const state = getState();
    
    // Fill normal order with favourites
    if (type === 'normal' || type === 'counter' || type == 'reset') {
        let favourites = pubSelectors.favourites(state);

        items = favourites.map(favourite => {
            let supplier_id = favourite.supplier_id;
            const supplier = supplierSelectors.getById(state, supplier_id);
            if (!supplier || supplier.active == 0) {
                supplier_id = 4;
            }
            const stockRecord = pubSelectors.stockById(state, favourite.product_id);
            let quantity = 0;
            if (type === 'normal' || type =='reset') {
                const stock = stockRecord ? stockRecord.stock : 0;
                const par = favourite.par;

                quantity = par - stock;
                quantity = quantity < 0 ? 0 : quantity;
            }

            return {
                product_id: favourite.product_id,
                quantity: quantity,
                supplier_id: supplier_id
            }
        });
        
        if (type === 'counter') {
            // Filter only HIL (non-blade) draught products 
            items = items.filter(item => {
                const counter = productSelectors.isCounter(state, item.product_id);
                return counter;
            });
        }
    }
    

    const commonProps = {
        type,
        items
    };
    
    if (type === 'reset') {
        return commonProps;
    } else {
        return {
            ...commonProps,
            filter_supplier: preferenceSelectors.getFilterSupplier(state),
            filter_category: preferenceSelectors.getFilterCategory(state)
        };
    }
});

const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        clear: () => initialState,
        restore: (state, action) => {
            const order = action.payload;
            if (!order || !order.sub_orders || !order.sub_orders.length) return;

            let newState = {...initialState};
            newState.items = [];
            newState.edited = false;
            newState.type = order.type === "counter" ? "counter" : "normal";

            for (var i = 0; i < order.sub_orders.length; i++) {
                var sub = order.sub_orders[i];
                for(var n = 0; n < sub.products.length; n++) {
                    var line = sub.products[n];

                    // Add product to order
                    newState.items.push({
                        product_id: line.product_id,
                        quantity: line.qty,
                        supplier_id: line.supplier_id
                    });
                }
            }

            return newState;
        },
        addOrUpdate: (state, action) => {
            const index = state.items.findIndex(item => item.product_id === action.payload.product_id);
            if (index < 0) {
                state.items.push({
                    product_id: action.payload.product_id,
                    quantity: action.payload.quantity ? parseInt(action.payload.quantity) : 0,
                    supplier_id: parseInt(action.payload.supplier_id)
                });
            } else {
                if (action.payload.quantity !== undefined) {
                    state.items[index].quantity = parseInt(action.payload.quantity);
                }
                if (action.payload.supplier_id !== undefined) {
                    state.items[index].supplier_id = parseInt(action.payload.supplier_id);
                }
            }

            state.edited = true;
        },
        remove: (state, action) => {
            state.items = state.items.filter(item => item.product_id !== action.payload);
        },
        setDeliveryNote: (state, action) => ({ ...state, delivery_note: action.payload }),
        setDeliveryDate: (state, action) => ({ ...state, delivery_date: action.payload }),
        setMobileCountryCode: (state, action) => ({ ...state, mobile_country_code: action.payload }),
        setMobileNumber: (state, action) => ({ ...state, mobile_number: action.payload }),
        setPurchaseOrder: (state, {payload}) => {
            state.purchase_orders[payload.supplier_id] = payload.purchase_order
        },
        setSort: (state, action) => {return ({ ...state, sort: action.payload })},
        setFilterSupplier: (state, action) => ({ ...state, filter_supplier: action.payload }),
        setFilterCategory: (state, action) => ({ ...state, filter_category: action.payload }),
        clearFilters: (state) => ({ ...state, sort: "supplier", filter_supplier: null, filter_category: null})
    },
    extraReducers: (builder) => {
        builder.addCase(create.fulfilled, (state, { payload }) => {
            _.merge(state, payload);

            state.items = payload.items;
            
            state.edited = false;
        });

        builder.addCase(create.rejected, (state, { meta, error }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            console.error("Redux create rejected", error);
        });

        builder.addCase(submit.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
            }
        });

        builder.addCase(submit.fulfilled, (state, { meta, payload }) => {
            console.log("Order submitted");
        });

        builder.addCase(submit.rejected, (state, { meta }) => {
            if (
                state.loading !== 'pending' ||
                state.currentRequestId !== meta.requestId
            ) {
                return;
            }

            console.error("Redux submit rejected");
        });
    }
});
export const actions = slice.actions;
export default slice.reducer;

/**
 * SELECTORS
 */

// Helper functions for selectors
const selectOrder = (state) => state.order;
const selectItems = createMapSelector([
        state => state.order.items,
        state => ({ filter_supplier: state.order.filter_supplier, filter_category: state.order.filter_category }),
        state => state.products.items,
        state => state.pub.favourites,
        (state, orderable = false) => orderable,
        (state, orderable = false, filtered = true) => filtered,
    ], (items, preferences, products, favourites, orderable, filtered) => {
        let newItems = [...items];

        if (orderable) {
            newItems = newItems.filter(item => {
                const product = productSelectors.getById({products: {items:products}}, item.product_id);
                return product && item.quantity > 0 && product.status !== 0 && product.status !== 2;
            });
        }

        if (!filtered) {
            return newItems;
        }

        // Filter suppliers and categories
        newItems = newItems.filter(item => {
            const product = productSelectors.getById({products: {items:products}}, item.product_id);
            const isSupplierAllowed =
                !preferences.filter_supplier || preferences.filter_supplier === "all" ||
                item.supplier_id === parseInt(preferences.filter_supplier);
            const isCategoryAllowed =
                !preferences.filter_category || preferences.filter_category === "all" ||
                product.category_id === parseInt(preferences.filter_category);
            return isSupplierAllowed && isCategoryAllowed;
        });

        const itemOrders = newItems.reduce((acc, item) => {
            const favourite = pubSelectors.favouriteById({pub: {favourites:favourites}}, item.product_id);
            acc[item.product_id] = favourite ? favourite.item_order : 0;
            return acc;
        }, {});

        // Sort items by preferred stock sequence
        newItems.sort((a, b) => {
            const productAOrder = itemOrders[a.product_id] || 9999999;
            const productBOrder = itemOrders[b.product_id] || 9999999;
            return productAOrder - productBOrder;
        });

        return newItems;
    }
);

const getPayable = createMapSelector(
    [
        selectItems,
        state => state.products.items
    ], 
    (items, products) => 
    items.filter((item) => {
        const price = productSelectors.getPrice({products: {items: products}}, item.product_id, false, item.quantity);
        return !!price;
    })
    
)

export const selectors = {
    isCounter: createMapSelector(selectOrder, order => order.type === 'counter'),
    isTopUp: createMapSelector(selectOrder, order => order.type === 'topup'),
    getGroupedBySupplier: createMapSelector(
        [
            selectItems,
            state => state.suppliers.items
        ],
        (items, suppliers) => {
            let orderSuppliers = items.reduce((grouped, item) => {
                let supplier = item.supplier_id ? item.supplier_id : 4;
                const group = grouped.find(group => group.supplier_id === supplier);
                if (!group) {
                    grouped.push({
                        supplier_id: supplier,
                        items: [item],
                    });
                } else {
                    group.items.push(item);
                }
                return grouped;
            }, []);

            suppliers = _.orderBy(orderSuppliers, [
                // Get Heienken suppliers to the top
                supplier => supplier.supplier_id == 1 ? 0 : (supplier.supplier_id == 3 ? 1 : 2),
                // Get supplier selectable to bottom
                supplier => !!supplier.supplier_id,
                // Sort remaining by name
                supplier => supplier.supplier_id && supplierSelectors.getById({suppliers: { items: suppliers}}, supplier.supplier_id).name.toLowerCase()
            ], ['asc']);

            return suppliers;
        }
    ),
    getGroupedByCategory: createMapSelector(
        [
            selectItems,
            state => state.products.items
        ],
        (items, products) => {
            return items.reduce((grouped, item) => {
                const product = productSelectors.getById({products: {items:products}}, item.product_id);
                const group = grouped.find(group => group.category_id === product.category_id);
                if (!group) {
                    grouped.push({
                        category_id: product.category_id,
                        items: [item],
                    });
                } else {
                    group.items.push(item);
                }
                return grouped;
            }, [])
        }
    ),
    getItems: selectItems,
    getItemsCount: createMapSelector(selectItems, items => items.length),
    hasMinOrderQtyBeenMet: createMapSelector([
        state => selectItems(state, true),
        state => state.products.items
    ],
    (items, products) =>
        !items.some(item => {
            const product = productSelectors.getById({products: {items:products}}, item.product_id);
            return product.min_order_qty > item.quantity;
        })
    ),
    inProgress: createMapSelector(selectOrder, order => !!order.type),
    isEdited: createMapSelector(selectOrder, order => order.edited),
    nonZero: createMapSelector(selectItems,
        (items) => items.filter(item => item.quantity > 0)
    ),
    getProduct: createMapSelector(
        [
            state => selectItems(state),
            (state, product_id) => product_id
        ],
        (items, product_id) => items.filter(item => item.product_id === product_id)
    ),
    getPayable,
    getTotal: createMapSelector(
        [
            state => getPayable(state),
            state => state.products.items,
            state => state.pub.details,
            (state, includeVAT = false) => includeVAT,
            (state, includeVAT = false, vatOnly = false) => vatOnly,
        ],
        (items, products, pubDetails, includeVAT, vatOnly) => {
            if (!pubSelectors.details({pub: {details: pubDetails}}, 'reca')) {
                return null; // Not a RECA pub
            }
    
            let total = 0;
            items.forEach((item) => {
                let price = productSelectors.getPrice({products: {items: products}}, item.product_id, false, item.quantity),
                    vat = productSelectors.getPrice({products: {items: products}}, item.product_id, false, item.quantity, true);
    
                if (!price) return;
    
                if (includeVAT) {
                    total += vat;
                }
    
                if (!vatOnly) {
                    total += price;
                }
            });
    
            return '€' + Utils.roundFloat(total);
        }
    ),
    getSort: state => state.order.sort,
    getFilterSupplier: state => state.order.filter_supplier,
    getFilterCategory: state => state.order.filter_category,
};