import { AppService as App } from './app.js';

export class SmartlinksService {
    setup() {
        this.smartlinks = [];

        App.initUpdate('Smart links', 0);
        return this._load();//
    } 

    get(value = null, key = null) {
        if (!this.smartlinks) {
            return [];
        }

        // Clone to avoid propagation
        var smartlinks = JSON.parse(JSON.stringify(this.smartlinks));

        if (value === null) {
            return smartlinks;
        }

        if (!key) {
            key = "id";
        }

        var results = smartlinks.filter(smartlink => smartlink[key] === value);

        if (results.length == 1 || key == "id") { // If we're looking up a smartlink always return a single
            return results[0];
        } else if (results.length) {
            return results;
        }
    }

    _load() {
        return App.api.request("smartlinks").then((response) => {
            var smartlinks = [];

            if (response && Array.isArray(response)) {
                smartlinks = response.map((item) => {
                    return {
                        id: item.id,
                        link: !item.url ? "" : item.url.includes('http') ? item.url : "http://"+ item.url,
                        title: item.name,
                        short_desc: item.short_desc,
                        long_desc: item.long_desc,
                        icon: item.icon,
                        active: item.active,
                        sort: item.sort
                    }
                });
            }

            smartlinks = smartlinks.sort((a, b) => a.sort - b.sort);

            App.initUpdate('Smart links', 1);
            App.cache.set("smartlinks", smartlinks);
            this.smartlinks = smartlinks;
        }, (error) => {
            //console.error(error);
            App.initUpdate('Smart links', 2);

            // Load from cache as offline
            App.cache.get("smartlinks").then((data) => {
                this.smartlinks = data;
            });
        });
    }
}