import React, { useEffect } from 'react';
import { Utils } from "smartpub-common/services/utils";
import { useSelector } from 'react-redux';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from 'smartpub-common/redux/default';

import tickIcon from '#assets/icons/tick.svg';
import warningIcon from '#assets/icons/warning.svg';

export default function Summary() {
    const location = useLocation();
    const navigate = useNavigate();
    const order = location.state?.order;
    const deliverDate = useSelector(state => state.order.delivery_date);
    const isReca = useSelector(state => state.pub.details).reca;

    useEffect(() => {
        if(!order || !order.sub_orders) {
            navigate('/', { replace: true });
            return null;
        }
    }, []);

    if (!order) {
        return;
    }

    var suborders = order.sub_orders.map((suborder) => {
        const supplier = supplierSelectors.getById(store.getState(), suborder.supplier_id);
        return (
            <div className='col-md-6'>
                <div className={"block block--" + (suborder.sent ? "integrated" : "non-integrated")}>
                    <div className='header'>
                        <h5 className="text-center">{supplier.name}</h5>
                        <h6 className="text-center">{suborder.ref}</h6>
                    </div>
                    <div className='content'>
                        { order.type === 'counter' ?
                            <div>
                                <img className="supplier-warning" src={warningIcon} alt="Tick icon" />
                                <div>Please Wait! When your order is ready for collection at the depot we will notify you by email and/or SMS.</div>
                            </div>
                        : (suborder.email || supplier.email) || supplier.is_heineken ?
                            <React.Fragment>
                                <img className="supplier-tick" src={tickIcon} alt="Tick icon" />
                                Submitted to supplier
                                {
                                    suborder.email ? 
                                        <div className="text-center small" style={{fontWeight: 'normal'}}>Order sent to {suborder.email}</div>
                                    :
                                        null
                                }
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <img className="supplier-warning" src={warningIcon} alt="Warning icon" />
                                Please check your email for order and manually submit to supplier.
                            </React.Fragment>
                        }
                        {
                            deliverDate && supplier.is_heineken ?
                                <div style={{color: "white", fontSize: 12, marginTop: 5, textAlign: 'center'}}>
                                    Delivery: {deliverDate.delivery}
                                </div>
                            :
                                null
                        }
                    </div>
                    {
                        suborder.paid && isReca ?
                            <div className='payment-footer'>
                                <p style={{fontSize: 14, color: "#FFFFFF"}}>
                                    Payment of €{ Utils.roundFloat(suborder.paid.total_excl) } + €{ Utils.roundFloat(suborder.paid.total - suborder.paid.total_excl) } VAT has been made - Ref { suborder.paid.id }
                                </p>
                            </div>
                        : null
                    }
                </div>
            </div>
        );
    });
        

    return (
        <div id="order-summary">
            <div className='container'>
                <div className='row'>
                    { suborders }
                </div>
            </div>
        </div>
    );
    
}