import "isomorphic-fetch";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppRegistry } from 'react-native';
import './components/styles/style.scss';
import 'bootstrap/dist/css/bootstrap.css';

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {
	initialProps: {},
	rootTag: document.getElementById('root')
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();