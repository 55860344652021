import React from 'react';
import { IconButton } from '../includes/IconButton';
import { AppService as App } from 'smartpub-common/services/app.js';

import stockIcon from '#assets/icons/stock-icon-acitve.svg';
import calendarIcon from '#assets/icons/stock-icon-acitve.svg';
import { useNavigate } from 'react-router-dom';

const StockMenu = () => {
    const navigate = useNavigate();

    let title = "Stock count",
    items = [
        { title: "Update\ncurrent stock", permission: "stock_favourites", path: "/stock/count", icon: stockIcon },
        { title: "Order history", permission: "history", path: "/history", icon: calendarIcon }
    ],
    pId = title.replace(/ /g, "-").toLowerCase();

    let links = items.map((item) => (
        <div className="col-md-6" key={item.title}>
            <IconButton size="big" permission={item.permission} file={item.icon} title={item.title} 
                onClick={() => {
                    App.analytics.logEvent('tap_'+ item.title.replace(/\s+/g, '_').toLowerCase());
                    navigate(item.path)
                }} />
        </div>
    ));

    return (
        <div id={pId} className="row">
            <div className="container container--small">
                <div className="row justify-content-center">
                    {links}
                </div>
            </div>
        </div>
    );
}

export default StockMenu;