import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { AppService as App } from 'smartpub-common/services/app.js';
import ProductName from '../../product/Name';
import Value from '../../product/Value';

export function ProductItem({ item, setQtyPopupProduct }) { 
    const dispatch = useDispatch();
    const product = useSelector(state => productSelectors.getById(state, item.product_id));

    return (
        <>
            <div className="product-list-item" key={"product" + item.product_id}>
                <div className="row sort">    
                    <div className="col-md-12">
                        <div className="row">
                            <div className="product-list-item-left flex-fill">
                                <div className="product-list-item-image">
                                    <img src={product.image}/>
                                </div>
                                <div className={"product-list-item-name-container sortable"}>
                                    <ProductName item={item} showPrice={true} onClick={()=> {
                                        if (product.status !== 2) {
                                            setQtyPopupProduct(item);
                                            App.analytics.logEvent('tap_product_line', {'product': product.name});
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className='col-md-0'>
                                <Value value={item.quantity} name="quantity" onClick={() => {
                                        if (product.status !== 2) {
                                            setQtyPopupProduct(item);
                                            App.analytics.logEvent('tap_product_line', {'product': product.name});
                                        }
                                    }} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}