import React, { useState, useEffect, useLayoutEffect } from 'react';
import {ProductList} from '../../includes/order/ProductList';
import filterIcon from '../../../assets/icons/filter-button.svg';
import { AppService as App } from 'smartpub-common/services/app.js';
import { PopUpFilterSort } from '../../includes/order/PopUpFilterSort';
import { useSelector, useDispatch } from 'react-redux';
import { selectors as orderSelectors, create as createOrder } from 'smartpub-common/redux/slices/order';
import { useLocation, useNavigate } from 'react-router-dom';



export default function Order({ setPageTitle }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const route = useLocation();
    const inProgress = useSelector(orderSelectors.inProgress);
    const isTopUp = useSelector(orderSelectors.isTopUp);
    const isCounter = useSelector(orderSelectors.isCounter);

    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const itemsLen = useSelector(orderSelectors.getItemsCount);
    const isEdited = useSelector(orderSelectors.isEdited);

    setPageTitle(isCounter ? 'Depot collection order' : isTopUp ? 'Top up order' : 'Order');

    // Create new order on navigation if required
    useLayoutEffect(() => {
        const type = route.state?.type;
        if (!type) {
            if (!inProgress) {
                dispatch(createOrder('normal'));
            }
            return; // Keep the current order
        }

        navigate(null, { replace: true,  state: {} });

        if (inProgress && itemsLen && isEdited) {
            var confirm = window.confirm('All changes to the current order will be lost.\n\nAre you sure you want to continue?');
            if (confirm) {
                dispatch(createOrder(type));
                App.analytics.logEvent('tap_new_or_continue', {'order_continue': 'continue'});
            } else {
                App.analytics.logEvent('tap_new_or_continue', {'order_continue': 'cancel'});
                navigate("/order");
            }
        } else {
            // Start new empty order
            dispatch(createOrder(type));
        }
    }, []);

    return (
        <div id="order-new">
            <div className='container'>
                { !isTopUp && !isCounter ?
                    <a href='#!' className='filter-sort' onClick={()=>{
                        App.analytics.logEvent('tap_filter_order');
                        setShowFilterPopup(true);
                    }}>
                        Filter & sort
                        <img src={filterIcon} alt="Filter icon" />
                    </a>
                : null }
            </div> 
            <div className="row">
                <ProductList />
            </div>
            <PopUpFilterSort
                visible={showFilterPopup}
                onDismiss={() => setShowFilterPopup(false)}
            />
        </div>
    );
}