import React from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import Button from "../includes/Button";
import SearchBar from "../includes/financial/SearchBar";
import { ActivityIndicator } from "react-native-web";
import FlatList from "flatlist-react";
import { Utils } from "smartpub-common/services/utils";
import arrowIcon from '../../assets/icons/next-back-icon-green.svg';
import downloadIcon from '../../assets/icons/download-icon.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

class StatementsClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: [],
            downloading: [],
            statements: [],
            loading: true,
            types: {
                cfin: "Summary statement",
                invoice: "Invoice",
                memo: "Debit/Credit note",
                sdoc: "Invoice/statement pre Nov 2021"
            }
        }

        this.parseSearchOptions = this.parseSearchOptions.bind(this);
        this.loadStatements = this.loadStatements.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.download = this.download.bind(this);
        this.renderInvoices = this.renderInvoices.bind(this);
        this.toggleCheck = this.toggleCheck.bind(this);
    }

    componentDidMount() {
        this.parseSearchOptions(this.props);
    }

    parseSearchOptions(props) {
        let searchOptions = {
            to: props.searchParams.has('to') && props.searchParams.get('to') ? new Date(props.searchParams.get('to')) : null,
            from: props.searchParams.has('from') && props.searchParams.get('from') ? new Date(props.searchParams.get('from')) : null,
            type: ''
        }

        if (props.searchParams.get('type') && props.searchParams.get('type') !== 'all') {
            searchOptions.type = props.searchParams.get('type').split(',');
        }

        let title = "All documents";
        if ("type" in searchOptions && typeof searchOptions.type === "object") {
            title = Array.isArray(searchOptions.type) ? searchOptions.type.map(t => t.ucFirst() + 's').join(' & ') : searchOptions.type.ucFirst() + 's';
        }
        const searching = !!props.searchParams.has('search');

        this.setState({
            title,
            searching,
            from: searchOptions.from,
            to: searchOptions.to,
            type: searchOptions.type,
            showTypes: searching
        });

        this.loadStatements(searchOptions);
    }

    toggleCheck(id) {
        var checked = this.state.checked;

        if(!checked.includes(id)) {
            checked.push(id);
        } else {
            var index = checked.indexOf(id);
            checked.splice(index, 1);
        }

        this.setState({
            checked
        });
    }
    
    componentWillReceiveProps(newProps) {
        if (this.props.searchParams === newProps.searchParams) return;

        this.parseSearchOptions(newProps);
    }

    loadStatements(options = {}) {
        if (!App.pub) return;

        this.setState({
            loading: true
        });

        //i572 note (AK): Made this method more similar to the one in History.js, just for the sake of readability
        App.pub.getDocuments(options.type ?? this.state.type, options.from ?? this.state.from, options.to ?? this.state.to).then((data) => {
            data = data.sort(function(a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            this.setState({
                loading: false,
                statements: data
            });
        }, (err) => {
            this.setState({
                loading: false
            });
        });
    }

    downloadFile(fileData) {
        fileData.forEach(file => {
            //link is base64
            Utils.download(file.link, file.name);

            this.setState({
                downloading: []
            });
        });
    }

    download(all_in_one = false) {
        if(this.state.downloading.length) return;

        this.setState({
            downloading: this.state.checked,
            checked: []
        }, () => {
            //First do an API request to get the download links/base64 encoded content to do a blob fetch
            App.pub.downloadDocuments(all_in_one, this.state.downloading).then((fileData) => {
                if(!Array.isArray(fileData)) fileData = [fileData];
    
                this.downloadFile(fileData);
            }).catch(err => {
                console.warn('errrrrrr', err);
            });
        });
    }

    renderInvoices(item) {
        var type = '';
        if(item.memo_type) type = item.memo_type[0].toUpperCase() + item.memo_type.substring(1, item.memo_type.length) +' ';
        type += item.type[0].toUpperCase() + item.type.substring(1, item.type.length);

        return (
            <div key={item.id} className={"invoice-item"+ (this.state.checked.includes(item.id) ? ' selected' : '')}>
                <a href={item.link} className="invoice-item-link" onClick={(e) => {
                        e.preventDefault();
                        if(!e.target.className.includes('indicator')) {
                            //window.location.href = item.link;
                            window.open(item.link, '_blank').focus();
                        }

                        App.analytics.logEvent('doc_open')
                    }}>
                    <div className="invoice-item-content">
                        <div className="invoice-item-indicator-container" onClick={(e) => !this.state.downloading.includes(item.id) ? this.toggleCheck(item.id) : null}>
                            <span>
                                {
                                    this.state.downloading.includes(item.id) ?
                                        <div className="invoice-item-indicator loading">
                                            <ActivityIndicator color='#ffffff' size={28} />
                                        </div>
                                    :
                                        <div className="invoice-item-indicator">
                                            <div className="invoice-item-indicator-inner"></div>
                                        </div>
                                }
                            </span>
                        </div>
                        <div className="invoice-item-label-container">
                            <h5 className="invoice-item-label">{type} { Utils.getDateString((item.type == 'statement' ? item.closing_date : item.date), true) }</h5>
                            <div className="invoice-item-metadata">
                                <span>{ item.doc_no ? item.doc_no : item.total ? 'Closing Balance €'+ item.total +' inc VAT' : '' }</span>
                                {
                                    item.total && item.subtotal ?
                                        <span>€{ item.total } inc VAT</span>
                                    :
                                        null
                                }
                            </div>
                        </div>

                        <div className="invoice-item-arrow">
                            <img src={arrowIcon} />
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    render() {
        //i572 note (AK): Is this even still used? Doesn't seem like it. Leaving note here as reminder to remove/comment out the block of code below
        let dateString;
        if (this.state.from && this.state.to) {
            dateString = Utils.getDateString(this.state.from, true) + ' - ' + Utils.getDateString(this.state.to, true);
        } else if (this.state.from) {
            dateString = 'Since ' + Utils.getDateString(this.state.from, true);
        } else if (this.state.to) {
            dateString = 'Before ' + Utils.getDateString(this.state.to, true);
        }

        return (
            <div id="documents" className="page-wrapper">
                <div className='container'>
                    <a href="#!" className='go-back' onClick={() => this.props.navigate("/financial")}><img src={arrowIcon} />Back</a>
                </div>
                <div className="container row">
                    <SearchBar
                        selectedFilters={Array.isArray(this.state.type) ? this.state.type : this.state.type ? [this.state.type] : [] }
                        selectedDateRange={[this.state.from, this.state.to]}
                    />
                    <div className="col-md-12" id="documents-container">
                        <h3 id="page-title">{ this.state.title }</h3>
                        <p id="page-desc">Click document to open OR select documents to download to your device</p>

                        {
                            this.state.checked.length > 1 && !this.state.downloading.length ?
                                <div id="documents-buttons">
                                    <Button
                                        id="multi-download-btn"
                                        title="Separate downloads"
                                        theme="primary"
                                        onClick={() => this.download()}
                                        icon={<img src={downloadIcon} className="multi-download-icon" />}
                                    />
                                    <Button
                                        id="merged-download-btn"
                                        title="One single download"
                                        theme="primary"
                                        onClick={() => this.download(true)}
                                        icon={<img src={downloadIcon} className="merged-download-icon" />}
                                    />
                                </div>
                            :
                                this.state.checked.length === 1 && !this.state.downloading.length ?
                                    <div id="documents-buttons">
                                        <Button
                                            id="download-btn"
                                            title="Download"
                                            theme="primary"
                                            onClick={() => this.download()}
                                            icon={<img src={downloadIcon} className="download-icon" />}
                                        />
                                    </div>
                                :
                                    this.state.downloading.length ?
                                        <div id="documents-buttons">
                                            <Button
                                                id="download-btn"
                                                className="loading"
                                                title="Downloading"
                                                theme="primary"
                                                icon={<ActivityIndicator size={25} color='#00414B'/>}
                                            />
                                        </div>
                                    :
                                        null
                        }

                        {
                            this.state.loading ?
                                <ActivityIndicator size="small" color="#00414B" />
                            :
                                <div id="invoices">
                                    <FlatList
                                        list={this.state.statements}
                                        renderItem={
                                            (item) => this.renderInvoices(item)
                                        }
                                        renderWhenEmpty={() => (<p style={{textAlign: "center", color: "white", margin: 20}}>{"No " + this.state.title.toLowerCase() + " found"}</p>)}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default function Statements() {
    const location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    return <StatementsClass location={location} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} />
}