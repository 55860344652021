import {
    ActivityIndicator
} from 'react-native';
import React from "react";
import {PopUp} from "./PopUp";
import Button from "./Button";
import MobileInput from './order/MobileInput';

export class PopUpConfirm extends PopUp {
    constructor(props) {
        super(props);

        this.state = {
            content: props.content,
            loadingMessage: props.loadingMessage,
            recentlyOrderedSuppliers: props.recentlyOrderedSuppliers
        }

        setTimeout(
            function() {
                this.setState({
                    loadingMessage: "Please bear with us while we process your order.\n\nPlease do not close the window or click the back button."
                });
            }
            .bind(this), 20000
        );
    }

    static getDerivedStateFromProps(props, state) {
        return {
            title: props.title,
            content: props.content,
            recentlyOrderedSuppliers: props.recentlyOrderedSuppliers
        }
    }

    NewlineText(content) {
        if(!content) content = this.state.content;
        if(!content.includes('\n')) return content;
        const newText = content.split('\n').map(str => <p>{str}</p>);
        return newText;
    }

    renderContent() {
        return (
            <React.Fragment>

                {
                    !this.props.loading ?

                    <React.Fragment>
                        {this.state.recentlyOrderedSuppliers && this.state.recentlyOrderedSuppliers.matches?.length && !this.props.isCounter ?
                            <p
                                id="recently-ordered-suppliers"
                                style={{
                                    fontSize: 16,
                                    color: this.state.recentlyOrderedSuppliers.message_colour,
                                    textAlign: "center"
                                }}
                            >
                            {this.state.recentlyOrderedSuppliers.message}{'\n'}
                                <ul>
                                    {this.state.recentlyOrderedSuppliers.matches.map((supplier, i) => {     
                                        return (
                                            <li
                                                style={{
                                                    fontSize: 16,
                                                    color: this.state.recentlyOrderedSuppliers.message_colour,
                                                }}
                                            >
                                                {supplier}
                                            </li>
                                        ) 
                                    })}
                                </ul>
                            </p>
                        :
                            null
                        }
                        { this.props.isCounter ? <MobileInput isPopUp={true} /> : null }
                        { !this.props.isCounter ?
                            <p className={!this.props.isCounter ? 'text-center' : 'text-padding'}>{this.NewlineText(this.state.content)}</p>
                        : null }
                        <div className="display-flex choice-button-container">
                            {
                                this.props.onCancel ?
                                    <Button
                                        title="Cancel"
                                        theme="primary"
                                        onClick={() => {
                                            this.props.onCancel();
                                        }}
                                    />
                                : null
                            }
                            {
                                this.props.onConfirm ?
                                    <Button
                                        title="Confirm"
                                        theme="primary"
                                        onClick={() => {
                                            this.props.onConfirm();
                                        }}
                                    />
                                : null 
                            }
                        </div>
                    </React.Fragment>

                    : 

                    <React.Fragment>
                        <p className="text-center">{this.NewlineText(this.state.loadingMessage)}</p>
                        <ActivityIndicator size="large" color="#ffffff" />
                    </React.Fragment>
                }
                

            </React.Fragment>
        )
    }
}