import * as React from 'react';
import Fuse from 'fuse.js';

/**
 * A React Hook that filters an array using the Fuse.js fuzzy-search library.
 *
 * @param list The array to filter.
 * @param searchTerm The search term to filter by.
 * @param fuseOptions Options for Fuse.js.
 * 
 * @returns The filtered array.
 * 
 * @see https://fusejs.io/
 */
function useFuse(
    list,
    searchTerm,
    fuseOptions = {
        keys: ['name'],
        shouldSort: true,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        matchAllTokens: true,
        findAllMatches: true
    }
) {
    const fuse = React.useMemo(() => {
        return new Fuse(list, fuseOptions)
    }, [list])

    // React.useEffect(() => {
    //     fuse.setCollection(list);
    //     console.log("Set collection", list.length);
    // }, [list])

    const results = React.useMemo(() => {
        console.log("Update results");
        if (!searchTerm) return [];
        return fuse.search(searchTerm);
    }, [fuse, searchTerm, list])

    return results.map(result => result.item);
}

export default useFuse;