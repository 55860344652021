import React, { useState } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as productSelectors } from 'smartpub-common/redux/slices/products';
import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import ProductName from '../product/Name';
import ColumnHeaders from './ColumnHeaders.js';

import moveIcon from '../../../assets/icons/move-icon.svg';

export const ProductItem = ({ item, setActiveProduct, showQuantityPopup, sort = true }) => {
    if (item === "header") {
        return <ColumnHeaders />;
    }

    const product = useSelector(state => productSelectors.getById(state, item.product_id));    

    let stock = useSelector(state => pubSelectors.stockById(state, item.product_id));
    stock = stock ? stock.stock : null;

    const image = product.image;

    return <>
        <div className="product-list-item" key={"product" + product.id}>
            <div className="row sort">    
                <div className="col-md-12">
                    <div className="row">
                        <div className="product-list-item-left flex-fill">
                            <div className="product-list-item-image">
                                <img src={image}/>
                            </div>

                            <ProductName item={item} showPrice={false} onPress={()=> {
                                showQuantityPopup(product, stock);
                                App.analytics.logEvent('tap_product_line', {'product': product.name});
                            }} />
                        </div>
                        <div className="product-list-item-right col-md-3">
                            <div className="editable-content">
                                <a
                                    href="#!"
                                    className="product-list-item-value editable"
                                    onClick={() => {
                                        showQuantityPopup(product, stock);
                                    }}
                                >
                                        { stock }
                                </a>
                            </div>
                            { sort ?
                                <a className="sort-arrows" onClick={() => {
                                    setActiveProduct(item.product_id);
                                }}>
                                    <img src={moveIcon}  />
                                </a>
                            : <div style={{paddingRight: 20}}></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}