import React from "react";
import Button from "./Button";
import { PopUp } from "./PopUp";

export class PopUpQuantity extends PopUp {
    constructor(props) {
        super(props);

        this.inputs = [];

        this.state = {
            title: props.title ? props.title : 'Set quantity',
            quantity: null,
            loading: false
        }

        this.getInputs = this.getInputs.bind(this);
        this.changeQuantity = this.changeQuantity.bind(this);
        this.hasReachedMinQuantity = this.hasReachedMinQuantity.bind(this);
    }

    componentDidUpdate(prevProps) {
        // Check if the props have changed
        if (this.props.quantity !== prevProps.quantity) {
            this.setState({ quantity: null })
        }
    }

    getInputs() {
        return this.inputs;
    }

    changeQuantity(quantity) {
        quantity = quantity.replace(/[^0-9]/g, '');
    
        if (quantity !== "" && quantity !== null && !Number.isInteger(Number(quantity))) {
            quantity = this.state.quantity;
        }
    
        // Allow deletion when quantity is empty
        if (quantity === "") {
            quantity = null;
        }
    
        if (quantity !== null && (isNaN(quantity) || quantity < 0)) {
            return;
        }
    
        if (quantity !== null && quantity.length > 1 && quantity[0] === '0') {
            return;
        }
    
        if (quantity !== null && quantity > 999) {
            quantity = 999;
        }
    
        if (quantity !== "" && quantity !== null) {
            quantity = parseInt(quantity);
        }
    
        this.setState({
            quantity: quantity
        });
    }

    hasReachedMinQuantity() {
        if (this.props.minOrderQty === false) return true;
        return this.state.quantity == 0 || !this.props.product.min_order_qty || this.props.product.min_order_qty <= 0 || this.props.product.min_order_qty <= this.state.quantity
    }

    submit() {
        if (this.hasReachedMinQuantity()) {
            this.setState({
                loading: true
            })
            this.props.onSave(this.state.quantity === null ? this.props.quantity.toString() : this.state.quantity).then(() => {
                this.props.onDismiss();
            }).finally(() => {
                this.setState({
                    loading: false
                });
            });
        } else {
            console.log("Min quantity not reached");
        }
    }

    renderContent() {
        if (!this.props.product) return;


        return <React.Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    this.submit();
                }}
            >
                <input
                    id="popup-quantity"
                    placeholder={this.props.quantity.toString()}
                    value={this.state.quantity === null ? '' : this.state.quantity.toString()}
                    onChange={(e) => this.changeQuantity(e.target.value)}
                    type="text"
                    className="plain"
                />
                <input type="submit" className="d-none" />
            </form>
            <Button
                theme="primary"
                loading={this.props.loading}
                onClick={() => {
                    this.submit();
                }}
                title="Confirm"
            />
            {
                this.props.product.min_order_qty && this.props.minOrderQty !== false ? 
                    <div style={ !this.hasReachedMinQuantity() ? {color: 'red', marginTop: 10, textAlign: 'center'} : {marginTop: 10, textAlign: 'center'}}>
                        &nbsp;
                        Minimum order quantity is { this.props.product.min_order_qty }
                    </div>
                : null
            }
            
        </React.Fragment>;
    }
}