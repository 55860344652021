import {AppService as App} from 'smartpub-common/services/app.js';
import React, { useMemo } from 'react';
import {ReminderItem} from './ReminderItem';

import { selectors as reminderSelectors } from 'smartpub-common/redux/slices/reminders';
import { useSelector } from 'react-redux';

export function ReminderList({ historical = false, filter = false, summary = false }) {
    const reminderState = useSelector(state => reminderSelectors.getList(state, filter, historical));

    const reminderList = useMemo(() => {
        if (!summary) return reminderState;

        const week = 7 * 24 * 60 * 60 * 1000;
        return reminderState.slice(0,3).filter(item => (item.start_date - (new Date)) <= week);
    }, [reminderState])

    if (!reminderList.length) {
        if (summary) return null;

        return (
            <h5 className="text-center mt-5">
                No reminders found
            </h5>
        );
    }

    let reminderHTML = reminderList.map((reminder, i) => {
        return (
            <div className="list-contents">
                <ReminderItem
                    reminder={reminder}
                    summary={summary}
                />
            </div>
        );
    });

    return (
        <div className={ summary ? "reminder-summary" : "list-container" }>
            { reminderHTML }
        </div>
    )
}