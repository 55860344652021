import React from "react";
import { createPortal } from 'react-dom';

import { useSelector, useDispatch } from 'react-redux';
import { selectors as appSelectors, actions as appActions } from 'smartpub-common/redux/slices/app';

import closeIcon from '#assets/icons/close.svg';

import _ from 'lodash';

export const PopUpMessage = () => {
    const dispatch = useDispatch();
    const confirmation = useSelector(appSelectors.getConfirmation);
    
    const onDismiss = () => {
        dispatch(appActions.hideConfirmation());
    }

    if (!confirmation) return;

    return createPortal(
        <div className="popup popup--select-supplier">
            <div id="popup-filter" onClick={onDismiss}> </div>

            <div id="popup-box">
                {
                    confirmation.title ? 
                        <h5 className="text-center">
                            { confirmation.title }
                            <div id="popup-close" onClick={onDismiss}>
                                <img src={closeIcon} alt="close" />
                            </div>
                        </h5>
                    : null
                }

                {
                    !confirmation.error ? 
                        <div className="text-center">
                            <span className="icon icon--tick"></span>
                        </div>
                    : null
                }

                <span className="text-center">{ confirmation.message }</span>
            </div>
        </div>
    , document.getElementById('root'));
};