import { AppService as App } from './app.js';
import { ApiService as Api } from './api.js';

export class PubUserService { 
    setup() {
        this.users = [];

        App.initUpdate('Pub users', 0);
        return this._loadUsers();
    }

    get(value = null, key = null) {
        var users = this.users;

        if (value === null) {
            return users;
        }

        if (!key) {
            key = "id";
        }

        var results = users.filter(user => user[key].toString().toLowerCase() === value.toString().toLowerCase());

        if (results.length == 1) {
            return results[0];
        } else if (results.length) {
            return results;
        }
    }

    update(userID, permissions, active = null) {
        return Api.request("pub/user", {
            'user_id': userID,
            'permissions': JSON.stringify(permissions),
            'active': active ? 1 : 0
        }, "PATCH").then(() => {
            this.users = this.users.map((user) => {
                if (user.id === userID) {
                    user.permissions = permissions;
                    user.is_active = active ? 1 : 0;
                }

                return user;
            });

            // Update cache
            App.cache.set("pub/users", this.users);
        }, (error) => {
            throw error;
        });
    }

    save(userData) {
        userData.permissions = JSON.stringify(userData.permissions);

        return Api.request("pub/user", userData, "POST").then(() => {
            return this.setup();
        });
    }

    _loadUsers() {
        return Api.request("pub/users").then(data => {
            this.users = typeof data != "object" ? [] : data;

            App.initUpdate('Pub users', 1);
            App.cache.set("pub/users", data);
            return data;
        }, (error) => {
            //console.error(error);
            App.initUpdate('Pub users', 2);

            // Load from cache
            App.cache.get("pub/users").then((users) => {
                this.users = users;
                
                return users;
            });
        });
    }
}