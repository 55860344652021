import { HistoryService as History } from './history.js';
import { PubUserService as PubUsers } from './pubUsers.js';
import { AppService as App } from './app.js';
import { Utils } from './utils.js';

export class PubService {
	constructor() {
        this.history = new History();
        this.users = new PubUsers();
	}

    getDocuments(type = null, from = null, to = null) {
	    let data = {};
	    if (from) {
	        data.from = Utils.getDateString(from);
        }
        if (to) {
            data.to = Utils.getDateString(to);
        }

        if (Array.isArray(type)) {
            if (type.length) {
                type = type.join(',');
            } else {
                type = "";
            }
        }

        data.type = type;

        return App.api.request("pub/documents", data).then((data) => {
            return data;
        });
    }

    downloadDocuments(all_in_one, documents = null) {
        return App.api.request("pub/documents/download", {all_in_one, documents}).then((data) => {
            return data
        });
    }
}