import React, { useState, useEffect } from 'react';
import { AppService as App } from 'smartpub-common/services/app.js';
import { useSelector, useDispatch } from 'react-redux';
import { actions as orderActions, selectors as orderSelectors, submit as orderSubmitThunk, create as createOrder } from 'smartpub-common/redux/slices/order';
import { useNavigate } from 'react-router-dom';

import { selectors as pubSelectors } from 'smartpub-common/redux/slices/pub';
import { selectors as supplierSelectors } from 'smartpub-common/redux/slices/suppliers';
import { store } from 'smartpub-common/redux/default';
import { ActivityIndicator } from 'react-native';
import { PopUpConfirm } from '../../includes/PopUpConfirm.js';
import { ProductList } from '../../includes/order/confirmation/ProductList.js';
import Footer from '../../includes/order/confirmation/Footer.js';

import { showMessage } from '../../facades/showMessage';

export default function Confirmation({ setPageTitle }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const order = useSelector(state => state.order);
    const orderableItems = useSelector(state => orderSelectors.getItems(state, true));
    const suppliers = useSelector(state => orderSelectors.getGroupedBySupplier(state, true));
    const isCounter = useSelector(orderSelectors.isCounter);
    const isTopUp = useSelector(orderSelectors.isTopUp);
    const inProgress = useSelector(orderSelectors.inProgress);
    const payableItems = useSelector(orderSelectors.getPayable);
    const pubIsReca = useSelector(state => pubSelectors.details(state, "reca"));
    const isPayable = pubIsReca && payableItems.length;
    const hasMinOrderQtyBeenMet = useSelector(orderSelectors.hasMinOrderQtyBeenMet);

    const pubDetails = useSelector(state => state.pub.details);
    const pubId = pubDetails.id;

    const [submitting, setSubmitting] = useState(false);

    const [isOrderable, setIsOrderable] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [recentlyOrderedSuppliers, setRecentlyOrderedSuppliers] = useState([]);
    const [errorMessage, setErrorMessage] = useState();

    let title = 'Order';
    switch (order.type) {
        case 'topup': title = 'Top up order'; break;
        case 'counter': title = 'Depot collection'; break;
    }

    setPageTitle(isCounter ? 'Depot collection order' : isTopUp ? 'Top up order' : 'Order');


    // Init
    useEffect(() => {
        // Don't allow users to reach this page if they haven't started an order
        if (!inProgress) {
            dispatch(createOrder('normal'));
        }

        if (!recentlyOrderedSuppliers || !recentlyOrderedSuppliers.length) {
            let supplierArray = suppliers.map(entry => entry.supplier_id);

            App.api.request("pub/ordercheck", {suppliers: supplierArray, pub: pubId}).then((result) => {
                setRecentlyOrderedSuppliers(result);
            }).catch((err) => {
                console.log('errrrr', err);
            });
        }
    }, []);

    // Order update
    useEffect(() => {
        if (!inProgress) {
            return;
        }

        // Does order contain items
        if (orderableItems.length < 1) {
            showMessage({
                title: "No products in order",
                message: "Your order does not contain any products.\n\nYour order must contain at least one product in order to proceed.",
                timeout: false,
                error: true
            });
            navigate("/order/new", { replace: true });
            return;
        }

        if (!hasMinOrderQtyBeenMet) {
            navigate("/order/new", { replace: true });
            return;
        }

        // Check there are no straggling supplier selectable
        let orderable = true;
        orderableItems.forEach(item => {
            if(item.supplier_id == null || item.supplier_id == 4 || !supplierSelectors.getById(store.getState(), item.supplier_id)){
                orderable = false;
            }
        });

        if (!orderable) {
            showMessage({
                title: "No supplier set",
                message: "Your order contains products that are not attached to a supplier.\n\nAll of your products must be attached to a supplier before being able to proceed.",
                timeout: false,
                error: true
            });
            navigate("/order/new", { replace: true });
            return;
        }

        setIsOrderable(true);
        
    }, [order]);

    const submitOrder = () => {
        setSubmitting(true);

        dispatch(orderSubmitThunk())
        .unwrap()
        .then(data => {
            // Does this order need payment?
            if (data.payment) {
                navigate("/order/payment", {
                    replace: true,
                    state: {
                        order: data
                    }
                });
            } else {
                navigate("/order/summary", {
                    replace: true,
                    state: {
                        order: data
                    }
                });
            }
        })
        .catch(error => {
            console.error(error);
            setErrorMessage(error.message);
        })
        .finally(() => {
            setShowConfirmationPopup(false);
            setSubmitting(false);
        })
    }

    if (!isOrderable) return null;

    let popUpConfirmationContent = "Pressing the confirm button will create your order.\n\nYou will not be able to amend any of the items on this page.\n\nAre you sure you want to continue?";
    if (isPayable && !isCounter) {
        popUpConfirmationContent = "Pressing the confirm button will take you to a payment screen for your HIL and HIL POS items.\n\nYou will not be able to amend any of the items on this page.\n\nAre you sure you want to continue?";
    } else if (isCounter) {
        popUpConfirmationContent = "Press the Confirm button to submit your order."
    }

    return (
        <div id="order-confirmation">
            <div id="order-checkout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" id="product-list-table">
                            <div className="table-header row">
                                <div className="col-md-9">
                                    <p className="text-left">ITEM</p>
                                </div>

                                <div className="col-md-3">
                                    <p className="text-right">ORDER QUANTITY</p>
                                </div>
                            </div>

                            <ProductList />
                            <Footer
                                onSubmit={() => { setShowConfirmationPopup(true) }}
                                submitting={submitting}
                            />

                            {
                                <React.Fragment>
                                    {
                                        showConfirmationPopup ?   
                                            <PopUpConfirm
                                                loading={submitting}
                                                isVisible={true}
                                                modalTitle={'Submit order'}
                                                content={popUpConfirmationContent}
                                                recentlyOrderedSuppliers={recentlyOrderedSuppliers}
                                                loadingMessage={"Order is being processed"}
                                                isCounter={isCounter}
                                                onConfirm={()=>{
                                                    submitOrder();
                                                }}
                                                onCancel={()=>{
                                                    setShowConfirmationPopup(false);
                                                }}
                                            />
                                        : null
                                    }
                
                                    {
                                        errorMessage ?   
                                            <PopUpConfirm
                                                isVisible={true}
                                                modalTitle="Error submitting order"
                                                content={errorMessage}
                                                allowCancel={false}
                                                onConfirm={() => setErrorMessage(null)}
                                            />
                                        : null
                                    }
                                </React.Fragment>
                            } 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}